

import React, { useState, useEffect } from "react";
import "./style.css";
import backimg from "../assets/images/godfather.png";
import logo from "../assets/images/logo1.svg";
import { useNavigate, Link } from "react-router-dom";
import { useAuthValue } from "./AuthContext";
import axios from "axios";
import server from "./server";

const AdminLogin = () => {
    const navigate = useNavigate();
    //email authentication states
    const [values, setValues] = useState({
        email: "",
        pass: "",
    });
    const [errorMsg, setErrorMsg] = useState("");
    const { setTimeActive } = useAuthValue();



    const handleSubmission = (e) => {
        e.preventDefault();
        console.log(values.email, values.pass);
        setErrorMsg("");
        axios
            .post(`${server}/admin/adminlogin`, {
                email: values.email,
                password: values.pass,
            })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("type", res.data.type);
                localStorage.setItem("login", JSON.stringify(res.data.data));
                navigate(`/${res.data.type}dashboard`);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.msg) {
                    setErrorMsg(err.response.data.msg);
                }
                else {
                    setErrorMsg("Invalid email or password");
                }
            });
    };

    useEffect(() => {
        let user = localStorage.getItem("login");
        if (localStorage.getItem("login")) {
            if (user.type == "seeker" || user.type == "admin") {
                navigate("/seekerdashboard");
            } else if (user.type == "user") {
                navigate("/userdashboard");
            }
        }
    }, []);


    return (
        <>
            <div id="snackbar">You are logged in Successfully..</div>

            <div className="login-container row">
                <div className="left-side col-lg-5">
                    <div className="top-left d-flex align-items-center">
                        <i
                            onClick={() => {
                                navigate("/");
                            }}
                            className="bi bi-arrow-left cursor-pointer"
                        ></i>
                        <p className="px-3 m-0">Login</p>
                    </div>
                    <img className="login-img" src={backimg} alt="" />
                    <div className="login-footer">
                        <div className="open-quote">“</div>
                        <div className="quote">
                            Acting is the least mysterious of all crafts. Whenever we want something from somebody or when we
                            want to hide something or pretend, we're acting. Most people do it all day long.
                        </div>
                        <div className="author">marlon brando</div>
                        <div className="close-quote">”</div>
                        <div className="three-dots">
                            <i className="fa-solid fa-circle mx-1"></i>
                            <i className="fa-regular fa-circle mx-1"></i>
                            <i className="fa-regular fa-circle mx-1"></i>
                        </div>
                    </div>
                </div>
                <div className="right-side col-lg-7 d-flex align-items-center justify-content-center">
                    <form
                        onSubmit={handleSubmission}
                        action=""
                        className={`form-container`}
                    >
                        <img src={logo} alt="" className="form-logo" />
                        <h3 className="text-center">Admin Login</h3>
                        <input
                            type="email"
                            placeholder="Email"
                            className="form-control my-2"
                            required
                            onChange={(event) => {
                                setValues((prev) => ({ ...prev, email: event.target.value }));
                            }}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="form-control my-2"
                            required
                            onChange={(event) => {
                                setErrorMsg("");
                                setValues((prev) => ({ ...prev, pass: event.target.value }));
                            }}
                        />
                        <b>{errorMsg}</b>
                        {/* alert({errorMsg}) */}
                        <button type="submit" className="submit-btn btn btn-lg btn-block my-4">
                            Login
                        </button>

                    </form>

                </div>
            </div>
        </>
    )
}

export default AdminLogin