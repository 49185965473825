import React from "react";
import axios from "axios";
import server from "./server";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const SlotBookPayment = ({ selectedCollab, setPaymentStatus, selectedSlot, setPaymentModal }) => {

    const handlePayment = async (value) => {
        axios
            .get(`${server}/payment/orders/${value}`)
            .then((res) => {
                console.log("Slotbook payment 26\n", res.data)
                displayRazorpay(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    async function displayRazorpay(data) {
        console.log(data)
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const options = {
            key: "rzp_test_fIqJxrea11tI3G",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            name: "Search-In",
            description: "Your Shopping Companion",
            handler: function (response) {
                console.log(response)
                const PaymentStatus = [];
                PaymentStatus.push({
                    Status: "Succesfull",
                    PaymentId: response.razorpay_payment_id,
                    OrderId: response.razorpay_order_id,
                    signatre: response.razorpay_signature,
                });
                console.log(PaymentStatus);
                setPaymentStatus("Success")

            },
            prefill: {
                name: "demo",
                email: "",
                phone_number: "9899999999",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            setPaymentStatus("Failed")
        })
        paymentObject.open();
    }


    return (
        <>
            <div className="modal-main" style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)"
            }}>
                <div className="buy-confirm">
                    <p className="buy-title">Confirm Collaboration</p>
                    <p className="slot-text">Slot</p>
                    <p className="slot-start-end-date">
                        {selectedSlot?.startDate} - {selectedSlot?.endDate}
                    </p>
                    <p className="order">Order Summary</p>
                    <div className="buy-content">
                        <p className="buy-thing">{selectedCollab?.adType}</p>
                        <p className="buy-price ">&#x20B9;{selectedCollab?.price}</p>
                    </div>
                    <div className="buy-content">
                        <p className="buy-thing">Tax</p>
                        <p className="buy-price">&#x20B9;12</p>
                    </div>
                    <div className="buy-content">
                        <p className="buy-thing">Discount</p>
                        <p className="buy-price">-&#x20B9;12</p>
                    </div>
                    <div className="buy-total">
                        <p className="total-text">Total</p>
                        <p className="total-price">&#x20B9;{selectedCollab?.price}</p>
                    </div>
                    <div className="div-pay">
                        <button className="button-pay" onClick={() => setPaymentModal(false)}>
                            Cancel
                        </button>

                        <button onClick={() => {
                            handlePayment(selectedCollab?.price);
                        }} className="button-pay">Pay Now</button>

                    </div>
                </div>
            </div>
        </>
    );
};

export default SlotBookPayment;