import axios from 'axios'
import React, { useEffect, useState } from 'react'
import server from '../server'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const Modal2 = ({ setModel, info, setProfile, UserProfileDeatils, roles }) => {
    const [characters, setCharacters] = useState([])
    const [initialStatus, setInitialStatus] = useState([])
    const profileStrength = (setProfile.profile && setProfile.talent && setProfile.photo && setProfile.education && setProfile.roles)

    let statIndex = 0;

    const navigate = useNavigate()
    const [statLoading, setStatLoading] = useState(true)
    const getStatus = (charIds) => {
        let statuses = []
        let indexto = 0
        charIds.map((charId, index) => {
            axios.get(`${server}/application/JobDetails/${UserProfileDeatils?.userId}/${charId}`)
                .then((response) => {
                    console.log({ "response": response })
                    indexto++
                    if (response.data.length > 0) {
                        let str = response.data[0].status;
                        statuses[index] = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                        //  response.data[0].status
                    }
                    else {
                        statuses[index] = "Apply"
                    }

                    if (indexto == charIds.length) {
                        setInitialStatus(statuses)
                        setStatLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    useEffect(() => {
        let charIds = []
        let initialStat = []
        roles.map((role) => {
            role.characters.map((character) => {
                charIds.push(character._id)
                initialStat.push("Apply")
            })
        })
        setCharacters(charIds)
        setInitialStatus(initialStat)
        if (profileStrength > 0) {
            getStatus(charIds)
        }
        else {
            setStatLoading(false)
        }

    }, [])

    const showToastSuccess = () => {
        toast("You have successfully applied to job ", {
            autoClose: 5000,

        });
    };
    const roleApply = (chrId, rId) => {
        axios
            .post(
                `${server}/application`,
                {
                    pId: info._id,
                    roleId: rId,
                    charId: chrId,
                    status: "applied",
                    seekerId: info.seekerId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setModel(false);
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                showToastSuccess()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function handleProfile() {
        navigate("/profiledetails");
    }

    return (
        <div className="modal-background my-4">
            <div className="modal-container">
                <div className="modal-header">
                    {
                        !(profileStrength >= 80) ? (
                            <div className="modal-header">
                                Your profile is weak to apply for this roles.
                            </div>
                        )
                            : ("")
                    }
                </div>
                <div className="modal-body">
                    <div className="modal-name">‘{info.basicInfo.name}’</div>
                    <div className="secondary-text">Roles</div>

                    {
                        roles.map((role, roleIndx) => {
                            return (
                                <>
                                    <div key={roleIndx}>
                                        <div className="modal-roles">
                                            <div className="role-name">
                                                {role.role}
                                                {"  "}
                                                {`(${role.characters.length})`}
                                            </div>

                                            {
                                                !statLoading && role.characters.map((character, charIndx) => {
                                                    let idx = statIndex
                                                    statIndex++
                                                    return (
                                                        <>
                                                            <div key={charIndx}>
                                                                <div className="char-name">{character.name}</div>
                                                                <div className="total-roles">
                                                                    {
                                                                        profileStrength >= 80 ?
                                                                            (
                                                                                <button
                                                                                    style={{
                                                                                        backgroundColor: "#8443e5",
                                                                                        color: "white",
                                                                                    }}
                                                                                    // onClick={() => {
                                                                                    //     roleApply(e._id, prRole._id);
                                                                                    // }}
                                                                                    className="apply-btn"
                                                                                    onClick={() => { initialStatus?.[idx] == "Apply" && roleApply(character._id, role._id) }}
                                                                                >
                                                                                    {
                                                                                        !statLoading ?
                                                                                            initialStatus?.[idx] :
                                                                                            "......"

                                                                                    }
                                                                                </button>
                                                                            )
                                                                            :
                                                                            (
                                                                                <button
                                                                                    style={{
                                                                                        backgroundColor: "grey",
                                                                                        color: "white",
                                                                                    }}
                                                                                    className="apply-btn"
                                                                                >
                                                                                    Apply
                                                                                </button>
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }
                </div>
                <div className="modal-footer">
                    <button
                        className="cancel-btn me-4"
                        onClick={() => {
                            setModel(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button onClick={handleProfile} className="update-btn">
                        Update-Profile
                    </button>
                </div>
            </div>

        </div>
    )
}

export default Modal2