import React, { useRef } from "react";

const IGMedia = ({ mediaData }) => {
	const containerRef = useRef(null);

	const handleTouchStart = (e) => {
		const touch = e.touches[0];
		containerRef.current.touchStartX = touch.clientX;
	};

	const handleTouchMove = (e) => {
		if (!containerRef.current.touchStartX) return;
		const touch = e.touches[0];
		const touchEndX = touch.clientX;
		const diffX = containerRef.current.touchStartX - touchEndX;

		containerRef.current.scrollLeft += diffX;
		containerRef.current.touchStartX = touchEndX;
	};

	if (!mediaData || !mediaData.data) {
		return <div className="loader"></div>;
	}

	return (
		<div className="py-4">
			<h2 className="text-2xl text-purple-700 font-semibold mb-4">
				Media Posts
			</h2>
			<div
				ref={containerRef}
				className="overflow-x-auto whitespace-nowrap"
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
			>
				<div className="flex md:grid md:grid-cols-2 lg:grid-cols-4 gap-4">
					{mediaData.data.map((media) => (
						<div
							key={media.id}
							className="inline-block min-w-[200px] md:min-w-0 shadow-lg bg-white rounded-lg overflow-hidden mx-2 md:mx-0"
						>
							<div className="w-full h-48 overflow-hidden flex items-center justify-center bg-gray-200">
								{media.media_type === "VIDEO" ? (
									<video
										controls
										className="w-full h-full object-cover"
									>
										<source
											src={media.media_url}
											type="video/mp4"
										/>
										Your browser does not support the video tag.
									</video>
								) : (
									<img
										src={media.media_url}
										alt={media.caption || "Instagram media"}
										className="w-full h-full object-cover"
									/>
								)}
							</div>
							<div className="p-4">
								<p
									className="text-gray-700"
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{media.caption}
								</p>
								<a
									href={media.permalink}
									className="text-purple-500 block mt-2"
									target="_blank"
									rel="noopener noreferrer"
								>
									View on Instagram
								</a>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default IGMedia;
