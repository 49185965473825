import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./components/AuthContext";
import { useState, useEffect, useRef } from "react";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Verification from "./components/Verification";
import EmailVerification from "./components/EmailVerification";
import Web1 from "./components/Web1";
import { Navigate } from "react-router-dom";
import TalentDashboard from "./components/TalentDashboard";
import SeekerDashboard from "./components/SeekerDashboard";
import RolesDashboard from "./components/RolesDashboard";
import ProfileDetails from "./components/ProfileDetails";
import ProjectCreation from "./components/ProjectCreation";
import Roles from "./components/Roles";
import ApplicantDetails from "./components/ApplicantDetails";
import BrowseProfile from "./components/BrowseProfile";
import UserProfile from "./components/UserProfile";
import Audition from "./components/Audition";
import Submission from "./components/Submission";
import Notification from "./components/Notification";
import Setting from "./components/Setting";
import FaqsHelp from "./components/FaqsHelp";
import MyRoles from "./components/MyRoles";
import RequestPage from "./components/RequestPage";

import TalentNotification from "./components/TalentNotification";
import server from "./components/server";

import ChatProvider from "./components/Context/ChatProvider";

import ChatPage1 from "./components/ChatPage1";
import "./components/responsive.css";
import Myapplication from "./components/Myapplication";

import io from "socket.io-client";
import SkillsAdmin from "./components/SkillsAdmin";
import RolesAdmin from "./components/RolesAdmin";
import InfluencerDashboard from "./components/InfluencerDashboard";
import YoutubeInfo from "./components/YoutubeInfo";
import InstagramInfo from "./components/InstagramInfo";
import InstagramAuthHandler from "./components/forms/instagram_details/InstagramAuthHandler";
import BrowseInfluencer from "./components/BrowseInfluencer";
import InfluencerViewProfile from "./components/InfluencerViewProfile";
import BookedSlots from "./components/BookedSlots";
import AdminLogin from "./components/AdminLogin";
import EmailVerifyRoute from "./components/EmailVerifyRoute";
import CollabInfo from "./components/CollabInfo";
import SlotInfo from "./components/Slotinfo";

import { logEvent } from "firebase/analytics";
import { analytics } from "./components/firebase-config";
const ENDPOINT = { server };

function App() {
	const [currentUser, setCurrentUser] = useState(null);
	const [timeActive, setTimeActive] = useState(false);
	const [clicked, setClicked] = useState(0);
	const [active, setActive] = useState("home");
	const [notificationCount, setNotificationCount] = useState(0);
	const [notificationCountSeeker, setNotificationCountSeeker] = useState(0);

	const [chatUnReadCount, setChatUnReadCount] = useState(0);

	const [socketConnected, setSocketConnected] = useState(false);
	const [typing, setTyping] = useState(false);

	const [socket, setSocket] = useState(io.connect(ENDPOINT.server));

	const getunreadonTopbar = useRef(0);

	useEffect(() => {
		let user = JSON.parse(localStorage.getItem("login"));
		if (user) {
			setCurrentUser(user);
		} else {
			if (
				window.location.pathname !== "/login" &&
				window.location.pathname !== "/verification" &&
				!window.location.pathname.includes("/emailVerifyRoute") &&
				window.location.pathname !== "/emailverify " &&
				window.location.pathname !== "/admin" &&
				window.location.pathname !== "/"
			) {
				window.location.href = "/login";
			}
		}
	}, []);

	useEffect(() => {
		console.log("🚀 ~ file: App.js:87 ~ useEffect ~ Analytics:");

		console.log("🚀 ~ file: App.js:90 ~ useEffect ~ analytics:", analytics);
		logEvent(analytics, "app_open");
	}, []);
	return (
		<Router>
			<AuthProvider
				value={{
					currentUser,
					timeActive,
					setTimeActive,
					setClicked,
					clicked,
					active,
					setActive,
					notificationCount,
					setNotificationCount,
					notificationCountSeeker,
					setNotificationCountSeeker,
					chatUnReadCount,
					setChatUnReadCount,
					typing,
					setTyping,
					socketConnected,
					setSocketConnected,
					socket,
					setSocket,
					getunreadonTopbar,
				}}
			>
					<Routes>
						{currentUser ? (
							<>
								<Route
									path="/logintest"
									element={
										!currentUser?.emailVerified ? (
											<Login />
										) : (
											<Navigate
												to="/"
												replace
											/>
										)
									}
								/>
								<Route
									path="/register"
									element={
										!currentUser?.emailVerified ? (
											<Signup />
										) : (
											<Navigate
												to="/"
												replace
											/>
										)
									}
								/>
								<Route
									path="/profiledetails"
									element={<ProfileDetails />}
								/>{" "}
								{/* talent */}
								<Route
									path="/projectcreation"
									element={<ProjectCreation />}
								/>
								<Route
									path="/userdashboard"
									element={<TalentDashboard />}
								/>{" "}
								{/* talent */}
								<Route
									path="/seekerdashboard"
									element={<SeekerDashboard />}
								/>
								<Route
									path="/admindashboard"
									element={<SeekerDashboard />}
								/>
								<Route
									path="/login"
									element={<Login />}
								/>
								<Route
									path="/signup"
									element={<Signup />}
								/>
								<Route
									path="/verification"
									element={<Verification />}
								/>
								<Route
									path="/emailverify"
									element={<EmailVerification />}
								/>
								<Route
									path="/"
									element={<Web1 />}
								/>
								<Route
									path="/rolesdashboard"
									element={<RolesDashboard />}
								/>
								<Route
									path="/roles"
									element={<Roles />}
								/>
								<Route
									path="/applicantdetails"
									element={<ApplicantDetails />}
								/>
								<Route
									path="/browseprofile"
									element={<BrowseProfile />}
								/>
								<Route
									path="/browseprofile/:user"
									element={<UserProfile />}
								/>
								<Route
									path="/submission"
									element={<Submission />}
								/>
								<Route
									path="/audition"
									element={<Audition />}
								/>
								<Route
									path="/notification"
									element={
										currentUser?.type === "seeker" ? (
											<Notification />
										) : (
											<TalentNotification />
										)
									}
								/>
								<Route
									path="/setting"
									element={<Setting />}
								/>{" "}
								{/* talent */}
								<Route
									path="/help"
									element={<FaqsHelp />}
								/>
								<Route
									path="/myrole"
									element={<MyRoles />}
								/>{" "}
								{/* talent */}
								<Route
									path="/requestpage"
									element={<RequestPage />}
								/>{" "}
								{/* talent */}
								<Route
									path="/roles"
									element={<Roles />}
								/>{" "}
								{/* talent */}
								<Route
									path="/applicantdetails"
									element={<ApplicantDetails />}
								/>
								<Route
									path="/myapplication"
									element={<Myapplication />}
								/>{" "}
								{/* talent */}
								<Route
									path="/skills"
									element={<SkillsAdmin />}
								/>
								<Route
									path="/adminroles"
									element={<RolesAdmin />}
								/>
								{/* <Route path="/emailVerifyRoute" element={<EmailVerifyRoute />} /> */}
								<Route
									path="/chat"
									element={
										<ChatProvider>
											<ChatPage1 />
										</ChatProvider>
									}
								/>
								<Route
									path="/influencerdashboard"
									element={<InfluencerDashboard />}
								/>


								<Route
									path="/instagraminfo"

									element={ <InstagramInfo />}
								/>
								<Route
									path="/instagramauth"
									element={<InstagramAuthHandler />}
								/>


								<Route
									path="/youtubeinfo"
									element={<YoutubeInfo />}
								/>
								<Route
									path="/collabinfo"
									element={<CollabInfo />}
								/>
								<Route
									path="/slotinfo"
									element={<SlotInfo />}
								/>
								back
								<Route
									path="/browseinfluencer"
									element={<BrowseInfluencer />}
								/>
								<Route
									path="/bookedslot"
									element={<BookedSlots />}
								/>
								<Route
									path="/viewinfluencer"
									element={<InfluencerViewProfile />}
								/>
								{/* ADMIN LOGIN */}
								<Route
									path="/admin"
									element={<AdminLogin />}
								/>
							</>
						) : (
							<>
								<Route
									path="/login"
									element={<Login />}
								/>
								<Route
									path="/verification"
									element={<Verification />}
								/>
								<Route
									path="/emailverify"
									element={<EmailVerification />}
								/>
								<Route
									path="/"
									element={<Web1 />}
								/>
								<Route
									path="/admin"
									element={<AdminLogin />}
								/>
								<Route
									path="/signup"
									element={<Signup />}
								/>

								<Route
									path="/emailVerifyRoute"
									element={<EmailVerifyRoute />}
								/>
							</>
						)}
					</Routes>
			</AuthProvider>
		</Router>
	);
}

export default App;
