import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import axios from "axios";
import server from "./server";
import AuthContext from "./AuthContext";
import profile from "../assets/icons/profile1.svg";
import noMessages from "../assets/images/nomessages.svg";
import Topbar from "./mini_components/Topbar";

const Notification = () => {
	const [projects, setProjects] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [jobProjects, setJobProjects] = useState([]);
	const [jobUsers, setJobUsers] = useState([]);
	const [userProjectMap, setUserProjectMap] = useState([]);
	const [loggedUser, setLoggedUser] = useState("");

	const dataFetchedRef = useRef(false);

	const auth = useContext(AuthContext);

	const getProjects = async () => {
		const res = await fetch(`${server}/project/allProjectsSeekers`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();

		if (response !== null) {
			response.reverse();
			setProjects(response);
		}
	};

	const getJobApplications = async () => {
		const res = await fetch(`${server}/application/allJobsSeeker`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();

		setJobs(response);
		getUsers(response);
		getJobProjects(response);
	};

	const getUsers = (jobs) => {
		jobs?.forEach((job) => {
			axios
				.get(`${server}/project/UserId/${job.userId}`)
				.then((res) => {
					if (res !== null) {
						setJobUsers((oldUsers) => [...oldUsers, res.data]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	const getJobProjects = (jobs) => {
		jobs?.forEach((job) => {
			axios
				.get(`${server}/project/projectDetails/${job.pId}`)
				.then((res) => {
					if (res !== null) {
						setJobProjects((oldProjects) => [...oldProjects, res.data]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		getProjects();
		getJobApplications();
		auth.setActive("notification");
	}, []);

	useEffect(() => {
		if (jobUsers.length === 0 || jobProjects.length === 0) return;
		const len = jobProjects.length === jobUsers.length ? jobProjects.length : 0;
		const arr = new Set();

		for (let index = 0; index < len; index++) {
			const userName = jobUsers[index][0]?.username;
			const projectName = jobProjects[index][0]?.basicInfo.name;
			const image = jobUsers[index][0]?.link;

			arr.add({
				notification: `${userName} has applied to project ${projectName}`,
				img: image,
			});
		}

		const projArr = [...arr];
		projArr.reverse();
		setUserProjectMap(projArr);
	}, [jobUsers, jobProjects]);

	useEffect(() => {
		setLoggedUser(JSON.parse(localStorage.getItem("login")));
	}, []);

	return (
		<>
			<Topbar />
			<div className="flex flex-col items-center p-2  min-h-screen">
				<div className="w-full h-screen max-w-4xl  rounded-lg p-4">
					<div className="flex justify-between items-center mb-4">
						<h1 className="text-2xl font-bold text-purple-600 flex items-center">
							Notifications <IoMdNotifications className="ml-2" />
						</h1>
					</div>
					<hr />
					{projects.length > 0 || userProjectMap.length > 0 ? (
						<>
							{projects?.map((project, index) => (
								<div
									key={index}
									className="py-2"
								>
									<div className="flex items-center space-x-4">
										<img
											src={loggedUser.link || profile}
											alt="pfp"
											className="w-10 h-10 rounded-full shadow-sm"
										/>
										<p>
											You have successfully created the project{" "}
											<span className="font-semibold">
												{project.basicInfo.name}
											</span>
										</p>
									</div>
									<hr className="my-2" />
								</div>
							))}
							{userProjectMap?.map((item, index) => (
								<div
									key={index}
									className="py-2"
								>
									<div className="flex items-center space-x-4">
										<img
											src={item.img || profile}
											alt="pfp"
											className="w-10 h-10 rounded-full shadow-sm"
										/>
										<p>{item.notification}</p>
									</div>
									<hr className="my-2" />
								</div>
							))}
						</>
					) : (
						<div className="flex flex-col items-center justify-center text-gray-500 ">
							<img
								src={noMessages}
								alt="No Notifications"
								className="max-h-72"
							/>
							<p className="mt-4">You do not have any notifications</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Notification;
