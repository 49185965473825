import React, { useContext, useEffect, useState } from "react";
import Topbar from "./mini_components/Topbar";
import Sidebar from "./mini_components/Sidebar";
import { useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";

const ProfileDetails = () => {
    const location = useLocation()
    const auth = useContext(AuthContext)

    useEffect(() => {
        auth.setActive("profiledetails")
    }, [])


    return (
        <div>
            <Topbar />
            <Sidebar userData={location.state?.user} />
        </div>
    );
};

export default ProfileDetails;
