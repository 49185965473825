import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ChannelStats from "./forms/influencer_YT_details/ChannelStats";
import CollabDetails from "./forms/influencer_YT_details/CollabDetails";
import SlotDetails from "./forms/influencer_YT_details/SlotDetails";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "./AuthContext";

const CollabInfo = () => {
    const user = JSON.parse(localStorage.getItem("login"));
    let channelDetailsObj = {
        channelId: "",
        channelName: "",
        channelLink: "",
        subscriberCount: "",
        videoCount: "",
        viewCount: "",
        averageCommentsCount: 0,
        averageLikesCount: 0,
    };

    let sampleChannelDetails = {
        channelId: "UCBqFKDipsnzvJdt6UT0lMIg",
        channelLink: "https://www.youtube.com/@SandeepSeminars",
        channelName: "Sandeep Maheshwari",
        hiddenSubscriberCount: false,
        subscriberCount: "27300000",
        videoCount: "574",
        viewCount: "2245403270",
        averageCommentsCount: "9377.42",
        averageLikesCount: "271469.5",
    };

    let collabObj = {
        adType: "",
        price: "",
    };

    let slotObj = {
        startDate: "",
        endDate: "",
        isAvailable: false,
    };

    const [channelDetails, setChannelDetails] = useState(channelDetailsObj);
    const [collabDetails, setCollabDetails] = useState([collabObj]);
    const [slotDetails, setSlotDetails] = useState([slotObj]);
    const auth = useContext(AuthContext)

    const [formSelect, setFormSelect] = useState(1);


    const handleNextClick = () => {
        for (let ind = 0; ind < collabDetails.length; ind++) {
            const element = collabDetails[ind];
            if (checkIfFormFilled(element)) {
                alert(`Entry no. ${ind + 1} is empty please fill it`);
                break;
            } else if (ind == collabDetails.length - 1) {
                setFormSelect((prev) => prev + 1);
            }
        }


        axios
            .post(
                `${server}/influencer/youtube`,
                {
                    statistics: channelDetails,
                    collabDetails: collabDetails,
                    slotDetails: slotDetails,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                console.log(response);
                // showToastMessage();
                // <ToastContainer
                //     position="top-right"
                //     autoClose={5000}
                //     hideProgressBar={false}
                //     newestOnTop={false}
                //     closeOnClick
                //     rtl={false}
                //     pauseOnFocusLoss
                //     draggable
                //     pauseOnHover
                //     theme="colored"
                // />;
            })
            .catch((err) => {
                alert(err.message);
            });

    };

    const handlePrevClick = () => {
        if (formSelect != 1) {
            setFormSelect(prev => prev - 1)
        }
    }


    function checkIfFormFilled(object) {
        for (const key in object) {
            const element = object[key];
            if (
                key != "isAvailable" &&
                key != "hiddenSubscriberCount" &&
                element == ""
            ) {
                return true;
            }
        }
        return false;
    }



    //   useEffect(() => {
    //     getCollabInfo();
    //   }, []);


    async function getYouTubeInfo() {
        // console.log(user);
        await axios
            .get(`${server}/influencer/ytInfo/${user._id}`)
            .then((response) => {
                let { data } = response;
                if (response.data) {
                    console.log(response)
                    setChannelDetails(data.statistics);
                    setCollabDetails(data.collabPrices);
                    setSlotDetails(data.slot);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getYouTubeInfo();
        auth.setActive("collab")
    }, []);

    return (
        <>
            <Topbar />
            <div className="section-youtube-info">
                <div className="width-100-mobile">
                    <CollabDetails
                        collabObj={collabObj}
                        collabDetails={collabDetails}
                        setCollabDetails={setCollabDetails}
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                    />
                    <ToastContainer />
                </div>
            </div>
        </>
    );
};

export default CollabInfo;
