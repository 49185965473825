import React, { useEffect, useState } from "react";
import Deleting from "../../../assets/icons/deleting.svg";
import Pencil from "../../../assets/icons/pencil.svg";
import LeftArrow from "../../../assets/icons/left-arrow.svg";
import "../forms.css";
import { ToastContainer, toast } from "react-toastify";

const CollabDetails = ({
	collabObj,
	collabDetails,
	setCollabDetails,
	handleNextClick,
	handlePrevClick,
}) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState();

	function handleInputChange(e, index) {
		let data = [...collabDetails];
		let changObj = data[index];
		changObj = { ...changObj, [e.target.name]: e.target.value };
		data[index] = changObj;

		setCollabDetails(data);
	}

	function removeFields(index) {
		let data = [...collabDetails];
		if (data.length === 1) {
			toast("Collaboration details need at least one entry", {
				autoClose: 2000,
			});
			return;
		}

		data.splice(index, 1);
		setCollabDetails(data);
		toast("Collab Deleted successfully", { autoClose: 2000 });
	}

	const handleEditCollab = (e, index) => {
		let collabBox = e.target.parentNode.parentNode;
		collabBox.querySelectorAll("input").forEach((inp) => {
			inp.readOnly = false;
		});
		collabBox.querySelector("input").focus();
	};

	function addMore() {
		setCollabDetails([...collabDetails, collabObj]);
		setTimeout(() => {
			let collabBtns = document.getElementsByClassName("collab-button");
			let last = collabBtns[collabBtns.length - 1];
			last.lastChild.click();
		}, 200);
	}

	const handleSaveClick = () => {
		toast("Collaboration details saved successfully!", { autoClose: 2000 });
		handleNextClick();
	};

	return (
		<>
			<ToastContainer />
			<div className="container mx-auto px-4 py-8">
				<h4 className="text-2xl text-center font-bold mb-4">Collab Details</h4>
				<div className="collablist">
					<div className="b_table ">
						{collabDetails?.length > 0 ? (
							<>
								{collabDetails?.map((collab, index) => {
									return (
										<div
											className="collab-main mb-4"
											key={index}
										>
											<div className="collab-box flex items-center justify-between p-4 bg-white shadow rounded-lg">
												<input
													placeholder="Enter type of AD"
													className="collab-type border-b border-gray-300 focus:border-purple-500 focus:outline-none w-1/2 py-2 px-3"
													value={collab?.adType}
													name="adType"
													onChange={(e) => {
														handleInputChange(e, index);
													}}
													readOnly={true}
												/>
												<input
													placeholder="Enter price"
													className="collab-price border-b border-gray-300 focus:border-purple-500 focus:outline-none w-1/4 py-2 px-3 ml-4"
													value={collab?.price}
													name="price"
													onChange={(e) => {
														handleInputChange(e, index);
													}}
													readOnly={true}
												/>
												<div className="collab-button flex space-x-2 ml-4">
													<img
														src={Deleting}
														alt="delete"
														className="cursor-pointer"
														onClick={() => {
															setDeleteIndex(index);
															setDeleteModal(true);
														}}
													/>
													<img
														src={Pencil}
														onClick={(e) => handleEditCollab(e, index)}
														alt="Edit"
														className="cursor-pointer"
													/>
												</div>
											</div>
										</div>
									);
								})}
								<div className="collab-button-main flex justify-between mt-4">
									<button
										className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600"
										onClick={() => addMore()}
									>
										Add More
									</button>

									<button
										onClick={handleSaveClick}
										className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600 flex items-center"
									>
										Save
										<img
											className="ml-2"
											src={LeftArrow}
											alt="Next"
										/>
									</button>
								</div>
							</>
						) : (
							""
						)}
					</div>
				</div>
			</div>

			{deleteModal && (
				<div
					id="myModal"
					className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
				>
					<div className="modal-content bg-white p-6 rounded-lg shadow-lg">
						<p className="text-center text-lg mb-4">Delete this AD?</p>
						<div className="text-center flex justify-center flex-row md:flex-col space-x-4">
							<button
								onClick={(e) => {
									e.preventDefault();
									setDeleteIndex();
									setDeleteModal(false);
								}}
								type="submit"
								className="bg-gray-200 text-gray-700 px-4 py-2 rounded shadow hover:bg-gray-300"
								value="Delete"
							>
								Cancel
							</button>
							<button
								onClick={(e) => {
									e.preventDefault();
									removeFields(deleteIndex);
									setDeleteModal(false);
								}}
								type="submit"
								className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
								value="Delete"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CollabDetails;
