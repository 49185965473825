import React, { useContext, useEffect } from "react";
import Sidebar2 from "./mini_components/Sidebar2";
import Topbar from "./mini_components/Topbar";
import { ToastContainer } from "react-toastify";
import AuthContext from "./AuthContext";

const ProjectCreation = () => {

    const auth = useContext(AuthContext)
    useEffect(() => {
        auth.setActive("projectcreation")


    }, [])


    return (
        <div>
            <Topbar />
            <ToastContainer />
            <Sidebar2 />
        </div>
    );
};

export default ProjectCreation;
