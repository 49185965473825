import React, { useState, useEffect } from "react";
import axios from "axios";
import InstaChannelStats from "./forms/instagram_details/InstaChannelStats";
import Topbar from "./mini_components/Topbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "./server";

const InstagramInfo = () => {
	const [userData, setUserData] = useState({});
	const [igUsername, setIgUsername] = useState("");
	const [formVisible, setFormVisible] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!igUsername) {
			toast.error("Please enter an Instagram username.");
			return;
		}
		setIsLoading(true);
		try {
			const authUrl = `${server}/influencer/instagram/authenticate/${igUsername}`;
			window.location = authUrl;
		} catch (error) {
			console.error("Error initiating Instagram authentication:", error);
			toast.error("Failed to initiate Instagram authentication.");
		} finally {
			setIsLoading(false);
		}
	};

	const fetchIGData = async () => {
		setIsLoading(true);

		try {
			const token = localStorage.getItem("token");
			if (!token) {
				toast.error("Session Expired. Please login again.");
				setIsLoading(false);
				return;
			}

			const response = await axios.get(
				`${server}/influencer/instagram/userdata`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.data || response.status !== 200) {
				throw new Error("No data returned from the server");
			}

			const data = response.data;

			toast.success(data.message || "Instagram data fetched successfully");

			setUserData(data);
			setFormVisible(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			if (error.response) {
				switch (error.response.status) {
					case 404:
						toast.warn(
							error.response.data.message || "No Instagram data found"
						);
						break;
					case 401:
						toast.error(
							error.response.data.message ||
								"Instagram token expired. Kindly authenticate with Instagram"
						);
						break;
					case 500:
						toast.error(
							error.response.data.message || "Failed to fetch Instagram data"
						);
						break;
					default:
						toast.info(
							error.response.data.message ||
								"An unknown response was received from the server"
						);
				}
			} else if (error.request) {
				toast.error("No response was received from the server.");
			} else {
				toast.error("Error making request to the server.");
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchIGData();
	}, []);

	return (
		<>
			<Topbar />
			{isLoading ? (
				<div className="flex justify-center items-center h-screen">
					<div className="text-center">
						<div className="loader"></div>
						<p className="mt-4 text-lg text-gray-700">
							Loading, please wait...
						</p>
					</div>
				</div>
			) : (
				<div className="instagram-info-container container mx-auto p-4">
					<div className="insta-channel-stats space-y-4">
						<h1 className="text-3xl font-bold text-center text-purple-700 mb-8">
							Instagram Channel Stats
						</h1>
						{formVisible ? (
							<form
								onSubmit={handleSubmit}
								className="space-y-4 max-w-md mx-auto p-4 bg-white rounded-lg shadow-md"
							>
								<input
									type="text"
									value={igUsername}
									onChange={(e) => setIgUsername(e.target.value)}
									placeholder="Enter Instagram username"
									className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
								/>
								<button
									type="submit"
									className="w-full bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded transition duration-300"
								>
									Fetch Stats
								</button>
							</form>
						) : (
							<div className="flex justify-center">
								<button
									onClick={() => setFormVisible(true)}
									className=" bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded transition duration-300"
								>
									Enter New Username
								</button>
							</div>
						)}

						{userData.username && <InstaChannelStats userData={userData} />}
					</div>
				</div>
			)}
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default InstagramInfo;
