import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ChannelCard from "./mini_components/ChannelCard";
import parse from "html-react-parser";
import Topbar from "./mini_components/Topbar";
const InfluencerViewProfile = () => {
  const location = useLocation();
  const channelInfo = location.state.channelInfo;
  const [videoLoader, setVideoLoader] = useState(0)

  function handleIframeLoad(){
    setVideoLoader(prev => prev + 1)
  }

  return (
    <>
      <Topbar />
      <div className="channelContainer new-style-channel-container" >
        <ChannelCard channel={channelInfo.statistics} />
        <div className="latestVideos new-style-latestVideos" style={{position:"relative"}} >
          {/* {channelInfo.statistics.latestVideos.map((video, indx) => {
            video = video.replace(
              'width="480" height="270"',
              'width="230" height="120" allowfullscreen'
            );

            return (
              <div className="videoCont" key={indx}>
                {parse(video)}
              </div>
            );
          })} */}
          {channelInfo.statistics.latestVideos.map((video, indx) => {

            return (
              <div className="videoCont" key={indx}>
                <iframe
                  src={video}
                  width="230"
                  height="120"
                  onLoad={handleIframeLoad}
                ></iframe>
              </div>
            );
          })}
          {videoLoader < 8 && <div className="loader-box"
            style={{
              position: "absolute",
              width: "100%",
              height: "275px",
              top: "0",
              left: "0",
              display: "flex",
              background: "#d7d7d7"

            }}
          >
            <div className="loader"></div>
          </div>}
        </div>

      </div>
    </>
  );
};

export default InfluencerViewProfile;
