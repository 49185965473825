import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Allapplications from './Allapplications';
import Topbar from './mini_components/Topbar'
import AuthContext from './AuthContext';

const Myapplication = () => {
    const location = useLocation();
    const data = location.state
    // console.log(data);

    const auth = useContext(AuthContext)

    useEffect(() => {
        // auth.setActive("fingers")
    }, [])


    return (
        <>
            <Topbar />
            <div className="container">
                <div className="b_table my-4 myapplications scroll-container">
                    <table style={{ width: "100%", overflowX: "scroll", borderSpacing: "10px" }}>
                        <thead>
                            <td>Project</td>
                            <td>Role </td>
                            <td>Character</td>
                            <td>Update</td>
                            <td>Status</td>
                        </thead>
                        {data?.map((item) => {
                            return (
                                <tbody style={{ width: "100%" }}>
                                    <Allapplications pid={item.pId} date={item.updatedAt}
                                        status={item.status} charId={item.charId}
                                        roleId={item.roleId} />
                                </tbody>
                            )
                        })
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default Myapplication
