import React, { useEffect, useState } from "react";
import { Country, State, City }  from 'country-state-city';
const ProjectDetailsForm = ({ display, functions }) => {
    const projectDetails = functions.projectDetails;
    const setProjectDetails = functions.setProjectDetails;
    const resetBasicInfo = functions.resetBasicInfo;
    const [resetConfModal, setResetConfModal] = useState(false)

    const [countries, setCountries] = useState(Country.getAllCountries)
    const [selectedCountry, setSelectedCountry] = useState()

    const [countryStates, setCountryStates] = useState([])
    const [selectedState, setSelectedState] = useState()

    const [cities, setCities] = useState([])
    const [invalidLink, setInvalidLink] = useState("")
    let show = {};
    if (display) {
        show = { display: "block" };
    } else {
        show = { display: "none" };
    }

    function checkValidLink(link,type){
      if(type == "instagram"){
         var pattern = /^https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("instagram")
         return isValid;
      }
      else if(type == "facebook"){
         var pattern = /^https?:\/\/(www\.)?facebook\.com\/([a-zA-Z0-9\.]+)\/?$/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("facebook")
         return isValid;
      }
      else if(type == "linkedIn"){
         var pattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/([a-zA-Z0-9_-]+)/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("linkedIn")
         return isValid;
         
      }
      return true;
   }

    const handleInputChange = (e) => {
        if(e.target.name == "country"){
            setProjectDetails({ ...projectDetails, ["country"]: e.target.value });
            setSelectedCountry(e.target.value)
          }
          else if(e.target.name =="state"){
            setProjectDetails({ ...projectDetails, ["state"]: e.target.value });
            setSelectedState(e.target.value)
          }
          else{
              setProjectDetails({ ...projectDetails, [e.target.name]: e.target.value });
            }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const {instagram, facebook} = projectDetails
        if ((instagram != "" && instagram != undefined) && !checkValidLink(instagram, "instagram")) {
          return;
        }
        else if ((facebook != "" && facebook != undefined) && !checkValidLink(facebook, "facebook")) {
          return;
        }
        myFunction()
        functions.toggleForm("labels");
    }

    var x = document.getElementById("snackbar");
    function myFunction() {
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    function handleResteForm(){
        resetBasicInfo()
        setResetConfModal(false)
    }

    useEffect(() => {
        let countrycode = selectedCountry?.split("-")[0]
        setCountryStates(State.getStatesOfCountry(countrycode))
      }, [selectedCountry])
      useEffect(() => {
        let countrycode = selectedCountry?.split("-")[0]
        let statecode = selectedState?.split("-")[0]
        setCities(City.getCitiesOfState(countrycode,statecode))
      }, [selectedState])

    return (

        <>
            <div id="snackbar">Your Data Saved Successfully..</div>
            <div className="form-body" style={show}>
                <div className="form-container">
                    <div className="form-head">Project Creation</div>
                    <div className="form-desc">
                        Post your project content to have quick access to the best worldwide talent.
                    </div>

                    <form id="bio-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Project Name"
                            name="name"
                            value={projectDetails.name}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            id="bio"
                            className="form-control text-area"
                            rows="4"
                            placeholder="Details..."
                             
                            name="description"
                            value={projectDetails.description}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            name="company_name"
                            value={projectDetails.company_name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company Address"
                            name="company_address"
                            value={projectDetails.company_address}
                            onChange={handleInputChange}
                            required
                        />
                        <select
                name="country"
                onChange={handleInputChange}
                // value={profileDetails.country}
                className="form-control form-select"
                required
              >
                <option value="" disabled selected>
                  Country
                </option>
                {/* <option>India</option>
                <option>USA</option> */}
                {
                  countries?.map((country,index)=>{
                    return(
                      <option index={index} value={country.isoCode + "-"+country.name}> {country.isoCode + "-"+country.name} </option>
                    )
                  })
                }
              </select>
              <div style={{ position: "relative", display: "flex" }}>
                <select
                  name="state"
                  onChange={handleInputChange}
                  // value={profileDetails.state}
                  className="form-control form-select"
                  required
                >
                  <option value="" disabled selected>
                    State
                  </option>
                  {/* <option>Maharashtra</option> */}
                  {
                    countryStates.map((state,index)=>{
                      return(
                        <option value={state.isoCode + "-" + state.name} > {state.isoCode + "-" +state.name} </option>
                      )
                    })
                  }
                </select>
                <p className="mx-2"></p>
                <select
                  name="city"
                  onChange={handleInputChange}
                  // value={profileDetails.city}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    City
                  </option>
                  {/* <option>Pune</option>
                  <option>Mumbai</option> */}
                  {
                    cities?.map((city,index)=>{
                      return(
                        <option>{city.name}</option>
                      )
                    })
                  }
                </select>
              </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={projectDetails.email}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="nummber"
                            className="form-control"
                            placeholder="Enter phone number"
                            name="number"
                            value={projectDetails.number}
                            onChange={handleInputChange}
                            required
                        />
                        <label className="form-label">Facebook  </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Facebook"
                            name="facebook"
                            value={projectDetails.facebook}
                            onChange={(e)=>{setInvalidLink(""); handleInputChange(e)}}
                            style={{borderColor:`${invalidLink=="facebook"?"red":""}`}}
                        />
                        <p className="text-danger"> { invalidLink == "facebook" && "Please enter valid FaceBook URL" } </p>
                        <label className="form-label">Instagram  </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Instagram"
                            name="instagram"
                            value={projectDetails.instagram}
                            onChange={(e)=>{setInvalidLink(""); handleInputChange(e)}}
                            style={{borderColor:`${invalidLink=="instagram"?"red":""}`}}
                        />
                        <p className="text-danger"> { invalidLink == "instagram" && "Please enter valid Instagram URL" } </p>
                        <div className="buttons justify-content-between">
                            <input type="button" className="col-4 cancel-btn btn btn-lg btn-block my-2" value="Reset" onClick={()=>{setResetConfModal(true)}} />
                            <p className="col-1"></p>
                            <input type="submit"  className="col-7 save-btn btn btn-lg btn-block my-2" value="Save" />
                        </div>
                    </form>
                </div>
            </div>

            {
                resetConfModal && (
                    <div id="myModal" class="modal" style={{display:"block"}}>
                        <div class="modal-content" style={{ width: "25%", marginTop: "12rem" }}>
                            <p>Your are sure you want to reset the details</p>
                            <div className="text-center" >
                                <button
                                    onClick={(e) => { e.preventDefault(); setResetConfModal(false) }}
                                    type="submit"
                                    className=" save-btn btn btn-lg btn-block m-2"
                                    value="Delete"
                                >Cancel</button>
                                <button
                                    onClick={(e) => { e.preventDefault(); handleResteForm() }}
                                    type="submit"
                                    className=" save-btn btn btn-lg btn-block m-2"
                                    value="Reset"
                                >Reset</button>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </>
    );
};

export default ProjectDetailsForm;
