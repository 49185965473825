import React, { useState, useEffect } from "react";
import IGMedia from "./IGMedia";

const InstaChannelStats = ({ userData }) => {
	const [userIGData, setUserIGData] = useState(userData);

	useEffect(() => {
		setUserIGData(userData);
	}, [userData]);

	return (
		<>
			{userIGData && (
				<div className="bg-white shadow rounded-lg p-6 mb-6 flex items-center">
					{userIGData.profile_picture ? (
						<img
							src={userIGData.profile_picture}
							alt="Profile"
							className="w-24 h-24 rounded-full mr-6"
						/>
					) : (
						<svg
							className="w-24 h-24 rounded-full mr-6 text-gray-500"
							fill="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
								clipRule="evenodd"
							/>
						</svg>
					)}
					<div>
						<p className="text-lg ">{userIGData.username}</p>
						<p className="text-gray-700">
							<strong>Account Type:</strong> {userIGData.account_type}
						</p>
						<p className="text-gray-700">
							<strong>Media Count:</strong> {userIGData.media_count}
						</p>
					</div>
				</div>
			)}
			{userIGData.media && <IGMedia mediaData={userData.media} />}
		</>
	);
};

export default InstaChannelStats;
