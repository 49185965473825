import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { RiAdvertisementLine, RiLogoutBoxRLine } from "react-icons/ri";
import { AiOutlineClose, AiFillHome } from "react-icons/ai";
import {
	FaBars,
	FaHandPointRight,
	FaRegBookmark,
	FaChair,
	FaUser,
	FaFileUpload,
	FaComments,
	FaHandsHelping,
	FaBell,
	FaCalendarAlt,
	FaClipboardList,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GiDramaMasks } from "react-icons/gi";

import CollabImage from "../../assets/images/collab.png";
import CollabImageSelect from "../../assets/images/collabselect.png";
import SlotImage from "../../assets/images/booking.png";
import SlotImageSelected from "../../assets/images/bookingselect.png";

import "./minicomp.css";
import home from "../../assets/icons/home.svg";
import ahome from "../../assets/icons/144-home copy.svg";
import directorchair from "../../assets/icons/director-chair.svg";
import clapperboard from "../../assets/icons/clapperboard.svg";
import thumbsup from "../../assets/icons/Group 156.svg";
import notification from "../../assets/icons/211-notification.png";
import chat from "../../assets/icons/045-chat-1.svg";
import profile from "../../assets/icons/profile1.svg";
import mask from "../../assets/icons/Group 110.svg";

import achair from "../../assets/icons/active-chair.svg";
import achat from "../../assets/icons/active-chat.svg";
import afingers from "../../assets/icons/active-fingers.svg";
import amask from "../../assets/icons/active-mask.svg";
import anotification from "../../assets/icons/active-notification.svg";
import role from "../../assets/images/role.png";
import arole from "../../assets/images/active-role.png";
import requests from "../../assets/icons/request.png";
import arequests from "../../assets/icons/active-request.png";
import axios from "axios";
import server from "../server";
import { RiAccountCircleFill } from "react-icons/ri";

import AuthContext from "../AuthContext";
import { IconContext } from "react-icons/lib";
import { CiInstagram, CiYoutube } from "react-icons/ci";

const Topbar = (props) => {
	const [profileHeight, setProfileHeight] = useState(0);
	const [notifHeight, setnotifHeight] = useState(0);
	const [dim, setDim] = useState(0);
	const [projects, setProjects] = useState([]);
	const [toggleNav, settoggleNav] = useState(false);

	let location = useLocation();

	const auth = useContext(AuthContext);
	const active = auth.active;

	let {
		socket,
		setSocket,
		setSocketConnected,
		setIsTyping,
		chatUnReadCount,
		setChatUnReadCount,
		getunreadonTopbar,
	} = auth;
	const user = JSON.parse(localStorage.getItem("login"));
	const navigate = useNavigate();

	useEffect(() => {
		// setSocket(prev => prev = io(ENDPOINT));
		socket.emit("setup", user);
		socket.on("connected", () => setSocketConnected(true));

		socket.off("setNotify").on("setNotify", (chat) => {
			// console.log("Topbar.jsx 55 :", chat)
			if (location.pathname != "/chat") {
				// console.log("Topbar 86 Setting chat unread count")
				setChatUnReadCount((prev) => {
					// console.log(prev);
					return prev + 1;
				});
				incrementChatCount([chat]);
			}
		});

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		localStorage.setItem("UnReadNotify", JSON.stringify(chatUnReadCount));
	}, [chatUnReadCount]);

	function toggleProfileOptions() {
		if (profileHeight == 0) {
			setProfileHeight(270);
			setDim(1);
			document.getElementById("profileOption").style.height = "fit-content";
		} else {
			setProfileHeight(0);
			setDim(0);
			document.getElementById("profileOption").style.height = "0px";
		}
	}
	function toggleNotifOption() {
		if (notifHeight == 0) {
			setnotifHeight(30);
			setDim(1);

			document.getElementById("notifOption").style.height = "20vh";
			document.getElementById("notifOption").style.overflowY = "scroll";
		} else {
			setnotifHeight(0);
			setDim(0);

			document.getElementById("notifOption").style.height = "0px";
		}
	}

	const [modal, setModal] = useState(false);

	const incrementChatCount = (localChats) => {
		if (localChats && localChats.length > 0) {
			localChats.map(async (item) => {
				try {
					const config = {
						headers: {
							"Content-type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					};
					await axios
						.put(`${server}/api/chat/incrChat`, { item }, config)
						.then((response) => {
							// console.log(response)
						});
				} catch (error) {
					console.log(error.message);
				}
			});
		}
	};

	function handleLogout() {
		localStorage.clear();
		navigate("/login");
		console.log("Logout succesfull");
	}

	const [jobs, setJobs] = useState();
	const [jobProjects, setJobProjects] = useState([]);
	const [jobUsers, setJobUsers] = useState([]);

	const dataFetchedRef = useRef(false);
	const [userProjectMap, setUserProjectMap] = useState([]);
	const [ForIds, setForIds] = useState();

	const [jobRoles, setJobRoles] = useState([]);

	const getProjects = async () => {
		const res = await fetch(`${server}/project/allProjectsSeekers`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();
		if (response !== null) {
			setProjects(response);
		}
	};

	const getJobapplicationIds = async () => {
		const res = await fetch(`${server}/application/allJobsUser`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();
		setForIds(response);
	};

	const getJobApplications = async () => {
		const res = await fetch(`${server}/application/allJobsSeeker`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();

		setJobs(response);

		getUsers(response);
		getJobProjects(response);
	};

	function getUsers(jobs) {
		jobs?.map((job, index) => {
			axios
				.get(`${server}/project/UserId/${job.userId}`)
				.then((res) => {
					if (res !== null) {
						setJobUsers((oldUsers) => [...oldUsers, res.data]);
					}
					// console.log(res.data)
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	function getJobProjects(jobs) {
		jobs?.map((job, index) => {
			axios
				.get(`${server}/project/projectDetails/${job.pId}`)
				.then((res) => {
					if (res !== null) {
						setJobProjects((oldProjects) => [...oldProjects, res.data]);
					}
					// console.log(res.data)
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	const getUnReadCount = async () => {
		// console.log(user._id);
		try {
			const config = {
				headers: { Authorization: `Bearer ${user.token}` },
			};

			await axios
				.get(`${server}/api/chat/getUnreadCount`, config)
				.then((response) => {
					setChatUnReadCount(response.data);
				});
		} catch (error) {
			console.log(error.message);
		}
	};
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		getProjects();
		getJobapplicationIds();
		getJobApplications();
		let localUnReadCount = JSON.parse(localStorage.getItem("UnReadNotify"));
		if (!localUnReadCount) {
			getUnReadCount();
		} else {
			auth.setChatUnReadCount(localUnReadCount);
		}
	}, []);

	useEffect(() => {
		if (jobUsers.length == 0 || jobProjects.length == 0) {
			return;
		}
		let len = jobProjects.length === jobUsers.length ? jobProjects.length : 0;
		let arr = new Set();
		let imgArr = new Set();

		for (let index = 0; index < len; index++) {
			const userName = jobUsers[index][0]?.username;
			const projectName = jobProjects[index][0]?.basicInfo?.fnaem;
			const image = jobUsers[index][0]?.link;

			let mapObj = {
				user: userName,
				project: projectName,
			};
			arr.add({
				notification: `${mapObj.user} has applied to project ${mapObj.project}`,
				img: image,
			});
		}
		// console.log(arr)
		setUserProjectMap([...arr]);
	}, [jobUsers, jobProjects]);

	useEffect(() => {
		setLoggedUser(JSON.parse(localStorage.getItem("login")));
	}, []);

	// ============talent=========================
	const [jobsTalent, setJobsTalent] = useState([]);
	const [jobRolesTalent, setJobRolesTalent] = useState([]);
	const [rolesNotification, setRolesNotification] = useState([]);
	const [views, setViews] = useState([]);
	const [viewUsers, setViewUsers] = useState([]);
	const [viewsNotification, setViewNotification] = useState([]);
	const [loggedUser, setLoggedUser] = useState("");

	const dataFetchedRefTalent = useRef(false);

	const getJobApplicationsTalent = async () => {
		const res = await fetch(`${server}/application/allJobsUser`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});

		const response = await res.json();
		setJobsTalent(response);
		getJobRolesTalent(response);
	};

	function getUsersTalent(views) {
		views?.map((view, index) => {
			axios
				.get(`${server}/project/UserId/${view.seekerId}`)
				.then((res) => {
					console.log(res.data);
					if (res !== null) {
						setViewUsers((oldUsers) => [...oldUsers, res.data]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	function getJobRolesTalent(jobsTalent) {
		jobsTalent?.map((job, index) => {
			axios
				.get(`${server}/project/getCharacter/${job.roleId}`)
				.then((res) => {
					setJobRolesTalent((oldRoles) => [...oldRoles, res.data]);
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	const [count, setCount] = useState([]);
	var arr = [];

	// =============== getreq count ========================

	const getreqcount = async () => {
		const res = await fetch(`${server}/auth/reqcount`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
		const response = await res.json();
		setCount((prev) => [...prev, response]);
		arr.push({ res: response });
	};

	const getReqToApp = () => {
		axios
			.get(`${server}/profile/reqToApp/${user._id}`)
			.then((res) => {
				setViews(res.data);
				console.log({ requests: res.data });
				getUsersTalent(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (dataFetchedRefTalent.current) return;
		dataFetchedRefTalent.current = true;
		getJobApplicationsTalent();
		getReqToApp();
	}, []);

	useEffect(() => {
		let roles = new Set();
		jobRolesTalent?.map((job) => {
			job?.map((item) => {
				roles.add(`${item.roles[0].role} in ${item.basicInfo.name}`);
			});
		});
		let rolesArr = [...roles];
		rolesArr.reverse();
		setRolesNotification(rolesArr);
	}, [jobRolesTalent]);

	useEffect(() => {
		let roles = new Set();
		console.log("viewUsers", viewUsers);
		viewUsers?.map((view) => {
			console.log("view", view);
			// if (Object.keys(view).length != 0) {

			// view?.map((item) => {
			roles.add({ notification: view?.username, img: view?.link });
			// })
			// }
		});
		let viewsArr = [...roles];
		viewsArr.reverse();
		setViewNotification(viewsArr);
	}, [viewUsers]);

	const handleNavbar = () => {
		if (toggleNav) {
			document.querySelector(".topbar-nav").style.display = "none";
			settoggleNav(false);
		} else {
			document.querySelector(".topbar-nav").style.display = "flex";
			settoggleNav(true);
		}
	};

	useEffect(() => {
		if (getunreadonTopbar.current < 1) {
			getUnReadCount();
			getunreadonTopbar.current++;
		}

		getreqcount();
	}, []);

	if (rolesNotification.length <= 2 && viewsNotification.length <= 2) {
		auth.setNotificationCount(
			rolesNotification.length + viewsNotification.length
		);
	} else {
		auth.setNotificationCount(4);
	}

	if (projects.length <= 2 && userProjectMap.length <= 2) {
		auth.setNotificationCountSeeker(projects.length + userProjectMap.length);
	} else {
		auth.setNotificationCountSeeker(4);
	}

	return (
		<>
			<div className="topbar ">
				<div className="topbar-name ">
					{active === "profiledetails" ||
					// active === "chat" ||
					active === "chair" ||
					active === "projectcreation" ? (
						<span
							className="navToggleSpecial"
							onClick={handleNavbar}
						>
							{toggleNav ? <AiOutlineClose /> : <FaBars />}
						</span>
					) : (
						<></>
					)}
					<span style={{ marginLeft: "10px" }}>Chance</span>

					<br />
					<span style={{ marginLeft: "10px" }}>Guru</span>

					{active === "profiledetails" ||
					// active === "chat" ||
					active === "chair" ||
					active === "projectcreation" ? (
						<></>
					) : (
						<span
							className="navToggle"
							onClick={handleNavbar}
						>
							{toggleNav ? <AiOutlineClose /> : <FaBars />}
						</span>
					)}
				</div>

				<div className="topbar-nav">
					<Link
						to={`/${user.type}dashboard`}
						onClick={() => auth.setActive("home")}
						state={null}
					>
						<span
							className={
								active === "home"
									? `nav_active topbar-icons-container`
									: "topbar-icons-container"
							}
						>
							{active === "home" ? (
								<AiFillHome className="topbar-icons" />
							) : (
								<AiFillHome className="topbar-icons" />
							)}
							<span className="nav-item-name">Home</span>
						</span>
					</Link>
					{(user.type === "seeker" || user.type === "admin") && (
						<>
							<Link
								to="/submission"
								onClick={() => auth.setActive("chair")}
							>
								<span
									className={
										active === "chair"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
								>
									{active === "chair" ? (
										<FaFileUpload className="topbar-icons" />
									) : (
										<FaFileUpload className="topbar-icons" />
									)}

									<span className="nav-item-name">Audition</span>
								</span>
							</Link>

							<Link to="/rolesdashboard">
								<span
									className={
										active === "mask"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
									onClick={() => auth.setActive("mask")}
								>
									{active === "mask" ? (
										<GiDramaMasks className="topbar-icons" />
									) : (
										<GiDramaMasks className="topbar-icons" />
									)}
									<span className="nav-item-name">Roles</span>
								</span>
							</Link>
							<Link
								to="/browseprofile"
								onClick={() => auth.setActive("fingers")}
							>
								<span
									className={
										active === "fingers"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
								>
									{active === "fingers" ? (
										<FaUser className="topbar-icons" />
									) : (
										<FaUser className="topbar-icons" />
									)}
									<span className="nav-item-name">Browse</span>
								</span>
							</Link>
							<Link
								to="/browseinfluencer"
								onClick={() => auth.setActive("influencer")}
							>
								<span
									className={
										active === "influencer"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
								>
									{active === "influencer" ? (
										<IconContext.Provider
											value={{ color: "#8443e5", size: "25px" }}
										>
											<RiAdvertisementLine style={{ margin: "10px" }} />
										</IconContext.Provider>
									) : (
										<IconContext.Provider value={{ size: "25px" }}>
											<RiAdvertisementLine style={{ margin: "10px" }} />
										</IconContext.Provider>
									)}
									<span className="nav-item-name">Influencers</span>
								</span>
							</Link>
							<Link to="/bookedslot">
								<span
									className={
										active === "bookedslots"
											? `nav_active topbar-icons-container bubbleDiv bubbleColorChange`
											: "topbar-icons-container bubbleDiv"
									}
									onClick={() => auth.setActive("bookedslots")}
								>
									{active === "bookedslots" ? (
										<FaRegBookmark className="topbar-icons" />
									) : (
										<FaRegBookmark className="topbar-icons" />
									)}
									<span className="nav-item-name">Bookings</span>
								</span>
							</Link>
						</>
					)}

					{user.type == "user" && (
						<Link to="/myrole">
							<span
								className={
									active === "mask"
										? `nav_active topbar-icons-container`
										: "topbar-icons-container"
								}
								onClick={() => auth.setActive("mask")}
							>
								{active === "mask" ? (
									<GiDramaMasks className="topbar-icons" />
								) : (
									<GiDramaMasks className="topbar-icons" />
								)}

								<span className="nav-item-name">My Roles</span>
							</span>
						</Link>
					)}

					{user.type == "influencer" && (
						<>
							<Link to="/youtubeinfo">
								<span
									className={
										active === "youtube"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
									onClick={() => auth.setActive("youtube")}
								>
									{active === "youtube" ? (
										<IconContext.Provider
											value={{ color: "#8443e5", size: "35px" }}
										>
											<CiYoutube style={{ margin: "5px" }} />
										</IconContext.Provider>
									) : (
										<IconContext.Provider value={{ size: "35px" }}>
											<CiYoutube style={{ margin: "5px" }} />
										</IconContext.Provider>
									)}

									<span className="nav-item-name">My Channel</span>
								</span>
							</Link>
							<Link to="/instagraminfo">
								<span
									className={
										active === "instagram"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
									onClick={() => auth.setActive("instagram")}
								>
									{active === "instagram" ? (
										<IconContext.Provider
											value={{ color: "#8443e5", size: "35px" }}
										>
											<CiInstagram style={{ margin: "5px" }} />
										</IconContext.Provider>
									) : (
										<IconContext.Provider value={{ size: "35px" }}>
											<CiInstagram style={{ margin: "5px" }} />
										</IconContext.Provider>
									)}

									<span className="nav-item-name">My Instagram</span>
								</span>
							</Link>
							<Link to="/collabinfo">
								<span
									className={
										active === "collab"
											? `nav_active topbar-icons-container`
											: "topbar-icons-container"
									}
									onClick={() => auth.setActive("collab")}
								>
									{active === "collab" ? (
										<FaHandsHelping className="topbar-icons" />
									) : (
										<FaHandsHelping className="topbar-icons" />
									)}
								</span>
							</Link>
							<Link to="/slotinfo">
								<span
									className={
										active === "slot"
											? `nav_active topbar-icons-container bubbleColorChange`
											: "topbar-icons-container"
									}
									onClick={() => auth.setActive("slot")}
								>
									{active === "slot" ? (
										<FaCalendarAlt className="topbar-icons" />
									) : (
										<FaCalendarAlt className="topbar-icons" />
									)}
									<span className="nav-item-name">Slots</span>
								</span>
							</Link>
						</>
					)}

					{user.type !== "admin" ? (
						<Link to="/chat">
							<span
								className={
									active === "chat"
										? `nav_active topbar-icons-container bubbleDiv bubbleColorChange`
										: "topbar-icons-container bubbleDiv"
								}
								onClick={() => auth.setActive("chat")}
							>
								{active === "chat" ? (
									<FaComments className="topbar-icons" />
								) : (
									<FaComments className="topbar-icons" />
								)}

								{chatUnReadCount > 0 && <h6>{chatUnReadCount}</h6>}

								<span className="nav-item-name">Chats</span>
							</span>
						</Link>
					) : (
						""
					)}

					{user.type === "user" && (
						<Link to="/requestpage">
							<span
								className={
									active === "request"
										? `nav_active topbar-icons-container bubbleDiv bubbleColorChange`
										: "topbar-icons-container bubbleDiv"
								}
								onClick={() => auth.setActive("request")}
							>
								{active === "request" ? (
									<FaClipboardList className="topbar-icons" />
								) : (
									<FaClipboardList className="topbar-icons" />
								)}

								{count?.map((item) => {
									return item?.isMarked === false ? (
										<h6>{count.length}</h6>
									) : (
										<h6>0</h6>
									);
								})}
								<span className="nav-item-name">Requests</span>
							</span>
						</Link>
					)}

					{/* <Link to="/projectcreation"> */}
					<Link
						to="/notification"
						onClick={() => auth.setNotificationCount(0)}
					>
						<span
							className={
								active === "notification"
									? `topbar-icons-container n_icon bubbleDiv bubbleColorChange`
									: "topbar-icons-container n_icon  bubbleDiv"
							}
							onClick={() => {
								auth.setActive("notification");
							}}
						>
							{active === "notification" ? (
								<FaBell className="topbar-icons" />
							) : (
								<FaBell className="topbar-icons" />
							)}
							{user.type === "user" ? (
								auth.notificationCount !== 0 ? (
									<h6>{auth.notificationCount}</h6>
								) : (
									""
								)
							) : auth.notificationCountSeeker !== 0 ? (
								<h6>{auth.notificationCountSeeker}</h6>
							) : (
								""
							)}
							<span className="nav-item-name">Notifications</span>
						</span>
					</Link>

					<span
						className="d-flex w-full align-items-center cursor-pointer position-relative"
						onClick={toggleProfileOptions}
					>
						<span className="topbar-icons-container">
							{loggedUser.link ? (
								<img
									className="topbar-icons topbar-profile "
									src={loggedUser.link}
									alt=""
								/>
							) : (
								<RiAccountCircleFill style={{ fontSize: "2rem" }} />
							)}
						</span>
						<span className="top-profile-name influncer-nomargin">
							{user.fname}
						</span>
						<div
							className="profile-options h-full w-full"
							id="profileOption"
						>
							<ul className="space-y-1">
								{user.type == "user" ? (
									<>
										<li>
											<Link to="/profiledetails">My Profile</Link>
										</li>
										<li>
											<NavLink
												to="/myapplication"
												state={ForIds}
											>
												My Applications
											</NavLink>
										</li>
									</>
								) : (
									<></>
								)}
								{user.type === "admin" ? (
									<>
										<li>
											<NavLink
												to="/skills"
												exect
											>
												Skills
											</NavLink>
										</li>
										<li>
											<NavLink to="/adminroles">Roles</NavLink>
										</li>
									</>
								) : (
									<></>
								)}
								<li>
									<NavLink
										state={loggedUser}
										to="/setting"
									>
										Settings
									</NavLink>
								</li>
								<li>
									<NavLink to="/help">FAQ's & Help</NavLink>
								</li>
								<li onClick={() => setModal(true)}>
									<a>Logout</a>
								</li>
							</ul>
						</div>
					</span>

					{modal && (
						<div
							className="fixed p-4 inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
							style={{ zIndex: "1000" }}
						>
							<div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
								<div className="flex justify-between items-center mb-4">
									<h1 className="text-2xl font-bold text-purple-600 m-0">
										Logout Confirmation
									</h1>
									<RiLogoutBoxRLine
										style={{ fontSize: "30px", color: "#8443e5" }}
									/>
								</div>
								<p className="mb-4">Are you sure you want to Logout?</p>
								<div className="flex justify-between space-x-4">
									<button
										onClick={() => setModal(false)}
										className="bg-gray-200 text-gray-700 px-4 py-2 rounded shadow hover:bg-gray-300"
									>
										Cancel
									</button>
									<button
										onClick={handleLogout}
										className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Topbar;
