import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import server from "../../server";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const RolePref = ({ display, userData }) => {
  const [bool, setbool] = useState(true);
  const animatedComponents = makeAnimated();
  const user = JSON.parse(localStorage.getItem("login"));
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false)

  let show = {};
  if (display) {
    show = { display: "block" };
  } else {
    show = { display: "none" };
  }

  const [formFields, setformFields] = useState([]);
  const [Roles, setRoles] = useState([]);
  const handleModalYes = () => {
    setConfirmModal(false)
    toast("Profile Data saved successfully", {
      autoClose: 2000,
    })
    setTimeout(() => {
      navigate("/userdashboard");
    }, 2000)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (formFields.length == 0) {
      alert("Please select role first.");
    } else {
      if (user.type === "admin") {
        axios
          .put(
            `${server}/profile/AdminSiderolePref/${userData._id}`,
            { formFields })
          .then((res) => {
            toast("Profile Data saved successfully", {
              autoClose: 2000,
            })
            setTimeout(() => {
              navigate("/");
            }, 2000)
          });
      } else {
        if (bool) { 
          axios
            .post(`${server}/profile/`, { rolePref: formFields }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              toast("Profile details saved successfully", {
                autoClose: 2000,
              });
            })
            .catch((err) => {
              toast(err.message, {
                autoClose: 2000,
              });
            })
        } else {
          axios
            .put(
              `${server}/profile/rolePref`,
              { formFields },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              toast("Role Preferences saved successfully", {
                autoClose: 2000,
              })

            });
        }
      }
    }
  };

  const handleShow = async () => {
    axios
      .get(`${server}/profile/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data !== null) {
          setbool(false);
          if (response.data.rolePref !== 0) {
            setformFields(response.data.rolePref);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getRoles = () => {
    axios
      .get(`${server}/admin/roles`)
      .then((res) => {
        res.data.forEach(item => {
          setRoles(prev => [...prev, { value: item._id, label: item.role }]);
        })
      })
      .catch((err) => { console.log(err) });
  }

  const handleFormfields = (id) => {
    setformFields(formFields.filter((item) => {
      return item.value !== id ;
    }))
  }

  // ============ fetching roles data =============

  const getRolesAtadminSide = (data) => {
    data.map(async (item) => {
      const res = await fetch(`${server}/admin/getRolesAtadminSide/${item.roleId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const data = await res.json();
      if (data !== null) {
        setRoles(prev => [...prev, { role: data[0]?.role }]);
      }
    })
  }

  useEffect(() => {
    if (user.type === "user") {
      handleShow();
      getRoles();
    }
    else {
      getRolesAtadminSide(userData.rolePref)
      setformFields(userData.rolePref);
    }

  }, []);

  return (
    <>
      {
        <div className="form-body" style={show}>
          <div className="form-container">
            <div className="form-head">Role Preferences</div>
            <div className="form-desc">
              Let us know about you to suggest the best for you.
            </div>

            <div>
              {formFields.map((item) => {
                console.log(item.value)
                return(
                  <>
                  <div className="cross-wala-div">{item.label} <span onClick={()=>{handleFormfields(item.value)}} className="times">&times;</span></div>
                  </>
                )
              })}
            </div>
          <form onSubmit={handleFormSubmit}>
              <Select isMulti
                value={formFields}
                className="mb-3"
                onChange={(e) => { setformFields(e) }}
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={Roles} />

              <div className="buttons justify-content-between mt-5">
                <input
                  type="submit"
                  className="col-4 cancel-btn btn btn-lg btn-block my-2"
                  value="Cancel"
                />
                <p className="col-1"></p>
                <input
                  type="submit"
                  className="col-3 save-btn btn btn-lg btn-block my-2"
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>
      }
      {
        confirmModal && (
          <div className="userSub_modal my-4 ">
            <div className="modal_child shadow ">
              <div className="d-flex justify-content-start align-items-center m-3">
                <h1 className="purple_title m-0" style={{ fontSize: "30px" }}>
                  Profile Data
                </h1>
              </div>

              <p>Submit Profile Data?</p>
              <div className="btns">
                <button onClick={() => setConfirmModal(false)}>Cancel</button>
                <button onClick={handleModalYes}>Confirm</button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default RolePref;
