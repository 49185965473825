// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./svg/activities.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./svg/animals_nature.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./svg/flags.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./svg/food_drink.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./svg/objects.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./svg/smileys_people.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./svg/symbols.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./svg/travel_places.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, ".emoji-picker-react .emoji-categories button {\n    height: 40px;\n    width: 20px;\n    padding: 5px 0;\n    background-repeat: no-repeat;\n    background-size: 20px;\n    background-position: 50% 50%;\n    cursor: pointer;\n    opacity: .5;\n    transition: opacity .1s;\n}\n\n.emoji-picker-react .emoji-categories button.icn-activities     { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n.emoji-picker-react .emoji-categories button.icn-animals_nature { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); }\n.emoji-picker-react .emoji-categories button.icn-flags          { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "); }\n.emoji-picker-react .emoji-categories button.icn-food_drink     { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "); }\n.emoji-picker-react .emoji-categories button.icn-objects        { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "); }\n.emoji-picker-react .emoji-categories button.icn-smileys_people { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + "); }\n.emoji-picker-react .emoji-categories button.icn-symbols        { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "); }\n.emoji-picker-react .emoji-categories button.icn-travel_places  { background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "); }\n\n\n.emoji-picker-react .emoji-categories {\n    padding: 0 15px;\n    display: flex;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n.emoji-picker-react .emoji-categories.inactive button,\n.emoji-picker-react .emoji-categories.inactive button.active,\n.emoji-picker-react .emoji-categories.inactive button:hover {\n    opacity: .4;\n    cursor: default;\n}\n\n.emoji-picker-react .emoji-categories button.active {\n    opacity: 1;\n}\n\n.emoji-picker-react .emoji-categories button:hover {\n    opacity: .7;\n}\n", ""]);
// Exports
module.exports = exports;
