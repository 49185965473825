import React, { useContext, useEffect, useState } from "react";
import Searchbar from "./mini_components/Searchbar";
import Topbar from "./mini_components/Topbar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsChevronDown, BsPhone } from "react-icons/bs";

import axios from "axios";
import Contect from "./Contect";
import server from "./server";
import Images from "./Images";
import AuthContext from "./AuthContext";
import BrowseProfileImg from "../assets/images/browse_profile.png"

const BrowseProfile = () => {
  const [query, setQuery] = useState("");
  const [profileData, setProfileData] = useState();
  const [select, setSelect] = useState("");
  const [searchData, setsearchData] = useState([]);
  const location = useLocation();
  const [skills, setSkills] = useState();
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true)

  const auth = useContext(AuthContext)


  const setGet = (e) => {
    const { name, value } = e.target;

    value != "" && setSelect(() => {
      return {
        ...select,
        [name]: value,
      };
    });
  };

  const handleSelect = async () => {
    if (select) {
      console.log(select)
      const data = await fetch(
        `${server}/profile/SelectData?role=${select.select}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await data.json();
      if (res) {
        setProfileData(res);
      }
    }

  };



  const handleSearch = async () => {
    const data = await fetch(
      `${server}/profile/ProData?fullname=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const res = await data.json();
    if (res) {
      setProfileData(res);
    }
  };
  useEffect(() => {
    if (query != "") {
      handleSearch();
    }
    else {

    }
  }, [query])


  const getuserData = () => {
    axios
      .get(`${server}/admin/roles`)
      .then((res) => {
        if (res !== null) {
          setRoles(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getuserSkills = () => {
    axios
      .get(`${server}/admin/skills`)
      .then((res) => {
        if (res !== null) {
          setSkills(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const GetProfiledata = async () => {
    const data = await fetch(`${server}/profile/profileData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await data.json();
    console.log(res)
    setsearchData(res);
    if (res) {
      // setProfileData(res);
    }
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/projectcreation`;
    navigate(path);
  };

  useEffect(() => {
    auth.setActive("fingers")
    GetProfiledata();
    getuserData();
    getuserSkills();
  }, []);

  function viewProfileClicked(item) {
    console.log(item)

    axios.post(`${server}/profile/ReqToApp`, { talentId: item.userId }, {

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

    })
      .then(res => {
        console.log(res.data);

      })
      .catch(err => {
        console.log(err);
      });
  }
  return (
    <div>
      <Topbar />
      <div className="container">
        <div className="row">
          <div className="searchBox col-lg-8">
            <Searchbar
              setQuery={setQuery}
              query={query}
              handleSearch={handleSearch}
            />
            <div
              className="searchDropdown"
              style={query === "" ? { border: "none" } : {}}
            >
              {searchData
                .filter((item, index) => {
                  const searchTerm = query?.toLowerCase();
                  let name = item.basicInfo?.fname + " " + item.basicInfo?.lname;
                  name = name?.toLowerCase()
                  return searchTerm && name.startsWith(searchTerm);
                })
                .map((item, index) => {
                  return (
                    item.basicInfo && (<div onClick={() => { setQuery(item.basicInfo.fname + " " + item.basicInfo.lname) }}>
                      {item.basicInfo.fname + " " + item.basicInfo.lname}
                    </div>)
                  )
                })}
            </div>
          </div>
          <div className="col-lg-4">
            <button
              className="btn btn-primary create-btn"
              onClick={routeChange}
            >
              Create New Project
            </button>
          </div>
        </div>

        {
          profileData?.length > 0 ? <div className="filter d-flex justify-content-between align-item-center  mb-2 mt-2">
            <p>Search Results</p>
            <select
              value={select.select}
              name="select"
              className="bg-light p-1 border-0"
              onClick={handleSelect}
              onChange={setGet}
            >
              <option selected value="">Filter</option>
              {
                roles?.map((item) => {
                  return (
                    <option>{item.role}</option>
                  )
                })
              }

              {
                skills?.map((item) => {
                  return (
                    <option>{item.skill}</option>
                  )
                })
              }
            </select>
          </div>
            : <></>
          //  <div>
          //   <img src={Search} alt="" style={{
          //     width: "100%",
          //     height: "100%"
          //   }} />
          // </div>
        }


        {
          !loading ? (

            <div >
              {profileData ? (
                <div className="b_table scroll-container" >
                  <table style={{ width: "100%", overflowX: "scroll", }}>
                    <thead>
                      <td></td>
                      <td style={{ textAlign: "left", paddingLeft: "30px" }}>Talents</td>
                      <td style={{ textAlign: "left", paddingLeft: "30px" }}></td>
                      <td style={{ textAlign: "left", paddingLeft: "30px" }}>Roles Interested</td>
                      <td style={{ textAlign: "left", paddingLeft: "30px" }}>Location</td>
                      <td style={{ textAlign: "left", paddingLeft: "30px" }}>Contact</td>
                    </thead>
                    <tbody>
                      {
                        (
                          profileData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="sticky-left">
                                  <div>
                                    <NavLink
                                      to={item.basicInfo?.fname || item.basicInfo?.lname ? "/browseprofile/" + item.basicInfo?.fname + "-" + item.basicInfo?.lname : ""}
                                      state={{ user: item, card: [], index: 0, btn: 0 }}
                                      onClick={() => viewProfileClicked(item)}
                                      exact
                                    >
                                      <button>View Profile</button>
                                    </NavLink>
                                  </div>
                                </td>
                                <td >
                                  <Images item={item} key={item._id} />
                                </td>
                                <td >
                                  <p>{item.basicInfo?.fname} {" "} {item.basicInfo?.lname}</p>
                                </td>
                                <td className="mobile-wrap">
                                  {item.rolePref.length !== 0
                                    ? item.rolePref?.map((i) => {
                                      // console.log(i);
                                      return (
                                        <>
                                          <span>{i.label}, </span>
                                          {/* <br /> */}
                                        </>
                                      );
                                    })
                                    : "No role preferences found"}
                                </td>
                                <td>
                                  {" "}
                                  {item.basicInfo?.address
                                    ? item.basicInfo.address
                                    : "No address"}{" "}
                                </td>
                                <td><Contect index={index} userId={item.userId} key={item._id + "cont"} /> </td>
                                {/* <td>
                                  <div>
                                    <NavLink
                                      to={item.basicInfo?.fname || item.basicInfo?.lname ? "/browseprofile/" + item.basicInfo?.fname + "-" + item.basicInfo?.lname : ""}
                                      state={{ user: item, card: [], index: 0, btn: 0 }}
                                      onClick={() => viewProfileClicked(item)}
                                      exact
                                    >
                                      <button>View Profile</button>
                                    </NavLink>
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })
                        )
                      }
                    </tbody>
                  </table>
                </div>
              )
                : <div style={{ textAlign: "center" }}>
                  <img src={BrowseProfileImg} alt="" style={{
                    width: "50%",
                    height: "100%"
                  }} />
                  <br />
                  No data to show try again later! OR try searching with different query

                </div>
                // </>
              }
            </div>
          )
            : (
              <div className="loader"></div>
            )
        }
      </div>
    </div>
  );
};

export default BrowseProfile;
