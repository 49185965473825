import React, { useEffect, useState } from 'react'
import profile from "../assets/icons/profile1.svg";
import server from './server';

const Images = ({ item, key }) => {
    const [Image, setImage] = useState("");

    const handleSelect = async () => {
        const data = await fetch(`${server}/auth/UserImageFromUserTable/${item.userId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await data.json();
        setImage(res.link)
    };

    useEffect(() => {
        handleSelect();
    }, [])

    return (

        <img key={key} src={Image ? Image : profile} className='image-profile' style={{
            // width: "100%",
            // height: "100%",

            // display: "inline-block"
        }} />

    )
}

export default Images
