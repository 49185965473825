import React from "react";
import { useState, useEffect } from "react";
import "../forms.css";
import axios from "axios";
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import server from "../../server";
import { BsTrash } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { CiEdit } from "react-icons/ci";


const PhotoVideoForm = ({ display, toggleForm, userData }) => {
    const [bool, setbool] = useState(true);

    let photoForm = document.getElementById("photo-form");
    let vidForm = document.getElementById("vid-form");
    let photoToggle = document.getElementById("photo-toggle");
    let vidToggle = document.getElementById("vid-toggle");
    const user = JSON.parse(localStorage.getItem("login"));

    const [newPhotoUrl, setNewPhotoUrl] = useState()
    const toggle = (cur_form) => {
        if (cur_form == "photo") {
            photoForm.style.display = "block";
            vidForm.style.display = "none";
            photoToggle.classList.add("active-toggle");
            vidToggle.classList.remove("active-toggle");
        } else {
            vidForm.style.display = "block";
            photoForm.style.display = "none";
            photoToggle.classList.remove("active-toggle");
            vidToggle.classList.add("active-toggle");
        }
    };

    let show = {};
    if (display) {
        show = { display: "block" };
    } else {
        show = { display: "none" };
    }


    const [photoURL, setphotoURL] = useState([{
        link: ""
    }]);

    const [videoURL, setvideoURL] = useState([{
        link: ""
    }]);


    const handlePhotoInputChange = (e, index) => {
        let data = [...photoURL];
        data[index].link = e.target.value;
        setphotoURL(data);
    };

    const addFields = (e) => {
        setNewPhotoUrl({link:""})
    };

    const removeFields = (index) => {
        let data = [...photoURL];
        data.splice(index, 1);
        setphotoURL(data);
    };

    const handlePhotoSubmit = (e) => {
        e.preventDefault();
        if (user.type === "admin") {
            axios.put(`${server}/profile/photo`, {
                photoURL
            },
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }
            ).then((res) => {
                toast("Photos url data saved!", {
                    autoClose: 2000,
                })
                toggle("vid");
            })

        } else {
            if (bool) {
                axios
                    .post(`${server}/profile/`, { photos: photoURL }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((res) => {
                        toast("Profile details saved successfully", {
                            autoClose: 2000,
                        });
                        setbool(false);
                        toggle("vid");
                    })
                    .catch((err) => {
                        toast(err.message, {
                            autoClose: 2000,
                        });
                    })
            } else {

                axios.put(`${server}/profile/photo`, {
                    photoURL
                },
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                ).then((res) => {
                    toast("Photos url data saved!", {
                        autoClose: 2000,
                    })
                    toggle("vid");
                })
            }
        }
    }

    const handleVideoInputChange = (e, index) => {
        let data = [...videoURL];
        data[index].link = e.target.value;
        setvideoURL(data);
    };

    const addFieldsVideo = (e) => {
        e.preventDefault();
        let obj = { link: "" };
        setvideoURL([...videoURL, obj]);
    };

    const removeFieldsVideo = (index) => {
        let data = [...videoURL];
        data.splice(index, 1);
        setvideoURL(data);
    };

    const handleVideoSubmit = (e) => {
        e.preventDefault();

        if (user.type === "admin") {
            axios.put(`${server}/profile/AdminSidevideo/${userData._id}`, videoURL,).then((res) => {
                toast("Videos url data saved!", {
                    autoClose: 2000,
                })
                if (res) {
                    toggleForm("skill");
                }
            })

        } else {
            if (bool) {
                axios
                    .post(`${server}/profile/`, { videos: videoURL }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((res) => {
                        toast("Profile details saved successfully", {
                            autoClose: 2000,
                        });
                        setbool(false);
                        toggle("skill");
                    })
                    .catch((err) => {
                        toast(err.message, {
                            autoClose: 2000,
                        });
                    })
            } else {
                axios.put(`${server}/profile/video`, videoURL,
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                ).then((res) => {
                    toast("Videos url data saved!", {
                        autoClose: 2000,
                    })
                    if (res) {
                        toggleForm("skill");
                    }
                })
            }
        }
    }

    const handleShow = async () => {
        axios
            .get(`${server}/profile/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                if (response.data !== null) {
                    setbool(false);
                    if (response.data.photos.length !== 0) {
                        setphotoURL(response.data.photos);
                    }
                    if (response.data.videos.length !== 0) {
                        setvideoURL(response.data.videos);
                    }
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    }
    useEffect(() => {
        handleShow();
    }, [])

    //s3 bucket image upload
    AWS.config.update({
        accessKeyId: 'AKIAUXONWQ3HERXZEX5M',
        secretAccessKey: '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',
        region: 'ap-south-1',
        signatureVersion: 'v4',
    });

    const [imageUrl, setImageUrl] = useState([{
        link: ""
    }]);
    const [file, setFile] = useState([null]);

    const handleFileInputChange = (e) => {
        setFile(e.target.files);

    }

    const handleFileUpload = async () => {
        if (!file) {
            return;
        }

        const s3 = new AWS.S3();
        const formData = new FormData();
        const urls = [];

        for (let i = 0; i <= file.length; i++) {
            formData.append("images", file[i]);
            var files = file[i];
            const params = {
                Bucket: 'image-orders-bucket',
                Key: `${Date.now()}.${files.name}`,
                Body: files,
                ContentEncoding: "base64",
                ContentType: file.type,
            };
            const { Location } = await s3.upload(params).promise();
            urls.push(Location);
            var obj = { link: urls[i] };
            setImageUrl([...imageUrl, obj]);
            let data = [...photoURL];
            data[i] = urls[i];
            setphotoURL([...photoURL, obj]);
            //setphotoURL([...photoURL, obj]);
            console.log('uploading to s3', urls[i]);
        }
        console.log(photoURL)
    }

    function editImageUrl(e, save = false) {
        e.preventDefault()
        try {

            if (!save) {
                let imageContainer = e.target.parentNode.parentNode
                let buttonContainer = e.target.parentNode
                imageContainer.getElementsByTagName('input')[0].classList.remove("d-none")
                imageContainer.getElementsByTagName('img')[0].classList?.add("d-none")

                buttonContainer.classList.add("d-none")
                imageContainer.getElementsByTagName('button')[0].classList?.remove("d-none")
            }
            else {
                let imageContainer = e.target?.parentNode
                let buttonContainer = imageContainer.getElementsByTagName('div')[0]
                imageContainer.getElementsByTagName('input')[0].classList.add("d-none")
                imageContainer.getElementsByTagName('img')[0].classList.remove("d-none")

                buttonContainer.classList.remove("d-none")
                imageContainer.getElementsByTagName('button')[0].classList.add("d-none")
            }
        } catch (error) {
            toast.error("An error occured please try again or refresh the page", {
                autoClose: 2000,
            })
        }
    }

    function handleAddNewPhoto(e){
        e.preventDefault();
        let obj = { link: newPhotoUrl.link };
        if(newPhotoUrl?.link !== "" && newPhotoUrl?.link !== null && newPhotoUrl?.link !== undefined ){
            setphotoURL([...photoURL, obj]);
            setNewPhotoUrl()
            toast("Photo added successfully", {
                autoClose: 2000,
                position:"bottom-center",
            })
        }
        else{
            toast.error("Please enter valid Link", {
                autoClose: 2000,
                position:"bottom-center",
            })
        }

    }

    return (
      <>
        {" "}
        {
          <div className="form-body" style={show}>
            <div className="form-container">
              <div className="form-head">Portfolio</div>
              <div className="form-desc">
                Upload your Photos & Videos Samples
              </div>
              <div className="form-toggle d-flex justify-content-between  ">
                <div
                  className="toggle-option active-toggle"
                  onClick={() => {
                    toggle("photo");
                  }}
                  id="photo-toggle"
                >
                  Photos
                </div>
                <div
                  className="toggle-option"
                  onClick={() => {
                    toggle("vid");
                  }}
                  id="vid-toggle"
                >
                  Videos
                </div>
              </div>
              <form id="photo-form" onSubmit={handlePhotoSubmit}>
                <input
                  type="file"
                  multiple="true"
                  accept="image/*"
                  id="finput"
                  onChange={handleFileInputChange}
                />
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="button"
                      className="full-width-btn"
                      value="Upload Photo"
                      onClick={handleFileUpload}
                    />

                    <p className="mx-1"></p>
                    <input
                      onClick={addFields}
                      type="button"
                      className="full-width-btn"
                      value="Add Photo Link"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap ">
                  {photoURL.map((item, index) => {
                    return (
                      <>
                        {item?.link && (
                          <>
                            <div
                              className="imageContainer me-2 mb-2"
                              key={index}
                            >
                              <input
                                required={true}
                                type="text"
                                placeholder="Enter photo url"
                                name="photo1"
                                value={item.link}
                                className={`form-control d-none`}
                                onChange={(e) => {
                                  handlePhotoInputChange(e, index);
                                }}
                              />
                              <img
                                src={item.link}
                                key={index}
                                width={"100px"}
                                height={"100px"}
                                alt="Image cannot be loaded. Please check the link"
                              />

                              <div className="buttonContainer d-flex justify-content-between p-2 bg-dark bg-gradient">
                                <IconContext.Provider
                                  value={{
                                    size: "24px",
                                    className: "cursor-pointer",
                                    color: "white",
                                  }}
                                >
                                  <BsTrash
                                    onClick={() => removeFields(index)}
                                  />
                                  <CiEdit onClick={(e) => editImageUrl(e)} />
                                </IconContext.Provider>
                              </div>
                              <button
                                className="btn btn-success d-none"
                                onClick={(e) => editImageUrl(e, true)}
                              >
                                Save
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>

                {newPhotoUrl && (
                  <div className="mt-2">
                    <h5>Add new Photo URL</h5>
                    <input
                      required={true}
                      type="text"
                      placeholder="Enter Photo Url"
                      name="photo1"
                      value={newPhotoUrl.link}
                      className={`form-control`}
                      onChange={(e) => {
                        setNewPhotoUrl((prev) => {
                            return{...prev,["link"]:e.target.value}
                        });
                      }}
                    />
                    <div className="d-flex ">
                      <button
                        className="btn btn-success me-2"
                        onClick={handleAddNewPhoto}
                      >
                        Add
                      </button>
                      <button
                        className="btn btn-danger "
                        onClick={() => setNewPhotoUrl()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                <canvas id="canv1"></canvas>
                {/* <input type="file" multiple="true" accept="image/*" id="finput" onChange={handleFileInputChange} /> */}

                {/* <img src={file} className="photoUpload" alt="" /> */}

                <div className="row">
                  <input
                    type="submit"
                    className="col-4 cancel-btn btn btn-lg btn-block my-2"
                    value="Cancel"
                  />
                  <p className="col-1"></p>
                  <input
                    type="submit"
                    className="col-7 save-btn btn btn-lg btn-block my-2"
                    value="Save"
                  />
                </div>
              </form>
              <form
                id="vid-form"
                style={{ display: "none" }}
                onSubmit={handleVideoSubmit}
              >
                <div className="d-flex">
                  <input
                    onClick={addFieldsVideo}
                    type="button"
                    className="full-width-btn"
                    value="Add Youtube link"
                  />
                  <p className="mx-1"></p>
                  <input
                    onClick={addFieldsVideo}
                    type="button"
                    className="full-width-btn"
                    value="Add Vimeo Link"
                  />
                </div>
                {videoURL.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="d-flex align-items-center">
                        <input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter photo url"
                          name="photo1"
                          value={item.link}
                          onChange={(e) => {
                            handleVideoInputChange(e, index);
                          }}
                        />
                        <i
                          className="fa-solid fa-trash-can mx-2 mb-2"
                          onClick={() => removeFieldsVideo(index)}
                        ></i>
                      </div>
                    </>
                  );
                })}

                <div className="row">
                  <input
                    type="submit"
                    className="col-4 cancel-btn btn btn-lg btn-block my-2"
                    value="Cancel"
                  />
                  <p className="col-1"></p>
                  <input
                    type="submit"
                    className="col-7 save-btn btn btn-lg btn-block my-2"
                    value="Save"
                  />
                </div>
              </form>
            </div>
          </div>
        }{" "}
      </>
    );
};

export default PhotoVideoForm;
