import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import server from "../../server";

const InstagramAuthHandler = () => {
	const history = useNavigate();

	useEffect(() => {
		const exchangeCodeForToken = async (code, igUsername) => {
			try {
				await axios.post(
					`${server}/influencer/instagram/exchange_code`,
					{ code, igUsername },
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				);

				toast.success("Authenticated successfully!");
				history("/instagraminfo");
			} catch (error) {
				console.error("Error exchanging code for token:", error);
				toast.error("Failed to authenticate with Instagram.");
				history("/instagraminfo");
			}
		};

		const handleAuthentication = () => {
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get("code");
			const state = urlParams.get("state");

			if (!code || !state) {
				toast.error("Authentication failed. Please try again.");
				history("/instagraminfo");
				return;
			}

			const { username } = JSON.parse(decodeURIComponent(state));
			exchangeCodeForToken(code, username);
		};

		handleAuthentication();
	}, [history]);

	return (
		<div style={{ textAlign: "center", margin: "50px" }}>
			<div class="loader"></div>
			<p
				style={{
					color: "#333",
					fontSize: "16px",
					fontFamily: "Arial, sans-serif",
				}}
			>
				Authenticating with Instagram, please wait...
			</p>
		</div>
	);
};

export default InstagramAuthHandler;
