import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ChannelStats from "./forms/influencer_YT_details/ChannelStats";
import CollabDetails from "./forms/influencer_YT_details/CollabDetails";
import SlotDetails from "./forms/influencer_YT_details/SlotDetails";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "./AuthContext";

const YoutubeInfo = () => {
	const user = JSON.parse(localStorage.getItem("login"));
	let channelDetailsObj = {
		channelId: "",
		channelName: "",
		channelLink: "",
		subscriberCount: "",
		videoCount: "",
		viewCount: "",
		averageCommentsCount: 0,
		averageLikesCount: 0,
	};

	let collabObj = {
		adType: "",
		price: "",
	};
	let slotObj = {
		startDate: "",
		endDate: "",
		isAvailable: false,
	};
	const [channelDetails, setChannelDetails] = useState(channelDetailsObj);
	const [collabDetails, setCollabDetails] = useState([collabObj]);
	const [slotDetails, setSlotDetails] = useState([slotObj]);

	const auth = useContext(AuthContext);

	const [formSelect, setFormSelect] = useState(1);

	useEffect(() => {}, [channelDetails]);

	function checkIfFormFilled(object) {
		for (const key in object) {
			const element = object[key];
			if (
				key !== "isAvailable" &&
				key !== "hiddenSubscriberCount" &&
				element === ""
			) {
				return true;
			}
		}
		return false;
	}

	const showToastMessage = (message, type = "success") => {
		toast[type](message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	};

	const handleNextClick = () => {
		switch (formSelect) {
			case 1:
				if (checkIfFormFilled(channelDetails)) {
					showToastMessage("Please fill channel details", "error");
				} else {
					setFormSelect((prev) => prev + 1);
				}
				break;
			case 2:
				for (let ind = 0; ind < collabDetails.length; ind++) {
					const element = collabDetails[ind];
					if (checkIfFormFilled(element)) {
						showToastMessage(
							`Entry no. ${ind + 1} is empty please fill it`,
							"error"
						);
						break;
					} else if (ind === collabDetails.length - 1) {
						setFormSelect((prev) => prev + 1);
					}
				}
				break;
			case 3:
				for (let index = 0; index < slotDetails.length; index++) {
					const element = slotDetails[index];
					if (checkIfFormFilled(element)) {
						showToastMessage(
							`Entry no. ${index + 1} is empty please fill it`,
							"error"
						);
						break;
					} else if (index === slotDetails.length - 1) {
						axios
							.post(
								`${server}/influencer/youtube`,
								{
									statistics: channelDetails,
									collabDetails: collabDetails,
									slotDetails: slotDetails,
								},
								{
									headers: {
										Authorization: `Bearer ${localStorage.getItem("token")}`,
									},
								}
							)
							.then((response) => {
								showToastMessage("Slot created Successfully!!");
							})
							.catch((err) => {
								showToastMessage(err.message, "error");
							});
					}
				}
				break;

			default:
				break;
		}
	};

	const EnterinDatabase = () => {
		if (channelDetails.channelId === "") {
			return;
		}
		axios
			.post(
				`${server}/influencer/youtube`,
				{
					statistics: channelDetails,
					collabDetails: collabDetails,
					slotDetails: slotDetails,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				showToastMessage(err.message, "error");
			});
	};

	const handlePrevClick = () => {
		if (formSelect !== 1) {
			setFormSelect((prev) => prev - 1);
		}
	};

	async function getYouTubeInfo() {
		await axios
			.get(`${server}/influencer/ytInfo/${user._id}`)
			.then((response) => {
				let { data } = response;
				if (response.data) {
					setChannelDetails(data.statistics);
					setCollabDetails(data.collabPrices);
					setSlotDetails(data.slot);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		getYouTubeInfo();
		auth.setActive("youtube");
	}, []);

	return (
		<>
			<Topbar />
			<div className="section-youtube-info mobile-overflow-hide p-4">
				<div className="container mx-auto">
					{formSelect === 1 && (
						<ChannelStats
							channelDetails={channelDetails}
							setChannelDetails={setChannelDetails}
							handleNextClick={handleNextClick}
							handlePrevClick={handlePrevClick}
							EnterinDatabase={EnterinDatabase}
						/>
					)}
					{formSelect === 2 && (
						<CollabDetails
							collabObj={collabObj}
							collabDetails={collabDetails}
							setCollabDetails={setCollabDetails}
							handleNextClick={handleNextClick}
							handlePrevClick={handlePrevClick}
						/>
					)}
					{formSelect === 3 && (
						<SlotDetails
							slotObj={slotObj}
							slotDetails={slotDetails}
							setSlotDetails={setSlotDetails}
							handleNextClick={handleNextClick}
							handlePrevClick={handlePrevClick}
						/>
					)}
				</div>
				<ToastContainer />
			</div>
		</>
	);
};

export default YoutubeInfo;
