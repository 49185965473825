import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Topbar from "./mini_components/Topbar";
import axios from "axios"
import server from "./server";
import EyeIcon from '../assets/images/view.png'

const RequestPage = () => {
  const [requests, setRequests] = useState([])
  const [reqUsers, setReqUsers] = useState([])
  const [reqUserMap, setReqUserMap] = useState([])
  const user = JSON.parse(localStorage.getItem("login"))

  function getUsers(applyReqs) {
    applyReqs?.map((item, index) => {
      axios
        .get(`${server}/auth/seeker/${item.seekerId}`)
        .then((res) => {
          if (res !== null) {
            setReqUsers(oldUsers => [...oldUsers, res.data])
          }
        })
        .catch((err) => {
          console.log(err);
        })

    })
  }

  const [marked, setMarked] = useState(true);

  const handleMarked = async () => {
    const data = await fetch(`${server}/auth/markedAsread`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ marked })
    })
    const res = await data.json();
    window.location.reload();
  }

  const [count, setCount] = useState([]);

  const getreqcount = async () => {
    const res = await fetch(`${server}/auth/reqcount`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const response = await res.json();
    console.log(" count ", response)
    setCount((prev) => [...prev, response])
  };

  const getAllRequests = () => {
    axios.get(`${server}/profile/getRequests/${user._id}`)
      .then((res) => {
        if (res.data.length != 0) {
          console.log("requests", res.data)
          setRequests(res.data)
          getUsers(res.data)
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (reqUsers.length == 0 || requests.length == 0) {
      return
    }
    let len = reqUsers.length === requests.length ? reqUsers.length : 0;
    console.log("length", reqUsers)
    let arr = []
    for (let index = 0; index < len; index++) {

      const seekerName = reqUsers[index].fname + reqUsers[index].lname;
      const seekerId = reqUsers[index]._id;
      let requestTime = new Date(requests[index].RequestSendAt);
      console.log(typeof (requestTime))
      requestTime = `${requestTime.getDate()}-${requestTime.getMonth() + 1 < 10 ? `0${requestTime.getMonth() + 1}` : requestTime.getMonth()}-${requestTime.getFullYear()}`
      let mapObj = {
        seeker: seekerName,
        requestTime: requestTime,
        seekerId: seekerId
      }
      arr.push(mapObj)

    }
    console.log(arr)
    if (arr.length != 0) {

      setReqUserMap(arr)
    }

  }, [requests, reqUsers])

  useEffect(() => {
    getAllRequests()
    getreqcount()
  }, [])

  return (
    <>
      <Topbar />

      <div className="requestPage d-flex justify-content-center">
        <div className="b_table mt-5 width-mobile">
          {
            reqUserMap.length > 0 ? (
              <table>
                <thead>
                  <td className="td-desktop-only" style={{ textAlign: "center" }}>Request ID</td>
                  <td style={{ textAlign: "center" }}>Seeker Name</td>
                  <td style={{ textAlign: "center" }}>Date</td>
                  <td></td>
                  <td></td>
                </thead>
                <tbody>
                  {
                    (reqUserMap.length === 0 || reqUserMap === undefined) ?
                      <div class="loader"></div>
                      :
                      reqUserMap?.map((item, index) => {
                        console.log(item, "idd")
                        return (
                          <tr>
                            <td className="td-desktop-only" style={{ textAlign: "center" }}>{index + 1}</td>
                            <td style={{ textAlign: "center", wordBreak: "break-word" }}>{item.seeker}</td>
                            <td style={{ textAlign: "center" }}>{item.requestTime}</td>
                            <td style={{ textAlign: "center" }}>
                              <NavLink to={`/userdashboard`} state={{ seekerId: item.seekerId, seekerName: item.seeker }} exact>
                                <button className="show-desktop">View Projects</button>
                                {/* <button > */}
                                <span style={{ display: "flex", justifyContent: "center" }}>
                                  <img src={EyeIcon} alt="" style={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                    className="show-mobile"
                                  />
                                </span>
                                {/* </button> */}
                              </NavLink>
                            </td>
                            <td style={{
                              margin: "5px"
                            }}
                              className="show-desktop"
                            >
                              <button className="btn-marked" style={{
                                "border": "solid 1px #8443e5",
                                "backgroundColor": "#8443e5"
                              }}>
                                {count?.map((item) => {
                                  return (
                                    item.isMarked ? <span style={{ width: "100%", display: "block", textAlign: "center ", color: "white" }}> Marked as Read</span> : <span style={{ width: "100%", display: "block", textAlign: "center " }} onClick={handleMarked}>Mark as Read</span>
                                  )
                                })}
                              </button>
                            </td>
                            <td className="btn-mobile-marked" style={{
                              margin: "5px",
                              width: "fit-content",
                              /* overflow: hidden; */
                              // "backgroundColor": "#8443e5",
                              // "textAlign": "center",
                              // "padding": ".7rem .8rem",
                              "borderRadius": ".3rem",
                              "cursor": "pointer",
                              // "color": "white"
                            }}>
                              {count?.map((item) => {
                                return (
                                  item.isMarked ? <span style={{
                                    width: "100%", display: "block", textAlign: "center ", color: "white",
                                    backgroundColor: "#8443e5",
                                    borderRadius: ".3rem",
                                    padding: "1px"
                                  }}>
                                    <i class="fa fa-envelope-open" aria-hidden="true"></i><br />
                                    Marked as Read
                                  </span> : <span onClick={handleMarked} style={{
                                    width: "100%", display: "block", textAlign: "center ", color: "white",
                                    backgroundColor: "#8443e5",
                                    borderRadius: ".3rem",
                                    padding: "1px"
                                  }}>
                                    <i class="fa fa-envelope" aria-hidden="true"></i><br />
                                    Mark as Read
                                  </span>
                                )
                              })}
                            </td>
                          </tr>
                        )
                      })
                  }
                </tbody>
              </table>
            )
              : "You currently have no requests"
          }

        </div>
      </div >
    </>
  );
};

export default RequestPage;
