import { BsThreeDotsVertical } from "react-icons/bs";
import DeleteChatModal from "../utils/DeleteChatModal";
import ReportUserModal from "../utils/ReportUserModal";
import noChatsImg from "../../assets/images/nomessages.svg";
import { getSender, getSenderFull } from "../config/ChatLogics";
//import { useHelper } from '../config/helper-hook';
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import server from "../server";

import ChatContext from "../Context/chat-context";

import ScrollableChat1 from "./ScrollableChat1";
import Picker from "emoji-picker-react";
import emojiIcon from "../../assets/icons/emoji.png";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import AuthContext from "../AuthContext";
import BlockUserModal from "../utils/BlockUserModal";
const ENDPOINT = `${server}`; //development
var selectedChatCompare;

const ChatBox1 = ({ fetchAgain, setFetchAgain, handleSideNavbar, ref }) => {
	let [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [newMessage, setNewMessage] = useState("");

	const [amItyping, setAmITyping] = useState(false);

	const [loadingChat, setLoadingChat] = useState(false);
	const [reportModal, setReportModal] = useState(0);
	const [blockModal, setBlockModal] = useState(0);
	const [deleteModal, setDeleteModal] = useState(0);
	const [loggedUser, setLoggedUser] = useState();

	const divRef = useRef(null);

	const scrollToBottom = () => {
		if (divRef.current) {
			const divElement = divRef.current;
			divElement.scrollTop = divElement.scrollHeight;
		}
	};

	const auth = useContext(AuthContext);

	const {
		selectedChat,
		setSelectedChat,
		user,
		chats,
		setChats,
		chatUnReadCount,
		setChatUnReadCount,
		socket,
		socketConnected,
		typing,
		setTyping,
	} = useContext(ChatContext);

	const fetchMessages = async () => {
		if (!selectedChat) return;

		try {
			const config = {
				headers: { Authorization: `Bearer ${user.token}` },
			};

			setLoading(true);

			const { data } = await axios.get(
				`${server}/api/message/${selectedChat._id}`,
				config
			);

			setMessages(data);
			setLoading(false);
			//   console.log(data, "fetched messsages of the selected chat data");

			socket.emit("join chat", selectedChat._id);
		} catch (error) {
			console.log(error.message);
		}
	};

	const sendMessage = async (event) => {
		let chat = selectedChat;
		console.log("chatbox1 75: ", chat, selectedChat);
		chat.unReadBy =
			chat.users[0]._id == user._id ? chat.users[1] : chat.users[0];
		if (newMessage) {
			socket.emit("stop typing", selectedChat._id);

			try {
				const config = {
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				};

				// window.scrollTo(0, document.body.scrollHeight);

				//async func -- wont make newMessage empty instantaneously
				//ui enhancement -- input to be empty as soon as we hit ender/send
				setNewMessage("");

				const { data } = await axios.post(
					`${server}/api/message`,
					{
						content: newMessage,
						chatId: selectedChat,
					},
					config
				);

				//setNewMessage("");
				data.users = selectedChat.users;
				data.chat = selectedChat;
				// console.log("Emit new message data: ", data);
				socket.emit("new_message", chat, data);

				setMessages((messages) => [...messages, data]);
				// console.log(data, "sent message response data");

				if (window.innerWidth < 468) {
					window.scrollTo(0, document.body.scrollHeight);
				}
			} catch (error) {
				console.log(error.message);
			}
		}
	};
	const sendMessageByEnter = (e) => {
		if (e.key === "Enter" && newMessage) {
			sendMessage();
		}
	};

	useEffect(() => {
		fetchMessages();
		//whwnever selctedChat changes, fetchAllMessages again for new selectedChat._id

		//just to keep a track

		selectedChatCompare = selectedChat;

		// eslint-disable-next-line
	}, [selectedChat]);

	//console.log(notification, 'notification Bellicon');

	const updateUnReadCount = (localChats) => {
		if (window.location.pathname == "/chat") {
			if (localChats && localChats.length > 0) {
				localChats.map(async (item) => {
					console.log(item);
					try {
						const config = {
							headers: {
								"Content-type": "application/json",
								Authorization: `Bearer ${user.token}`,
							},
						};
						await axios
							.put(`${server}/api/chat/updateUnreadCount`, { item }, config)
							.then((response) => {
								console.log("The response from ChatBox150:\n", response);
							});
					} catch (error) {
						console.log("The response from topbar65:\n", error.message);
					}
				});
			}
		}
	};

	useEffect(() => {
		socket
			.off("message_recieved")
			.on("message_recieved", (newMessageRecieved) => {
				console.log("New message", newMessageRecieved);
				if (
					!selectedChatCompare ||
					selectedChatCompare._id !== newMessageRecieved.chat._id
				) {
					// if chat is not selected or doesn't match current chat
					newMessageRecieved.chat.unreadCount++;
					let newChats = chats.map((item, i) => {
						if (item._id == newMessageRecieved.chat._id) {
							item.unReadBy = newMessageRecieved.chat.unReadBy;
							item.unreadCount += 1;

							setChatUnReadCount(item.unreadCount);
							updateUnReadCount([item]);
							console.log("Item Changed", item);
						}
						return item;
					});
					console.log(newChats);
					setChats(newChats);
				} else {
					setMessages((messages) => [...messages, newMessageRecieved]);
				}
			});

		socket.off("deleteChat").on("deleteChat", (chat) => {
			// console.log("Deletechat emit",chat)
			if (selectedChat._id == chat._id) {
				fetchMessages();
			}
		});
	});

	useEffect(() => {
		socket.on("typing", (room) => {
			setTyping(true);
		});
		socket.on("stop typing", (room) => setTyping(false));
	}, []);

	useEffect(() => {
		var objDiv = document.querySelector(".msg_Div");
		if (objDiv) {
			objDiv.scrollTop = objDiv.scrollHeight;
		}
	}, [loading, messages]);

	const typingHandler = (e) => {
		setNewMessage(e.target.value);

		setAmITyping(true);
		//typing animation code
		if (!socketConnected) return;

		if (!typing) {
			setTyping(true);
			socket.emit("typing", selectedChat._id);
		}

		//debounce/throttle function
		let lastTypingTime = new Date().getTime();
		var timerLength = 3000;

		setTimeout(() => {
			var timeNow = new Date().getTime();
			var timeDiff = timeNow - lastTypingTime;
			if (timeDiff >= timerLength && typing) {
				socket.emit("stop typing", selectedChat._id);
				setTyping(false);
				setAmITyping(false);
			}
		}, timerLength);
	};
	//   console.log(messages);

	const msgDiv = useRef("");

	useEffect(() => {
		// msgDiv.current.scrollTop = msgDiv.current.scrollHeight;
		// msgDiv.current.lastElementChild.scrollIntoView();
		// console.log(msgDiv.current);
	}, [selectedChat]);

	//   msgDiv.scrollTop = msgDiv.scrollHeight;

	const [dots, setdots] = useState(1);

	function toggleProfileOptions() {
		if (dots === 0) {
			setdots(1);
			document.getElementById("chatOption").style.height = "125px";
			document.getElementById("chatOption").style.display = "none";
		} else {
			setdots(0);
			document.getElementById("chatOption").style.height = "0px";
			document.getElementById("chatOption").style.display = "block";
		}
	}

	const handleDeleteChat = async () => {
		try {
			setLoadingChat(true);
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${user.token}`,
				},
			};
			await axios
				.post(`${server}/api/message/deleteChat`, { selectedChat }, config)
				.then((response) => {
					console.log(response);
					setDeleteModal(0);
					fetchMessages();
					setLoadingChat(false);
					socket.emit("deleteChat", selectedChat, user);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const reportChat = () => {
		setBlockModal(0);
		setDeleteModal(0);
		setReportModal(1);
	};
	const blockChat = () => {
		setReportModal(0);
		setDeleteModal(0);
		setBlockModal(1);
	};

	const deleteChat = () => {
		setReportModal(0);
		setBlockModal(0);
		setDeleteModal(1);
	};

	// console.log("SelectedUser", selectedChat);

	const [showPicker, setShowPicker] = useState(false);

	const onEmojiClick = (event, emojiObject) => {
		setNewMessage((prevInput) => prevInput + emojiObject.emoji);
		setShowPicker(false);
	};

	useEffect(() => {
		setLoggedUser(JSON.parse(localStorage.getItem("login")));
	}, [fetchAgain]);

	const handleReportChat = async (e) => {
		e.preventDefault();
		let txtArea = e.target.querySelector("#reportInputTxt");
		let users = selectedChat.users;
		let anotherUser = users[0]._id == user._id ? users[1] : users[0];

		let reportData = {
			reportee: user._id,
			reported: anotherUser._id,
			message: txtArea.value,
		};

		console.log(reportData);
		try {
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${user.token}`,
				},
			};
			await axios
				.post(
					`${server}/api/chat/reportChat`,
					{ reportData, selectedChat },
					config
				)
				.then((response) => {
					console.log(response);
					setReportModal(0);
					let selectChat = selectedChat;
					selectChat.status = "reported";
					setSelectedChat(selectChat);

					console.log("Emitting chatUpdated");
					socket.emit("chatUpdated", selectedChat, user);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleBlockChat = async () => {
		try {
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${user.token}`,
				},
			};
			await axios
				.post(`${server}/api/chat/blockChat`, { selectedChat }, config)
				.then((response) => {
					console.log(response);
					setBlockModal(0);
					let selectChat = selectedChat;
					selectChat.status = "blocked";
					setSelectedChat(selectChat);
					console.log("Emitting chatUpdated");
					socket.emit("chatUpdated", selectedChat, user);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			{selectedChat ? (
				<div className="chatBox_main  col-lg-9">
					<div className="cb_top top-mobile-chat">
						<div ref={ref}>
							<i
								class="fa fa-arrow-left show-mobile"
								style={{
									color: "white",
								}}
								aria-hidden="true"
								onClick={handleSideNavbar}
							></i>
						</div>
						<div className="d-flex align-items-center justify-content-between w-100 ">
							<div
								className="cb_textDiv"
								style={{ marginLeft: "10px" }}
							>
								<h6> {getSender(user, selectedChat.users)}</h6>
								{!amItyping && typing && <p>typing…</p>}
							</div>

							<div className="dots_div">
								<span onClick={toggleProfileOptions}>
									<BsThreeDotsVertical className="text-light" />
								</span>
								<div id="chatOption">
									<ul>
										{user.type != "user" && (
											<li onClick={deleteChat}>Delete Chat</li>
										)}
										{selectedChat.status != "blocked" && (
											<li onClick={blockChat}>
												Block {getSender(user, selectedChat.users)}
											</li>
										)}
										{selectedChat.status != "reported" && (
											<li onClick={reportChat}>
												Report {getSender(user, selectedChat.users)}
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className=" bottom-chat-mobile p-2 ">
						{loading ? (
							<h5>
								<div class="loader"></div>
							</h5>
						) : (
							<div
								className="msg_Div h-full"
								ref={msgDiv}
								style={{
									flex: 1,
									display: "flex",
									flexDirection: "column",
								}}
							>
								<ScrollableChat1
									messages={messages}
									loggedUser={loggedUser}
									ref={divRef}
								/>
							</div>
						)}

						<div
							className="cb_msgSend"
							onKeyDown={sendMessageByEnter}
							id="first-name"
							mt={3}
						>
							{selectedChat.status == "" || selectedChat.status == null ? (
								<>
									<input
										type="text"
										placeholder="Enter a message.."
										value={newMessage}
										onChange={typingHandler}
									/>

									<MdOutlineEmojiEmotions
										className="emoji-icon mx-2"
										src={emojiIcon}
										style={
											showPicker ? { color: "#8443e5" } : { color: "#9f9ec1" }
										}
										onClick={() => setShowPicker((val) => !val)}
									/>
									<div className="picker-container">
										{showPicker && (
											<Picker
												pickerStyle={{ width: "100%" }}
												onEmojiClick={onEmojiClick}
											/>
										)}
									</div>

									<button onClick={sendMessage}>Send</button>
								</>
							) : (
								<p>This chat has been {selectedChat.status} </p>
							)}
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="chatBox_main  col-lg-9 flex flex-col  items-center justify-center text-gray-500 ">
						<img
							src={noChatsImg}
							alt="No Chats"
							className="h-full "
						/>
						<p>No Messages available, start chatting to view your messages</p>
					</div>
				</>
			)}

			{reportModal == 1 && (
				<ReportUserModal
					handleClose={() => setReportModal(false)}
					handleReport={handleReportChat}
					userToReport={getSender(user, selectedChat.users)}
				/>
			)}
			{blockModal == 1 && (
				<BlockUserModal
					handleClose={() => setBlockModal(false)}
					handleBlock={handleBlockChat}
					userToBlock={getSender(user, selectedChat.users)}
				/>
			)}
			{deleteModal == 1 && (
				<DeleteChatModal
					handleClose={() => setDeleteModal(false)}
					handleDelete={handleDeleteChat}
				/>
			)}
		</>
	);
};

export default ChatBox1;
