import React from "react";

const BlockUserModal = ({ handleClose, handleBlock, userToBlock }) => {
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white rounded-lg shadow-md p-6 max-w-sm w-full">
				<div className="flex justify-between items-center mb-4">
					<h1 className="text-xl font-semibold text-purple-700">Block User</h1>
					<button
						className="text-gray-500 hover:text-gray-700"
						onClick={handleClose}
					>
						&times;
					</button>
				</div>
				<p className="text-gray-700 mb-4">
					Are you sure you want to block {userToBlock}?
				</p>
				<div className="flex justify-end space-x-4">
					<button
						className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
						onClick={handleClose}
					>
						Cancel
					</button>
					<button
						className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
						onClick={handleBlock}
					>
						Block
					</button>
				</div>
			</div>
		</div>
	);
};

export default BlockUserModal;
