import React, { useState } from "react";
import { FaSave, FaEdit, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiDeleteBin7Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noChatsImg from "../../../assets/images/nochats.svg";

const SlotDetails = ({
	slotObj,
	slotDetails,
	setSlotDetails,
	handleNextClick,
	handlePrevClick,
}) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState();
	const [expandedIndex, setExpandedIndex] = useState(null);

	function handleInputChange(e, index) {
		let data = [...slotDetails];
		let changObj = data[index];
		changObj = { ...changObj, [e.target.name]: e.target.value };
		data[index] = changObj;

		setSlotDetails(data);
	}

	function handleCheckboxChange(e, index) {
		let data = [...slotDetails];
		let changObj = data[index];
		let isAvailable = changObj.isAvailable;
		changObj = { ...changObj, isAvailable: !isAvailable };
		data[index] = changObj;

		setSlotDetails(data);
	}

	function removeFields(index) {
		let data = [...slotDetails];

		data.splice(index, 1);
		setSlotDetails(data);
		toast("Slot Deleted successfully", { autoClose: 2000 });
	}

	const handleEditSlot = (e, index) => {
		let slotBox = e.target.closest(".slot-box");
		slotBox.querySelectorAll("input").forEach((inp) => {
			inp.readOnly = false;
		});
		slotBox.querySelector("input").focus();
	};

	function addMore() {
		setSlotDetails([...slotDetails, slotObj]);
		setTimeout(() => {
			let slotBtns = document.getElementsByClassName("slot-button");
			let last = slotBtns[slotBtns.length - 1];
			last.lastChild.click();
		}, 200);
	}

	const handleSaveClick = () => {
		toast("Slot details saved successfully!", { autoClose: 2000 });
		handleNextClick();
	};

	const toggleExpand = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	return (
		<>
			<ToastContainer />
			<div className="container mx-auto px-4 py-8">
				<h4 className="text-2xl text-center font-bold mb-4">Slot Details</h4>
				<div className="slotlist">
					<div className="b_table mt-5">
						{slotDetails?.length > 0 ? (
							<>
								{slotDetails?.map((slot, index) => {
									const isExpanded = expandedIndex === index;
									return (
										<div
											className="slot-main mb-4"
											key={index}
										>
											<div className="slot-box flex flex-col md:flex-row items-center justify-between p-4 bg-white shadow rounded-lg">
												<div className="flex items-center justify-between w-full">
													<h5 className="font-bold">Slot {index + 1}</h5>
													<button
														onClick={() => toggleExpand(index)}
														className="text-gray-500 hover:text-gray-700"
													>
														{isExpanded ? (
															<FaChevronUp size={20} />
														) : (
															<FaChevronDown size={20} />
														)}
													</button>
												</div>
												{isExpanded && (
													<>
														<input
															required
															name="startDate"
															type="date"
															className="slot-input border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
															placeholder="Enter Start Date"
															onChange={(e) => handleInputChange(e, index)}
															value={slot?.startDate}
															readOnly
														/>
														<input
															required
															name="endDate"
															type="date"
															className="slot-input border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0 md:ml-4"
															placeholder="Enter End Date"
															onChange={(e) => handleInputChange(e, index)}
															value={slot?.endDate}
															readOnly
														/>
														<div className="form-check flex items-center mb-4 md:mb-0 md:ml-4">
															<input
																type="checkbox"
																id={`isAvailable${index}`}
																name="isAvailable"
																className="form-check-input"
																checked={slot?.isAvailable}
																onChange={(e) => {
																	!e.target.readOnly &&
																		handleCheckboxChange(e, index);
																}}
																readOnly={true}
															/>
															<label
																className="form-check-label ml-2"
																htmlFor={`isAvailable${index}`}
															>
																Available
															</label>
														</div>
														<div className="slot-button flex space-x-2">
															<button
																onClick={() => {
																	setDeleteIndex(index);
																	setDeleteModal(true);
																}}
																className="text-red-500 hover:text-red-700"
															>
																<RiDeleteBin7Line size={20} />
															</button>
															<button
																onClick={(e) => handleEditSlot(e, index)}
																className="text-purple-500 hover:text-purple-700"
															>
																<FaEdit size={20} />
															</button>
														</div>
													</>
												)}
											</div>
										</div>
									);
								})}
								<div className="slot-button-main flex justify-between mt-4">
									<button
										className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600"
										onClick={() => addMore()}
									>
										Create New Slot
									</button>

									<button
										onClick={handleSaveClick}
										className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600 flex items-center"
									>
										Save
										<FaSave className="ml-2" />
									</button>
								</div>
							</>
						) : (
							<div className="flex flex-col items-center justify-center text-gray-500">
								<img
									src={noChatsImg}
									alt="No Slots"
									className="h-full"
								/>
								<p className="pb-4">No slots available</p>
								<button
									className="bg-purple-500 text-white px-4 py-2 rounded shadow hover:bg-purple-600"
									onClick={() => addMore()}
								>
									Create New Slot
								</button>
							</div>
						)}
					</div>
				</div>
			</div>

			{deleteModal && (
				<div
					id="myModal"
					className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
				>
					<div className="modal-content bg-white p-6 rounded-lg shadow-lg">
						<p className="text-center text-lg mb-4">Delete this Slot?</p>
						<div className="text-center flex justify-center flex-row md:flex-col space-x-4">
							<button
								onClick={(e) => {
									e.preventDefault();
									setDeleteIndex();
									setDeleteModal(false);
								}}
								type="submit"
								className="bg-gray-200 text-gray-700 px-4 py-2 rounded shadow hover:bg-gray-300"
								value="Delete"
							>
								Cancel
							</button>
							<button
								onClick={(e) => {
									e.preventDefault();
									removeFields(deleteIndex);
									setDeleteModal(false);
								}}
								type="submit"
								className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
								value="Delete"
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SlotDetails;
