import React, { useContext, useEffect, useState } from "react";
import { FcSettings } from "react-icons/fc";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import axios from "axios";
import AWS from "aws-sdk";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "./AuthContext";
import "react-toastify/dist/ReactToastify.css";

const Setting = () => {
	const auth = useContext(AuthContext);
	const account = JSON.parse(localStorage.getItem("login"));
	const [imglink, setLink] = useState("");
	const [file, setFile] = useState([null]);

	const useInputs = (initialValue) => {
		const [value, setValue] = useState(initialValue);
		const handleChange = (e) => {
			setValue(e.target.value);
		};
		const changeValue = (v) => {
			setValue(v);
		};
		return {
			value,
			onChange: handleChange,
			onSet: changeValue,
		};
	};

	const fname = useInputs("");
	const lname = useInputs("");
	const email = useInputs("");
	const phone = useInputs("");
	const city = useInputs("");

	AWS.config.update({
		accessKeyId: "AKIAUXONWQ3HERXZEX5M",
		secretAccessKey: "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",
		region: "ap-south-1",
		signatureVersion: "v4",
	});

	const handleInputChange = (e) => {
		setFile(e.target.files);
	};

	const handleSetting = async (link) => {
		const data = await fetch(
			`${server}/auth/ResetLoggedUserData/${account._id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				body: JSON.stringify({ fname, lname, email, phone, link, city }),
			}
		);
		const res = await data.json();
		localStorage.setItem(
			"login",
			JSON.stringify({
				fname: res.profile?.basicInfo?.fname,
				lname: res.profile?.basicInfo?.lname,
				email: res.save.email,
				phone: res.save.phone,
				link: res.save.link,
				city: res.save.city,
				type: res.save.type,
			})
		);
		if (res.status === 201) {
			toast("Account details updated successfully.", {
				autoClose: 2000,
			});
		}
	};

	const handleReset = async () => {
		if (file[0] !== null) {
			const s3 = new AWS.S3();
			const formData = new FormData();
			const urls = [];

			formData.append("image", file[0]);
			const acnt_Img = file[0];
			const params = {
				Bucket: "image-orders-bucket/acnt-img",
				Key: `${Date.now()}.${acnt_Img.name}`,
				Body: acnt_Img,
				ContentEncoding: "base64",
				ContentType: file.type,
			};
			const { Location } = await s3.upload(params).promise();
			urls.push(Location);
			var obj = { link: urls[0] };
			setLink(obj.link);
			toast("Profile Image updated successfully.", {
				autoClose: 2000,
			});

			await handleSetting(obj.link);
		} else {
			handleSetting("");
		}
	};

	const handleForgotPassword = (e) => {
		e.preventDefault();
		axios
			.post(
				`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyBE9xQNI1nx1oPmRgXsiO7e54onMtptBYU&requestType=PASSWORD_RESET&email=${account.email}`
			)
			.then((res) => {
				toast("Password reset link sent to registered email.", {
					autoClose: 2000,
				});
			})
			.catch((err) => {
				alert(err?.response?.data?.error?.message);
			});
	};

	const setInitialAccountSettings = () => {
		fname.onSet(account.fname);
		lname.onSet(account.lname);
		email.onSet(account.email);
		phone.onSet(account.phone);
		city.onSet(account.city);
	};

	useEffect(() => {
		setInitialAccountSettings();
		setLink(account.link);
		auth.setActive("setting");
	}, []);

	return (
		<>
			<Topbar />
			<ToastContainer />
			<div className="notification p-5">
				<div className="p-4 shadow bg-white rounded-lg">
					<div className="flex items-center">
						<h1 className="text-purple-600 text-2xl font-bold flex items-center">
							Account Settings <FcSettings className="ml-2" />
						</h1>
					</div>
					<hr className="my-4" />
					<div className="account-main-div">
						<div className="flex flex-col md:flex-row justify-between items-center md:space-x-4">
							<div className="w-full md:w-1/3 mb-4 md:mb-0">
								<label
									htmlFor="formFile"
									className="form-label block text-gray-700"
								>
									Upload Image
								</label>
								<input
									className="form-control w-full h-full"
									type="file"
									accept="image/*"
									onChange={handleInputChange}
									id="formFile"
								/>
							</div>
							<input
								type="text"
								placeholder="First Name"
								className="setting-textfield border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
								value={fname.value}
								onChange={fname.onChange}
							/>
							<input
								type="text"
								placeholder="Last Name"
								className="setting-textfield border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
								value={lname.value}
								onChange={lname.onChange}
							/>
						</div>
						<div className="flex flex-col md:flex-row justify-between items-center md:space-x-4">
							<input
								type="email"
								placeholder="Email Address"
								className="setting-textfield border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
								value={email.value}
								onChange={email.onChange}
							/>
							<input
								type="text"
								placeholder="Phone Number"
								className="setting-textfield border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
								value={phone.value}
								onChange={phone.onChange}
							/>
							<input
								type="text"
								placeholder="City"
								className="setting-textfield border-b border-gray-300 focus:border-purple-500 focus:outline-none w-full md:w-1/3 py-2 px-3 mb-4 md:mb-0"
								value={city.value}
								onChange={city.onChange}
							/>
						</div>
						<div className="flex flex-col md:flex-row justify-start space-y-2 md:space-y-0 md:space-x-4 my-3">
							<div className="flex flex-row justify-between py-4">
								<button
									onClick={handleReset}
									className="bg-purple-600 text-white px-4 py-2 rounded shadow hover:bg-purple-700"
								>
									Save
								</button>
								<button
									onClick={setInitialAccountSettings}
									className="bg-gray-200 text-gray-700 px-4 py-2 rounded shadow hover:bg-gray-300"
								>
									Cancel
								</button>
							</div>
							<button
								onClick={handleForgotPassword}
								className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
							>
								Reset Password
							</button>
						</div>
						<hr className="my-4" />
					</div>
				</div>
			</div>
		</>
	);
};

export default Setting;
