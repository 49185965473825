import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { FaEdit } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import LeftArrow from "../../../assets/icons/left-arrow.svg";

// import LoadingIframe from 'react-loading-iframe'
import "../forms.css";

import parse from "html-react-parser";
import ChannelCard from "../../mini_components/ChannelCard";
const apiKey = "AIzaSyDOgIYh0BlWtWwwSjSp-dkogEBqVSSz3jI";

const ChannelStats = ({
	channelDetails,
	setChannelDetails,
	handleNextClick,
	handlePrevClick,
	EnterinDatabase,
}) => {
	const [isDataAvailable, setIsDataAvailable] = useState(false);
	const dataFetch = useRef(0);
	const [availableChannels, setAvailableChannels] = useState([]);
	const [channelConfirmModal, setChannelConfirmModal] = useState(false);
	const [channelSelected, setChannelSelected] = useState();
	const [channelNotFound, setChannelNotFound] = useState(false);

	const [videoLoader, setVideoLoader] = useState(0);

	let sampleChannelDetails = {
		channelId: "UCBqFKDipsnzvJdt6UT0lMIg",
		channelLink: "https://www.youtube.com/@SandeepSeminars",
		channelName: "Sandeep Maheshwari",
		hiddenSubscriberCount: false,
		subscriberCount: "27300000",
		videoCount: "574",
		viewCount: "2245403270",
		averageCommentsCount: "9377.42",
		averageLikesCount: "271469.5",
		topicCategories: [],
	};

	const getChannelInfo = async (channelId) => {
		console.log("ChannelStats 27 :", channelId);
		let statistics;
		axios
			.get(
				`https://www.googleapis.com/youtube/v3/channels?part=statistics,snippet,topicDetails&id=${channelId}&key=${apiKey}`
			)
			.then((response) => {
				console.log(response.data);
				const channelStatistics = response.data.items[0].statistics;
				const snippet = response.data.items[0].snippet;
				let topicCategories =
					response.data.items[0].topicDetails.topicCategories;

				topicCategories = JSON.parse(
					JSON.stringify(topicCategories).replaceAll(
						"https://en.wikipedia.org/wiki/",
						""
					)
				);
				// To get Country full name from abbrevation
				const regionNamesInEnglish = new Intl.DisplayNames(["en"], {
					type: "region",
				});
				let countryAndThumbnail = {
					thumbnail: snippet.thumbnails.medium.url,
					country: regionNamesInEnglish.of(snippet.country),
				};

				statistics = {
					...channelStatistics,
					topicCategories: topicCategories,
					...countryAndThumbnail,
				};

				console.log(statistics);
			})
			.catch((err) => {
				console.error(err);
			});

		// Get avg likes and comments for YT channel
		axios
			.get(
				`https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&order=date&maxResults=50&type=video&key=${apiKey}`
			)

			.then((response) => {
				const videoIds = response.data.items
					.map((item) => item.id.videoId)
					.join(",");

				return axios.get(
					`https://www.googleapis.com/youtube/v3/videos?part=statistics,player&id=${videoIds}&key=${apiKey}`
				);
			})

			.then((response) => {
				const totalCommentsCount = response.data.items.reduce((acc, item) => {
					return acc + parseInt(item.statistics.commentCount);
				}, 0);
				const totalLikesCount = response.data.items.reduce((acc, item) => {
					return acc + parseInt(item.statistics.likeCount);
				}, 0);

				let latestVideos = [];
				let videosCount = 10;

				for (let index = 0; index < videosCount; index++) {
					let parsed = parse(response.data.items[index].player.embedHtml);
					latestVideos.push(parsed.props.src);
				}
				console.log("ChannelStats 100 Latest 10 videos\n", latestVideos);

				const videoCount = response.data.items.length;
				const averageCommentsCount = totalCommentsCount / videoCount;
				const averageLikesCount = totalLikesCount / videoCount;
				statistics.latestVideos = latestVideos;
				console.log(`Channel: ${channelId}`);
				console.log(`Total Videos: ${videoCount}`);
				console.log(`Total Comments: ${totalCommentsCount}`);
				console.log(
					`Average Comments per Video: ${averageCommentsCount.toFixed(2)}`
				);
				console.log(`Total Likes: ${totalLikesCount}`);
				console.log(`Average Likes per Video: ${averageLikesCount.toFixed(2)}`);

				dataFetch.current = 0;

				setChannelDetails({
					...channelDetails,
					averageLikesCount: String(averageLikesCount),
					averageCommentsCount: String(averageCommentsCount),
					...statistics,
					channelId: channelId,
				});
			});
	};

	const handleYTSubmit = async (e, type = "q", parameter = "channelName") => {
		e.preventDefault();

		const query = channelDetails[`${parameter}`];
		console.log("ChannelStats 120", query);
		console.log("ChannelStats 121", type);
		if (query == "") {
			return;
		}
		axios
			.get(
				`https://www.googleapis.com/youtube/v3/search?part=snippet&type=channel&${type}=${query}&maxResults=50&key=${apiKey}`
			)
			.then((response) => {
				setAvailableChannels(response.data.items);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	function handleInputChange(e) {
		setChannelDetails({ ...channelDetails, [e.target.name]: e.target.value });
	}
	useEffect(() => {
		if (dataFetch.current < 1) {
			if (
				channelDetails.averageCommentsCount != "" &&
				channelDetails.averageCommentsCount != ""
			) {
				setChannelConfirmModal(false);
				setIsDataAvailable(true);
				console.log(channelDetails);
				EnterinDatabase();
				setChannelSelected();
				setAvailableChannels([]);
				dataFetch.current++;
			}
		}
	}, [channelDetails]);

	function handleMyChannelClick(channel) {
		console.log("finished");
		let channelId = channel.snippet.channelId;
		getChannelInfo(channelId);
	}

	function handleChannelNotFound() {
		setChannelNotFound(true);
	}

	function handleChannelIdSubmit(e) {
		handleYTSubmit(e, "channelId", "channelId");
		setChannelNotFound(false);
	}

	function handleIframeLoad() {
		setVideoLoader((prev) => prev + 1);
	}

	return (
		<>
			{!isDataAvailable && (
				<div
					className="yt-info-form"
					style={{ margin: "5rem auto", width: "fit-content" }}
				>
					<div
						className="form-body"
						style={{ height: "fit-content" }}
					>
						<div className="form-container">
							<form onSubmit={handleYTSubmit}>
								<p className="edit-youtube-heading">Youtube Channel Details </p>
								<div>
									<input
										required
										name="channelName"
										className="form-control"
										type="text"
										placeholder="Enter your Youtube channel's Name"
										onChange={handleInputChange}
										value={channelDetails.channelName}
									/>
									<input
										required
										name="channelLink"
										className="form-control"
										type="text"
										placeholder="Enter your Youtube channel's link"
										onChange={handleInputChange}
										value={channelDetails.channelLink}
									/>
									<div
										style={{ marginTop: "30px" }}
										className="button-next-div"
									>
										<button
											type="submit"
											className="button-next"
										>
											Next
											<img
												style={{ marginLeft: "10px" }}
												src={LeftArrow}
												alt="img"
											/>
										</button>
									</div>
									{/* <div className="d-flex justify-content-center">
                    <input type="submit" className="button-next" value="Save" />
                  </div> */}
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
			{isDataAvailable && (
				<>
					{/* <span>{JSON.stringify(channelDetails)}</span> */}

					<div className="channelContainer new-style-channel-container ">
						<IconContext.Provider
							value={{ color: "#8443e5", className: "editIcon cursor-pointer" }}
						>
							<FaEdit
								title="edit"
								onClick={() => setIsDataAvailable(false)}
							/>
						</IconContext.Provider>
						{/* <h4>Channel Details</h4> */}
						{/* <div className="flexCont">

              <div className="part">
                <img src={channelDetails.thumbnail} className="channelImg" />
              </div>

              <div className="part">
                <span className="channelTitle">
                  {channelDetails.channelName} &nbsp;
                </span>
                <span className="country"> {channelDetails.country} </span>

                <span className="channelTopics">
                  {" "}
                  {channelDetails.topicCategories?.map((topic, index) => {
                    return <span key={index}>{topic} |</span>;
                  })}{" "}
                </span>
                <div className="channelStats">


                  <span className="">
                    {" "}
                    <FaUsers /> Subscribers{" "}
                    {formatNumber(channelDetails.subscriberCount)}{" "}
                  </span>
                  <span className="">
                    {" "}
                    <FaVideo /> Videos {formatNumber(channelDetails.videoCount)}{" "}
                  </span>
                  <span className="">
                    {" "}
                    <FaEye /> Views{" "}
                    {formatNumber(channelDetails?.viewCount)}{" "}
                  </span>
                  <span className="likes">
                    {" "}
                    <AiOutlineLike /> Likes{" "}
                    {formatNumber(channelDetails?.averageLikesCount)}{" "}
                  </span>
                  <span className="">
                    {" "}
                    <AiOutlineComment /> Comments{" "}
                    {formatNumber(channelDetails?.averageCommentsCount)}{" "}
                  </span>
                </div>
              </div>
            </div> */}
						<ChannelCard channel={channelDetails} />
						<div
							className="latestVideos new-style-latestVideos"
							style={{ position: "relative" }}
						>
							{channelDetails.latestVideos.map((video, indx) => {
								return (
									<div
										className="videoCont"
										key={indx}
									>
										<iframe
											src={video}
											width="230"
											height="120"
											onLoad={handleIframeLoad}
										></iframe>
									</div>
								);
							})}
							{videoLoader < 8 && (
								<div
									className="loader-box"
									style={{
										position: "absolute",
										width: "100%",
										height: "275px",
										top: "0",
										left: "0",
										display: "flex",
										background: "#d7d7d7",
									}}
								>
									<div className="loader"></div>
								</div>
							)}
						</div>
						{/* <div className="button-next-div">
              <button className="button-next" onClick={handlePrevClick}>
                <img style={{ marginRight: "10px", rotate: "180deg" }} src={LeftArrow} alt="img" />
                Previous
              </button>
              <button className="button-next" onClick={handleNextClick}>
                Next
                <img style={{ marginLeft: "10px" }} src={LeftArrow} alt="img" />
              </button>
            </div> */}
					</div>
				</>
			)}
			<div
				className="show-desktop"
				style={{ width: "90vw" }}
			>
				<div className="searchList">
					{availableChannels.length > 0 && (
						<div>
							<p className="verify-text">Verify your Youtube Channel</p>
							<ul
								style={{ listStyleType: "none" }}
								className="list"
							>
								{availableChannels?.map((channel, index) => {
									return (
										<div className="channel-verify-div">
											<li
												className="list-item"
												key={index}
											>
												<div className="channelItem">
													<img
														src={channel.snippet?.thumbnails?.default.url}
														style={{
															width: "100px",
															height: "100px",
															borderRadius: "5px",
															marginRight: "10px",
														}}
														alt=""
													/>
													<div>
														<p className="channel-name-search">
															{channel.snippet?.title}
														</p>
														<p className="sub-count-search">
															14.1M subscribers{" "}
															{channel.statistics?.subscriberCount}
														</p>
														<p className="date-search">
															{" "}
															Published Date: {channel.snippet?.publishTime}
														</p>
													</div>
												</div>
												<div>
													<p className="desc-search">
														{" "}
														Description: {channel.snippet?.description}
													</p>
												</div>
												<div className="actionButtons">
													<button
														onClick={() => {
															setChannelSelected(channel);
															setChannelConfirmModal(true);
														}}
														className="actionButtons-button"
													>
														This is My Channel
													</button>
												</div>
											</li>
										</div>
									);
								})}
								<li>
									<div>
										Your channel not in the list? Click{" "}
										<button
											onClick={handleChannelNotFound}
											style={{
												outline: "none",
												backgroundColor: "transparent",
												border: "none",
												color: "blue",
											}}
										>
											Here
										</button>
									</div>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>

			<div className="show-mobile">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "wrap",
					}}
				>
					{availableChannels.length > 0 && (
						<div className="width-100-mobile">
							<p className="verify-text">Verify your Youtube Channel</p>
							{/* <ul style={{ listStyleType: "none" }} className="list"> */}
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									flexWrap: "wrap",
								}}
							>
								{availableChannels?.map((channel, index) => {
									return (
										<div
											className="channel-verify-div margin-mobile"
											style={{ width: "100%", padding: "5px 5px" }}
										>
											<div
												className="list-item"
												key={index}
											>
												<div className="channelItem">
													<img
														src={channel.snippet?.thumbnails?.default.url}
														style={{
															width: "100px",
															height: "100px",
															borderRadius: "5px",
															marginRight: "10px",
														}}
														alt=""
													/>
													<div>
														<p className="channel-name-search">
															{channel.snippet?.title}
														</p>
														<p className="sub-count-search">
															14.1M subscribers{" "}
															{channel.statistics?.subscriberCount}
														</p>
														<p className="date-search">
															{" "}
															Published Date: {channel.snippet?.publishTime}
														</p>
													</div>
												</div>
												<div>
													<p className="desc-search">
														{" "}
														Description: {channel.snippet?.description}
													</p>
												</div>
												<div className="actionButtons">
													<button
														onClick={() => {
															setChannelSelected(channel);
															setChannelConfirmModal(true);
														}}
														className="actionButtons-button"
													>
														This is My Channel
													</button>
												</div>
											</div>
										</div>
									);
								})}
							</div>
							<div>
								<div>
									Your channel not in the list? Click{" "}
									<button
										onClick={handleChannelNotFound}
										style={{
											outline: "none",
											backgroundColor: "transparent",
											border: "none",
											color: "blue",
										}}
									>
										Here
									</button>
								</div>
							</div>
							{/* </ul> */}
						</div>
					)}
				</div>
			</div>

			<div
				className="channelSelectConfirmModal"
				style={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					zIndex: "5",

					color: "#fff",
				}}
			>
				{channelConfirmModal && (
					<>
						<div className="card-search-confirm">
							<p className="confirm-heading">Please Cofirm Your Channel</p>
							<div className="channelItem">
								<div className="confirm-search">
									<div className="channelItem">
										<img
											src={channelSelected?.snippet?.thumbnails?.default.url}
											style={{
												width: "100px",
												height: "100px",
												borderRadius: "5px",
												marginRight: "10px",
											}}
											alt=""
										/>
										<div>
											<p className="channel-name-search">
												{channelSelected?.snippet?.title}
											</p>
											<p className="sub-count-search">
												14.1M subscribers{" "}
												{channelSelected?.statistics?.subscriberCount}
											</p>
											<p className="date-search">
												{" "}
												Published Date: {channelSelected?.snippet?.publishTime}
											</p>
										</div>
									</div>
									<div>
										<p className="desc-search">
											{" "}
											Description: {channelSelected?.snippet?.description}
										</p>
									</div>
									{/* <img
                  src={channelSelected?.snippet?.thumbnails?.default.url}
                  style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                  alt=""
                />
                Title: <span>{channelSelected?.snippet?.title}</span>
                <br />
                Description
                <p style={{ maxWidth: "50%", overflow: "ellipsis" }}>
                  {channelSelected?.snippet?.description}
                </p>
                <br />
                Published At{" "}
                <span> {channelSelected?.snippet?.publishTime} </span> */}
								</div>
							</div>
							<div className="confirm-buttons">
								<button
									className="button-cancel"
									onClick={() => {
										setChannelConfirmModal(false);
										setChannelSelected();
									}}
								>
									Cancel
								</button>
								<button
									className="button-confirm"
									onClick={() => {
										handleMyChannelClick(channelSelected);
									}}
								>
									{" "}
									Confirm
								</button>
							</div>
						</div>
					</>
				)}
			</div>

			<div
				className="channelNotFoundModal"
				style={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					zIndex: "5",
					backgroundColor: "gray",
					color: "#fff",
				}}
			>
				{channelNotFound && (
					<>
						<input
							type="text"
							name="channelId"
							placeholder="Enter the Channel ID"
							value={channelDetails.channelId}
							onChange={handleInputChange}
						/>
						<button onClick={handleChannelIdSubmit}>Submit</button>
						<h5>Follow this steps to get your channel's channel ID</h5>

						<div class="cc">
							<h4>Find your channel's user ID &amp; channel ID</h4>

							<p>
								You can see your channel's user and channel IDs in your
								<a
									href="http://www.youtube.com/account_advanced"
									target="_blank"
									rel="noopener"
								>
									&nbsp; advanced account settings
								</a>
								&nbsp;on a computer or mobile browser.
							</p>

							<ol>
								<li>
									Sign in to&nbsp;
									<a
										href="https://www.youtube.com/"
										rel="noopener"
										target="_blank"
									>
										YouTube
									</a>
									.
								</li>
								<li
									data-outlined="false"
									class=""
								>
									In the top right, click your profile picture&nbsp; &gt; &nbsp;
									<strong>Settings</strong> .
								</li>
								<li>
									From the left menu, select &nbsp;
									<strong>Advanced settings</strong>.
								</li>
								<li
									data-outlined="false"
									class=""
								>
									You’ll see your channel’s user and channel IDs.
								</li>
							</ol>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ChannelStats;
