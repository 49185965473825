import React from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import "../forms.css";
import { useState } from "react";
import { useEffect } from "react";
import server from "../../server";
import { Country, State, City } from "country-state-city";

const ProfileDetailsForm = ({ display, toggle, profileData, userData }) => {
   const [bool, setbool] = useState(true);

   const user = JSON.parse(localStorage.getItem("login"));
   let show = {};
   if (display) {
      show = { display: "block" };
   } else {
      show = { display: "none" };
   }


   // let today = new Date()
   // let initDob = String(today.getFullYear()) + "-" + (today.getMonth()+1)<10? "0" +String(today.getMonth()) : String(today.getMonth())
   // console.log("initDOb: ",initDob)
   const [profileDetails, setProfileDetails] = useState({
      fname: "",
      lname: "",
      gender: "",
      email: "",
      password: "",
      DOB: "2023-04",
      city: "",
      state: "",
      country: "",
      address: "",
      linkedin: "",
      facebook: "",
      instagram: "",
      userId: "1",
   });

   const [countries, setCountries] = useState(Country.getAllCountries);
   const [selectedCountry, setSelectedCountry] = useState();

   const [countryStates, setCountryStates] = useState([]);
   const [selectedState, setSelectedState] = useState();

   const [cities, setCities] = useState([]);

   const [invalidLink, setInvalidLink] = useState("")
   const handleInputChange = (e) => {
      if (e.target.name == "country") {
         setProfileDetails({ ...profileDetails, ["country"]: e.target.value });
         setSelectedCountry(e.target.value);
      } else if (e.target.name == "state") {
         setProfileDetails({ ...profileDetails, ["state"]: e.target.value });
         setSelectedState(e.target.value);
      } else {
         setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
      }
   };

   function checkValidLink(link,type){
      if(type == "instagram"){
         var pattern = /^https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("instagram")
         return isValid;
      }
      else if(type == "facebook"){
         var pattern = /^https?:\/\/(www\.)?facebook\.com\/([a-zA-Z0-9\.]+)\/?$/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("facebook")
         return isValid;
      }
      else if(type == "linkedIn"){
         var pattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/([a-zA-Z0-9_-]+)/;
         let isValid = ((link != undefined || link != '') &&  pattern.test(link))
         !isValid && setInvalidLink("linkedIn")
         return isValid;
         
      }
      return true;
   }

   const handleSubmit = async (e) => {
      e.preventDefault();

      const {
         fname,
         lname,
         gender,
         email,
         password,
         DOB,
         city,
         state,
         country,
         address,
         linkedin,
         facebook,
         instagram,
         userId,
      } = profileDetails;
      // alert(`${linkedin +" "+ instagram + " " + facebook}`)

      if((linkedin != "" && linkedin != undefined) && !checkValidLink(linkedin,"linkedIn")){
         return;
      }
      else if((instagram != "" && instagram != undefined) && !checkValidLink(instagram,"instagram")){
         return;
      }
      else if((facebook != "" && facebook != undefined) && !checkValidLink(facebook,"facebook")){
         return;
      }

      if (user.type === "admin") {
         const res = await fetch(`${server}/profile/basicinfoAdmin/${userData._id}`, {
            method: "PUT",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               fname,
               lname,
               gender,
               email,
               password,
               DOB,
               city,
               state,
               country,
               address,
               linkedin,
               facebook,
               instagram,
               userId,
            }),
         });
         const ok = await res.json();
         if (ok) {
            toast("Profile details updated successfully", {
               autoClose: 2000,
            });
            toggle("talent");
         }
      } else {
         if (bool) {
            axios
            .post(`${server}/profile/`, { basicInfo: profileDetails }, {
               headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
               },
            })
            .then((res) => {
               toast("Profile details saved successfully", {
                  autoClose: 2000,
               });
               toggle("talent");
            })
            .catch((err) => {
               toast(err.message, {
                  autoClose: 2000,
               });
            })
         } else {
            const res = await fetch(`${server}/profile/basicinfo`, {
               method: "PUT",
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
               },
               body: JSON.stringify({
                  fname,
                  lname,
                  gender,
                  email,
                  password,
                  DOB,
                  city,
                  state,
                  country,
                  address,
                  linkedin,
                  facebook,
                  instagram,
                  userId,
               }),
            });
            const ok = await res.json();
            if (ok) {
               localStorage.setItem("login", JSON.stringify({fname : fname , lname : lname , 
                  email : ok.user.email , phone : ok.user.phone , link : ok.user.link , city : ok.user.city , type : ok.user.type }))
               console.log(ok)
               toast("Profile details updated successfully", {
                  autoClose: 2000,
               });
               toggle("talent");
            }
         }
      }
   };

   const handleShow = async () => {
      axios
         .get(`${server}/profile/`, {
            headers: {
               Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
         })
         .then((response) => {
            if (response.data !== null) {
               setbool(false);
               if (response.data.basicInfo) {
                  setProfileDetails(response.data.basicInfo);
               }
            }
         })
         .catch((err) => {
            console.log(err.response);
         });
   };

   useEffect(() => {
      if (user.type === "user") {
         handleShow();
      } else {
         setProfileDetails(userData?.basicInfo);
      }
   }, [setProfileDetails]);

   useEffect(() => {
      // console.log(profileDetails)
   }, [profileDetails]);

   useEffect(() => {
      let countrycode = profileDetails?.country?.split("-")[0];
      setCountryStates(State.getStatesOfCountry(countrycode));
   }, [profileDetails?.country]);
   useEffect(() => {
      let countrycode = profileDetails?.country?.split("-")[0];
      let statecode = profileDetails?.state?.split("-")[0];
      setCities(City.getCitiesOfState(countrycode, statecode));
   }, [profileDetails?.state]);

   return (
      <>
         {
            <div className="form-body" style={show}>
               <div className="form-container">
                  <div className="form-head">Portfolio Details</div>
                  <div className="form-desc">Let us know about you to suggest the best for you.</div>
                  <form onSubmit={handleSubmit}>
                     <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="fname"
                        value={profileDetails.fname}
                        required
                        onChange={handleInputChange}
                     />
                     <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lname"
                        value={profileDetails.lname}
                        required
                        onChange={handleInputChange}
                     />
                     <div style={{ position: "relative", display: "flex" }}>
                        <select
                           name="gender"
                           onChange={handleInputChange}
                           value={profileDetails.gender}
                           className="form-control form-select"
                           required
                        >
                           <option value="" disabled selected>
                              Gender
                           </option>
                           <option>Male</option>
                           <option>Female</option>
                           <option>Transgender</option>
                        </select>
                     </div>
                     <input
                        type="month"
                        className="form-control empty"
                        placeholder="Date of birth"
                        name="DOB"
                        value={profileDetails.DOB}
                        onChange={handleInputChange}
                        required
                     />

                     <input
                        type="text"
                        className="form-control"
                        placeholder="Street Address"
                        name="address"
                        value={profileDetails.address}
                        onChange={handleInputChange}
                        required
                     />
                     <select
                        name="country"
                        onChange={handleInputChange}
                        value={profileDetails.country}
                        className="form-control form-select"
                        required
                     >
                        <option value="" disabled selected>
                           Country
                        </option>
                        {/* <option>India</option>
                <option>USA</option> */}
                        {countries?.map((country, index) => {
                           return (
                              <option index={index} value={country.isoCode + "-" + country.name}>
                                 {" "}
                                 {country.isoCode + "-" + country.name}{" "}
                              </option>
                           );
                        })}
                     </select>
                     <div style={{ position: "relative", display: "flex" }}>
                        <select
                           name="state"
                           onChange={handleInputChange}
                           value={profileDetails.state}
                           className="form-control form-select"
                           required
                        >
                           <option value="" disabled selected>
                              State
                           </option>
                           {/* <option>Maharashtra</option> */}
                           {countryStates.map((state, index) => {
                              return (
                                 <option value={state.isoCode + "-" + state.name}>
                                    {" "}
                                    {state.isoCode + "-" + state.name}{" "}
                                 </option>
                              );
                           })}
                        </select>
                        <p className="mx-2"></p>
                        <select
                           name="city"
                           onChange={handleInputChange}
                           value={profileDetails.city}
                           className="form-control form-select"
                        >
                           <option value="" disabled selected>
                              City
                           </option>
                           {/* <option>Pune</option>
                  <option>Mumbai</option> */}
                           {cities?.map((city, index) => {
                              return <option>{city.name}</option>;
                           })}
                        </select>
                     </div>
                     <label className="form-label">LinkedIn</label>
                     <input
                        type="text"
                        className="form-control"
                        placeholder="Linkedin"
                        name="linkedin"
                        value={profileDetails.linkedin}
                        onChange={(e)=>{setInvalidLink(""); handleInputChange(e)}}
                        style={{borderColor:`${invalidLink=="linkedIn"?"red":""}`}}
                     />
                     <p className="text-danger"> { invalidLink == "linkedIn" && "Please enter valid LinkedIn URL" } </p>
                     <label className="form-label">Facebook  </label>
                     <input
                        type="text"
                        className="form-control"
                        placeholder="Facebook"
                        name="facebook"
                        value={profileDetails.facebook}
                        onChange={(e)=>{setInvalidLink(""); handleInputChange(e)}}
                        style={{borderColor:`${invalidLink=="facebook"?"red":""}`}}
                     />
                     <p className="text-danger"> { invalidLink == "facebook" && "Please enter valid FaceBook URL" } </p>
                     <label className="form-label">Instagram  </label>
                     <input
                        type="text"
                        className="form-control"
                        placeholder="Instagram"
                        name="instagram"
                        value={profileDetails.instagram}
                        onChange={(e)=>{setInvalidLink(""); handleInputChange(e)}}
                        style={{borderColor:`${invalidLink=="instagram"?"red":""}`}}
                     />
                     <p className="text-danger"> { invalidLink == "instagram" && "Please enter valid Instagram URL" } </p>
                     <div className="buttons justify-content-between mt-5">
                        <input type="button" className="col-4 cancel-btn btn btn-lg btn-block my-2" value="Cancel" />
                        <p className="col-1"></p>
                        <input type="submit" className="col-7 save-btn btn btn-lg btn-block my-2" value="Save" />
                     </div>
                  </form>
               </div>
            </div>
         }
         <ToastContainer />
      </>
   );
};

export default ProfileDetailsForm;
