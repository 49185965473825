import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import axios from "axios";
import server from "./server";
import AuthContext from "./AuthContext";
import profile from "../assets/icons/profile1.svg";
import noMessages from "../assets/images/nomessages.svg";
import Topbar from "./mini_components/Topbar";

const TalentNotification = () => {
	const [jobsTalent, setJobsTalent] = useState([]);
	const [jobRolesTalent, setJobRolesTalent] = useState([]);
	const [rolesNotification, setRolesNotification] = useState([]);
	const [views, setViews] = useState([]);
	const [viewUsers, setViewUsers] = useState([]);
	const [viewsNotification, setViewNotification] = useState([]);
	const [loggedUser, setLoggedUser] = useState("");

	const auth = useContext(AuthContext);
	const dataFetchedRefTalent = useRef(false);
	const user = JSON.parse(localStorage.getItem("login"));

	const getJobApplicationsTalent = async () => {
		const res = await fetch(`${server}/application/allJobsUser`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});

		const response = await res.json();
		setJobsTalent(response);
		getJobRolesTalent(response);
	};

	const getUsersTalent = (views) => {
		views?.forEach((view) => {
			axios
				.get(`${server}/project/UserId/${view.seekerId}`)
				.then((res) => {
					if (res !== null) {
						setViewUsers((oldUsers) => [...oldUsers, res.data]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	const getJobRolesTalent = (jobsTalent) => {
		jobsTalent?.forEach((job) => {
			axios
				.get(`${server}/project/getCharacter/${job.roleId}`)
				.then((res) => {
					setJobRolesTalent((oldRoles) => [...oldRoles, res.data]);
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	const getReqToApp = () => {
		axios
			.get(`${server}/profile/reqToApp/${user._id}`)
			.then((res) => {
				setViews(res.data);
				getUsersTalent(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (dataFetchedRefTalent.current) return;
		dataFetchedRefTalent.current = true;
		getJobApplicationsTalent();
		getReqToApp();
		auth.setActive("notification");
	}, []);

	useEffect(() => {
		const roles = new Set();
		jobRolesTalent?.forEach((job) => {
			job?.forEach((item) => {
				roles.add(`${item.roles[0].role} in ${item.basicInfo.name}`);
			});
		});
		const rolesArr = [...roles];
		rolesArr.reverse();
		setRolesNotification(rolesArr);
	}, [jobRolesTalent]);

	useEffect(() => {
		const roles = new Set();
		viewUsers?.forEach((view) => {
			roles.add({ notification: view.username, img: view.link });
		});
		const viewsArr = [...roles];
		viewsArr.reverse();
		setViewNotification(viewsArr);
	}, [viewUsers]);

	useEffect(() => {
		setLoggedUser(JSON.parse(localStorage.getItem("login")));
	}, []);

	return (
		<>
			<Topbar />
			<div className="flex flex-col items-center p-2  min-h-screen">
				<div className="w-full h-screen max-w-4xl  rounded-lg p-4">
					<div className="flex justify-between items-center mb-4">
						<h1 className="text-2xl font-bold text-purple-600 flex items-center">
							Notifications <IoMdNotifications className="ml-2" />
						</h1>
					</div>
					<hr />
					{rolesNotification.length > 0 || viewsNotification.length > 0 ? (
						<>
							{rolesNotification?.map((roleName, index) => (
								<div
									key={index}
									className="py-2"
								>
									<div className="flex items-center space-x-4">
										<img
											src={loggedUser.link || profile}
											alt="pfp"
											className="w-10 h-10 rounded-full shadow-sm"
										/>
										<p>
											You have successfully applied for the role{" "}
											<span className="font-semibold">{roleName}</span>
										</p>
									</div>
									<hr className="my-2" />
								</div>
							))}
							{viewsNotification?.map((item, index) => (
								<div
									key={index}
									className="py-2"
								>
									<div className="flex items-center space-x-4">
										<img
											src={item.img || profile}
											alt="pfp"
											className="w-10 h-10 rounded-full shadow-sm"
										/>
										<p>{item.notification} has viewed your profile</p>
									</div>
									<hr className="my-2" />
								</div>
							))}
						</>
					) : (
						<div className="flex flex-col items-center justify-center text-gray-500 ">
							<img
								src={noMessages}
								alt="No Notifications"
								className="max-h-72"
							/>
							<p className="mt-4">You do not have any notifications</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default TalentNotification;
