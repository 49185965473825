import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsTelephone } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { RiCloseCircleLine, RiMailSendLine } from "react-icons/ri";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import User from "../assets/icons/user.svg";
import Email from "../assets/icons/email.svg";
import UpArrow from "../assets/icons/up-arrow.svg";
import Phone from "../assets/icons/phone.svg";
import EmptyDashboard from "../assets/images/emptydash.png";

const InfluencerDashboard = () => {
	const [slotReqs, setSlotReqs] = useState([]);
	const [contactInfo, setContactInfo] = useState();
	const [loading, setLoading] = useState(true);

	async function getSlotRequests() {
		await axios
			.get(`${server}/influencer/getYoutubeReqs`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			})
			.then((response) => {
				setSlotReqs(response.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		getSlotRequests();
	}, []);

	const formatDate = (date) => {
		let dat = new Date(date);
		if (dat != "Invalid Date") {
			return (
				dat
					.toLocaleDateString("en-GB", {
						day: "numeric",
						month: "short",
						year: "numeric",
					})
					.replace(/ /g, "-") || "NA"
			);
		} else {
			return "NA";
		}
	};

	return (
		<>
			<Topbar />
			{loading ? (
				<div className="flex items-center justify-center h-screen">
					<div className="loader"></div>
				</div>
			) : (
				<div className="requestPage d-flex justify-content-center h-screen">
					<div className="b_table mt-5 scroll-container">
						{slotReqs.length > 0 ? (
							<table className="w-full text-left border-collapse">
								<thead>
									<tr>
										<th className="px-4 py-2">Slot ID</th>
										<th className="px-4 py-2">Seeker Name</th>
										<th className="px-4 py-2">Ad Type</th>
										<th className="px-4 py-2">Price</th>
										<th className="px-4 py-2">Date</th>
										<th className="px-4 py-2"></th>
									</tr>
								</thead>
								<tbody>
									{slotReqs.map((slotReq, index) => (
										<tr
											key={index}
											className="border-t"
										>
											<td className="px-4 py-2">{index + 1}.</td>
											<td className="px-4 py-2">{slotReq?.seeker?.username}</td>
											<td className="px-4 py-2">{slotReq?.collab?.adType}</td>
											<td className="px-4 py-2">{slotReq?.collab?.price}</td>
											<td className="px-4 py-2">
												{formatDate(slotReq?.slot?.startDate)} -{" "}
												{formatDate(slotReq?.slot?.endDate)}
											</td>
											<td className="px-4 py-2">
												<button
													className="text-blue-500 hover:text-blue-700"
													onClick={() => setContactInfo(slotReq?.seeker)}
												>
													View Contact
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div className="flex flex-col items-center justify-center text-gray-500">
								<img
									src={EmptyDashboard}
									alt="Nothing to show"
									className="max-h-72"
								/>
								<p>You currently have no requests</p>
							</div>
						)}
					</div>
					{contactInfo && (
						<div className="mt-5 w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
							<div
								className="flex items-center justify-between cursor-pointer"
								onClick={() => setContactInfo(null)}
							>
								<p className="text-lg font-bold">Contact Details</p>
								<img
									className="w-5 h-5"
									src={UpArrow}
									alt="Close"
								/>
							</div>
							<div className="mt-4">
								<div className="flex items-center mb-4">
									<img
										className="w-6 h-6 mr-2"
										src={User}
										alt="user-img"
									/>
									<p className="text-gray-700">{contactInfo?.username}</p>
								</div>
								<div className="flex items-center mb-4">
									<img
										className="w-6 h-6 mr-2"
										src={Phone}
										alt="phone-img"
									/>
									<p className="text-gray-700">
										<a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
									</p>
								</div>
								<div className="flex items-center">
									<img
										className="w-6 h-6 mr-2"
										src={Email}
										alt="email-img"
									/>
									<p className="text-gray-700">
										<a href={`mailto:${contactInfo.email}`}>
											{contactInfo.email}
										</a>
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default InfluencerDashboard;
