import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import ChannelCard from "./mini_components/ChannelCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SlotBookPayment from "./SlotBookPayment";
import { FaCross } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./responsive.css"
import AuthContext from "./AuthContext";

const BrowseInfluencer = () => {
  const [selectedInfl, setSelctedInfl] = useState();
  const [influencers, setInfluencers] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedCollab, setSelectedCollab] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [active, setActive] = useState(null);
  const [orderConfirmModal, setOrderConfirmModal] = useState(false);

  const auth = useContext(AuthContext)


  const navigate = useNavigate()
  async function getYoutubeInfluencers() {
    await axios
      .get(`${server}/influencer/getAllYt`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("BrowseInfluencer 15:\n", response.data);
        setInfluencers(response.data);
      })
      .catch((err) => {
        console.log("BrowseInfluencer 18:\n", err);
      });
  }

  useEffect(() => {
    auth.setActive("influencer")
    getYoutubeInfluencers();
  }, []);

  function handleBookSlot() {
    if (!selectedCollab) {
      alert("Plese select Ad type");
      return;
    }
    if (!selectedSlot) {
      alert("Plese select the slot");
      return;
    }
    setPaymentModal(true);
  }
  const showToastMessage = () => {
    toast("The slot has been booked Successfully!!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const showToastSuccess = () => {
    toast("The payment is Successful ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  useEffect(() => {
    console.log("BrowseInfl187: ", paymentStatus);
    if (paymentStatus != "") {
      if (paymentStatus == "Success") {
        showToastSuccess();
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />;

        const bookSlot = {
          slot: selectedSlot,
          collab: selectedCollab,
          influencer: selectedInfl.influencer,
        };
        axios
          .post(`${server}/influencer/youtube/bookslot`, bookSlot, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setPaymentModal(false);
            showToastMessage();
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />;
            setOrderConfirmModal(true)
          })
          .catch((err) => {
            toast(`An error occured!\n ${err.message}`, {
              autoClose: 2000,
            });
            console.log(err);
          });
      }
      if (paymentStatus == "Failed") {
        toast(`The payment Has failed`, {
          autoClose: 2000,
        });
      }
    }
  }, [paymentStatus]);

  function formatDate(date) {
    if (date) {
      return (
        new Date(date).toLocaleDateString('en-GB', {
          day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, ' ')
      )
    }

    return ""


  }

  return (
    <>
      <Topbar />
      <ToastContainer />
      <div
        className="influencers-main"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          // padding: "10px",
          alignItems: "start",
        }}
      >
        <div
          className="influencers new-style-influencers "
          tyle={{ width: "40vw" }}
        >
          {influencers?.map((influencer, index) => {
            return (
              <div
                key={index}
                className="inflBox innox cursor-pointer"
                onClick={(e) => {
                  e.preventDefault()
                  setSelctedInfl(influencer)
                  window.scrollTo({
                    top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
                    behavior: 'smooth', // Use smooth scrolling for a nicer effect
                  });
                }}
              >
                <div
                  className={`card-influencer ${selectedInfl === influencer && "active-class"
                    }`}
                >
                  <ChannelCard channel={influencer.statistics} />
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="slotBook fixed-slotbook"
        //  style={{ width: "60vw" }}
        >
          {selectedInfl && (
            <>
              <div className="booking-slot-details">
                <div>
                  <div className="inflBox">
                    <ChannelCard channel={selectedInfl.statistics} />
                  </div>
                  <div className="bookingBox">
                    <div className="slotBox">
                      <p className="slotBox-title">Pick a Slot</p>
                      {selectedInfl?.slot?.map((slot, index) => {
                        return (
                          <>
                            <div
                              className={`${slot.isAvailable ? "available" : ""
                                } ${selectedSlot?._id == slot._id
                                  ? "activeSlot"
                                  : ""
                                }`}
                              onClick={() => {
                                slot.isAvailable && setSelectedSlot(slot);
                              }}
                              key={index}
                            >
                              <span className="slot-start-end">
                                {formatDate(slot.startDate)} - {formatDate(slot.endDate)}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="collabBox">
                      <p>Choose a prefered AD Type </p>
                      <div className="ad-type-slot">
                        {selectedInfl?.collabPrices?.map((collab, index) => {
                          return (
                            <div>
                              {/* <input
                                type="checkbox"
                                name=""
                                id={`collab${index}`}
                              />
                              <label htmlFor={`collab${index}`}> */}
                              <div
                                key={index}
                                //   className="labelItem"
                                className={`labelItem ${selectedCollab === collab &&
                                  "active-labelItem"
                                  }`}
                                onClick={() => {
                                  setSelectedCollab(collab);
                                }}
                              >
                                <span>{collab.adType}</span>
                                <span>{collab.price}</span>
                              </div>
                              {/* </label> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="slotBox-buttons">
                    <button
                      className="slot-buttons-book"
                      onClick={handleBookSlot}
                      type="button"
                    >
                      Book Slot
                    </button>
                  </div>

                  {/* <input
                    onClick={handleBookSlot}
                    type="button"
                    className="col-2 save-btn btn btn-lg btn-block my-2"
                    value="Book"
                  /> */}
                </div>
              </div>
            </>
          )}
          {selectedInfl && <div></div> ? (
            <div></div>
          ) : (
            <div className="empty-div">
              <div>
                <p className="empty-title">
                  Elevate your brand with powerful influencer partnerships
                </p>
                <p className="empty-text">
                  Influence millions. Grow your brand
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {paymentModal && (
        <SlotBookPayment
          setPaymentStatus={setPaymentStatus}
          selectedCollab={selectedCollab}
          selectedSlot={selectedSlot}
          setPaymentModal={setPaymentModal}
        />
      )}
      {orderConfirmModal && (
        <div className="modal-main" style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
        }}>
      <div className="buy-confirm">
        <IconContext.Provider value={{ className: "editIcon cursor-pointer", size: "24px" }} >
          <AiFillCloseCircle onClick={() => { setOrderConfirmModal(false); navigate("/bookedslot") }} />
        </IconContext.Provider>
        <p className="buy-title">Order Confirmed!</p>
        <p className="slot-text">Slot</p>
        <p className="slot-start-end-date">
          {selectedSlot?.startDate} - {selectedSlot?.endDate}
        </p>
        <p className="order">Order Summary</p>
        <div className="buy-content">
          <p className="buy-thing">{selectedCollab?.adType}</p>
          <p className="buy-price ">&#x20B9;{selectedCollab?.price}</p>
        </div>
        <div className="buy-content">
          <p className="buy-thing">Tax</p>
          <p className="buy-price">&#x20B9;12</p>
        </div>
        <div className="buy-content">
          <p className="buy-thing">Discount</p>
          <p className="buy-price">-&#x20B9;12</p>
        </div>
        <div className="buy-total">
          <p className="total-text">Total</p>
          <p className="total-price">&#x20B9;{selectedCollab?.price}</p>
        </div>
      </div>
    </div >

      )
      }
    </>
  );
};

export default BrowseInfluencer;
