import React from "react";

const ReportUserModal = ({ handleClose, handleReport, userToReport }) => {
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white rounded-lg shadow-md p-6 max-w-sm w-full">
				<div className="flex justify-between items-center mb-4">
					<h1 className="text-xl font-semibold text-purple-700">Report User</h1>
					<button
						className="text-gray-500 hover:text-gray-700"
						onClick={handleClose}
					>
						&times;
					</button>
				</div>
				<form onSubmit={handleReport}>
					<textarea
						name="inputField"
						placeholder={`Why do you want to report ${userToReport}?`}
						id="reportInputTxt"
						cols="30"
						rows="4"
						className="w-full p-2 border border-gray-300 rounded mb-4"
						required
					></textarea>
					<div className="flex justify-end space-x-4">
						<button
							type="button"
							className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
							onClick={handleClose}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
						>
							Report
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ReportUserModal;
