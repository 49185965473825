import React, { useState, useContext, useEffect, useRef } from "react";
import Topbar from "./mini_components/Topbar";
import MyChats1 from "./mini_components/MyChats1";
import ChatContext from "./Context/chat-context";
import ChatBox1 from "./mini_components/ChatBox1";
import { BsThreeDotsVertical } from "react-icons/bs";
import ExpandIcon from '../assets/images/icons8-forward-26.png'
import AuthContext from "./AuthContext";


const ChatPage1 = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const { user } = useContext(ChatContext);

  const [toggleSideNav, settoggleSideNav] = useState(true)
  const [showSearchIcon, setIcon] = useState(true)
  const auth = useContext(AuthContext);
  const sideBarRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    console.log(toggleSideNav)
  }, [toggleSideNav])



  const handleSideNavbar = () => {

    if (toggleSideNav) {
      settoggleSideNav(false);
      // document.querySelector(".side_chat").style.display = "block"
      document.querySelector(".side_chat").style.width = "100%"
      setIcon(true)

    }
    else {
      settoggleSideNav(true)
      // document.querySelector(".side_chat").style.display = "none"
      document.querySelector(".side_chat").style.width = "0"
      setIcon(false)

    }
  }

  const handleClickOutside = (event) => {
    if (sideBarRef.current && !sideBarRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
      settoggleSideNav(false);
      // document.querySelector(".side_chat").style.display = "block"
      document.querySelector(".side_chat").style.width = "0"
      setIcon(false)
      // Click occurred outside the sidebar, so close it
      // Add code here to close the sidebar (e.g., update state)
    }
  };

  useEffect(() => {
    auth.setActive("chat")
    document.querySelector(".side_chat").style.width = "100%"

    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])


  return (
    <>
      <Topbar />

      <div className="chat_page container-fluid">
        <h4 className="show-desktop">Messages</h4>
        

        <div className="side_chat" ref={sideBarRef}>
          {user && <MyChats1 fetchAgain={fetchAgain} handleSideNavbar={handleSideNavbar} toggleSideNav={toggleSideNav}
            showSearchIcon={showSearchIcon} setIcon={setIcon}
          />}
        </div>

        <div className="row">
          <div className="side_toggle_chat  col-lg-3">

            {user && <MyChats1 fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} toggleSideNav={toggleSideNav} />}
          </div>

          {user && (
            <ChatBox1 fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} ref={menuButtonRef} handleSideNavbar={handleSideNavbar} />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatPage1;
