import React, { useState, useEffect, useRef } from "react";
import { authentication } from "./firebase-config";
import {
   createUserWithEmailAndPassword,
   RecaptchaVerifier,
   signInWithPhoneNumber,
   sendEmailVerification
} from "firebase/auth";
import PhoneInput from "react-phone-number-input";
import { useAuthValue } from "./AuthContext";
import { useNavigate, useLocation, Link } from "react-router-dom";

import "react-phone-number-input/style.css";
import "./style.css";
import backimg from "../assets/images/kamal.jpeg";
import logo from "../assets/images/logo1.svg";
import axios from "axios";
import server from "./server";
import { toast } from "react-toastify";

const Login = () => {
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { setTimeActive } = useAuthValue();
	const [errorMsg, setErrorMsg] = useState("");
	const [phone, setPhone] = useState("");
	const [values, setValues] = useState({
		fname: "",
		lname: "",
		email: "",
		pass: "",
		repass: "",
		city: "",
		uType: "",
	});

	const [captchaExpired, setCaptchaExpired] = useState(false);
	const [expireMsg, setExpireMsg] = useState(
		"You clicked outside captcha container"
	);

	const inCaptcha = useRef(false);

	const onceOutsideclick = useRef(0);
	const validatePassword = () => {
		let isValid = true;
		if (values.pass !== "" && values.repass !== "") {
			if (values.pass !== values.repass) {
				isValid = false;
				setErrorMsg("Passwords does not match");
				console.log(isValid);
			}
		}
		return isValid;
	};
  
   const setUpRecaptcha = (number) => {
      setTimeout(() => {
         if(inCaptcha.current){
            setExpireMsg("Captcha Timeout")
            setCaptchaExpired(true)
         }
      },
      240000
      );
      window.recaptchaVerifier = new RecaptchaVerifier(
         authentication,
         "recaptcha-container",
         {
            size: "invisible",
            callback: (response) => {
               // reCAPTCHA solved, allow signInWithPhoneNumber.
               inCaptcha.current = false;
            },
         }
      );
   };


	// const uTypeValue = uType;

	const emailVerification = (values) => {
		const { fname, lname, email, pass, city, } = values;
		createUserWithEmailAndPassword(authentication, values.email, values.pass)
			.then(() => {
				sendEmailVerification(authentication.currentUser)
					.then(() => {
						console.log("email validated");
						setTimeActive(true);
						axios
							.post(`${server}/auth/signup`, {
								fname: fname,
								lname: lname,
								email: email,
								pass: pass,
								phone: phone,
								city: city,
								type: location.state.type,
							})
							.then((res) => {
								if (res.status === 200) {
									navigate("/verification");
									toast("Please verify your email and Phone number !", {
										autoClose: 2000,
									});
								}
							})
							.catch((error) => {
								alert(error);
								setLoader(false);
							});
					})
					.catch((err) => {
						alert(err.message);
						setLoader(false);
					});
			})
			.catch((err) => {
				setErrorMsg(err.message);
				setLoader(false);
			});
	};

	const getOtp = (e) => {
		setLoader(true);
		e.preventDefault();
		setErrorMsg("");
		// const { fname, lname, email, pass, uType } = values;
		setValues({
			fname: "",
			lname: "",
			email: "",
			pass: "",
			repass: "",
			city: "",
		});

		console.log("getOTP");
		console.log(values);
		console.log(phone);

		if (phone.length >= 12 && validatePassword()) {
			inCaptcha.current = true;
			setUpRecaptcha();

			document.getElementById("recaptcha-container").innerHTML = "";
			let appVerifier = window.recaptchaVerifier;
			console.log(location.state.type);

			signInWithPhoneNumber(authentication, phone, appVerifier)
				.then((confirmationResult) => {
					window.confirmationResult = confirmationResult;
					//    console.log(res);
					// Create a new user with email and password using firebase
					console.log("phone validated");
					emailVerification(values);
				})
				.catch((err) => {
					console.log(err);
					setLoader(false);
				});
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", (event) => {
			let cap = document.getElementById("recaptcha-container");
			if (
				cap?.innerHTML !== "" &&
				!cap?.contains(event.target) &&
				onceOutsideclick.current < 1
			) {
				document.getElementById("recaptcha-container").innerHTML = "";
				setCaptchaExpired(true);
				onceOutsideclick.current += 2;
			}
		});
	}, []);

	return (
		<>
			{/* <ToastContainer/> */}
			<div className="login-container row">
				<div className="left-side col-lg-5">
					<div className="top-left d-flex align-items-center">
						<i
							onClick={() => {
								navigate("/");
							}}
							className="bi bi-arrow-left"
						></i>
						<p className="px-3 m-0">Signup</p>
					</div>
					<img
						className="login-img"
						src={backimg}
						alt=""
					/>
					<div className="login-footer">
						<div className="open-quote">“</div>
						<div className="quote">
							I’m a 21st century man. I don’t belive in magic. I belive in
							sweat, tears, life and death.
						</div>
						<div className="author">kamal haasan</div>
						<div className="close-quote">”</div>
						<div className="three-dots">
							<i className="fa-solid fa-circle mx-1"></i>
							<i className="fa-regular fa-circle mx-1"></i>
							<i className="fa-regular fa-circle mx-1"></i>
						</div>
					</div>
				</div>
				<div className="right-side col-lg-7 d-flex align-items-center justify-content-center">
					{/* Signup Form */}
					<form
						onSubmit={getOtp}
						className="form-container"
						id="signup-form"
					>
						<img
							src={logo}
							alt=""
							className="form-logo w-50"
						/>
						<input
							type="text"
							placeholder="Enter First Name"
							className="form-control my-2"
							label="Name"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, fname: event.target.value }));
							}}
						/>
						<input
							type="text"
							placeholder="Enter Last Name"
							className="form-control my-2"
							label="Name"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, lname: event.target.value }));
							}}
						/>
						<input
							type="email"
							placeholder="Email"
							className="form-control my-2"
							label="Email"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, email: event.target.value }));
							}}
						/>
						<input
							type="password"
							placeholder="Password"
							className="form-control my-2"
							label="Password"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, pass: event.target.value }));
							}}
						/>
						<input
							type="password"
							placeholder="Re-enter Password"
							className="form-control my-2"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, repass: event.target.value }));
							}}
						/>
						<input
							type="text"
							placeholder="Enter City"
							className="form-control my-2"
							onChange={(event) => {
								setValues((prev) => ({ ...prev, city: event.target.value }));
							}}
						/>
						<PhoneInput
							className="form-control"
							placeholder="Enter phone number"
							value={phone}
							onChange={setPhone}
							defaultCountry="IN"
						/>
						<div style={{ position: "relative", display: "flex" }}>
							<select
								defaultValue={location.state.type}
								className="form-control my-2"
								disabled={true}
							>
								<option value="user">Talent</option>
								<option value="seeker">Seeker</option>
								<option value="influencer">Influencer</option>
							</select>
							<div
								style={{
									position: "absolute",
									right: "15px",
									top: "0",
									bottom: "0",
									display: "flex",
									alignItems: "center",
									color: "lightgrey",
								}}
							>
								<i className="fa-solid fa-chevron-down"></i>
							</div>
						</div>
						<b>{errorMsg}</b>
						{loader ? (
							<button
								className="submit-btn btn btn-lg btn-block my-2 btn-loader"
								onClick={(e) => {
									e.preventDefault();
								}}
							>
								<div class="lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</button>
						) : (
							<input
								type="submit"
								className="submit-btn btn btn-lg btn-block my-2"
								value="Signup"
							/>
						)}
						<div className="alternate-option my-3 text-center">
							Already have an account{" "}
							<Link to="/login">
								<b>
									<u>Login</u>
								</b>
							</Link>
						</div>
					</form>
					<div id="recaptcha-container"></div>
				</div>
			</div>
			{captchaExpired && (
				<div
					id="myModal"
					class="modal"
					style={{ display: "block", zIndex: "2000000001" }}
				>
					<div
						class="modal-content"
						style={{ marginTop: "12rem" }}
					>
						<p>The captcha has been expired!! You need to reload the page</p>
						<span>
							{" "}
							<b>Reason:</b> {expireMsg}{" "}
						</span>
						<div className="text-center">
							<button
								onClick={() => {
									window.location.reload();
								}}
								type="submit"
								className=" save-btn btn btn-lg btn-block m-2"
								value="Delete"
							>
								Reload
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Login;
