import React, { useState, useEffect, useContext } from "react";
import Topbar from "./mini_components/Topbar";
import Searchbar from "./mini_components/Searchbar";
import Card from "./mini_components/Card";
import axios from "axios";
import { AiFillCheckCircle } from "react-icons/ai";
import cardImg from "../assets/images/rectangle-13.png";
import AuthContext from "./AuthContext";
import { BsChevronDown, BsPhone } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import server from "./server";
import PageNotFound from "./PageNotFound";
import { ToastContainer } from "react-toastify";
import DownArrow from '../assets/images/expandDown.png'


const TalentDashboard = () => {
    const auth = useContext(AuthContext);
    const [cards, setcards] = useState();
    const [userImg, setuserImg] = useState();
    const [query, setQuery] = useState("");

    const [showstrength, setshowstrength] = useState(false)
    const location = useLocation()

    const [Seeker, setSeeker] = useState(location.state?.seekerName);

    const [maxLimit, setMaxLimit] = useState(9)



    const handleSearch = () => {
        axios.get(`${server}/project/SearchProjectForAdmin?name=${query}`)
            .then(async (res) => {
                setcards(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [profileStrength, setProfileStrength] = useState({
        profile: 0,
        talent: 0,
        photo: 0,
        education: 0,
        roles: 0,
    });

    const getProjects = async () => {
        if (Seeker) {
            axios.get(`${server}/project/getOnlySeekersProject/${location.state.seekerId}`)
                .then(async (res) => {
                    setcards(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            axios
                .get(`${server}/project/allProjects`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        }
                    }
                )
                .then(async (res) => {
                    setcards(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        getProjects();
    }, [setcards, Seeker]);

    const user = JSON.parse(localStorage.getItem("login"));

    const handleShow = async () => {
        axios
            .get(`${server}/profile/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                if (response.data !== null) {
                    let prof = response.data
                    prof.link = user?.link
                    setuserImg(prof)
                    profileCompletion(response.data);
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    // Profile Strength Score
    const profileCompletion = (data) => {
        const profilePerc = data.basicInfo ? calculatePercentage(data.basicInfo) : 0;
        const talentPerc = data.talent ? calculatePercentage(data.talent) : 0;
        const photoPerc = data.photos.length === 0 ? 0 : 50;
        const videoPerc = data.videos.length === 0 ? 0 : 50;
        const eduPerc = data.education.length === 0 ? 0 : 50;
        const skillPerc = data.skills.length === 0 ? 0 : 50;
        const rolePerc = data.rolePref.length === 0 ? 0 : data.rolePref.length === 1 ? 50 : 100;

        const obj = {
            profile: profilePerc,
            talent: talentPerc,
            photo: photoPerc + videoPerc,
            education: eduPerc + skillPerc,
            roles: rolePerc,
        };

        setProfileStrength(obj);
    };

    // Calculate percentage by taking ratio of (total_filled_fields / total_fileds) * 100
    const calculatePercentage = (obj) => {
        let total_fileds = 0;
        let filled_fileds = 0;
        for (let property in obj) {
            total_fileds += 1;
            if (obj[property] === "") {
                continue;
            }
            filled_fileds += 1;
        }
        const percentage = (filled_fileds / total_fileds) * 100;
        return Math.floor(percentage);
    };

    useEffect(() => {
        handleShow();
        auth.setActive("home")
    }, []);

    return (
        <>
            <Topbar />
            <div className="container-fluid talent_container" style={{ padding: "0 60px" }}>
                <ToastContainer />
                <Searchbar setQuery={setQuery} query={query} handleSearch={handleSearch} />


                <div className="container-fluid">

                    {/* add here card */}
                    <div className="strength-main-div">
                        <span onClick={(e) => {
                            e.preventDefault()
                            setshowstrength(!showstrength)
                        }}>
                            Profile Strength
                            {
                                showstrength ? <img src={DownArrow} alt="" style={{
                                    width: "30px",
                                    height: "30px",
                                    verticalAlign: "center",
                                    transform: 'rotate(180deg)'
                                }} />
                                    : <img src={DownArrow} alt="" style={{
                                        width: "30px",
                                        height: "30px",
                                        alignContent: "center"
                                    }} />


                            }
                        </span>
                    </div>

                    {
                        showstrength ? <div className="row">
                            <ul className="grid-wrapper"
                                style={{ gridTemplateRows: "70px repeat(4, 170px" }}>
                                <div className="talent-heading d-flex justify-content-between"
                                // style={auth.clicked ? { gridArea: "1/1/2/4" } : { gridArea: "1/1/2/5" }}
                                >
                                    {/* <div className="">Suggestions</div> */}

                                </div>

                                <li className="side_div"
                                //  style={auth.clicked ? {} : { display: "none" }}
                                >
                                    <div className="sd_1">
                                        <div className="sd_upper d-flex justify-content-center align-items-center flex-column mb-3">
                                            <img src={userImg?.link} alt="" />
                                            <p>{user.username}</p>
                                            <div>
                                                <h6>Profile Strength :</h6>
                                                <span>
                                                    {(profileStrength.profile &&
                                                        profileStrength.talent &&
                                                        profileStrength.photo &&
                                                        profileStrength.education &&
                                                        profileStrength.roles) <= 80 ? <p className="text-danger">Poor</p> : "Awesome"}
                                                </span>
                                            </div>
                                            <div className="line"></div>
                                        </div>
                                        <div className="sd_lower">
                                            <div>
                                                <p>Profile Details</p>
                                                <span>
                                                    <AiFillCheckCircle />
                                                    <p>{profileStrength.profile}%</p>
                                                </span>
                                            </div>
                                            <div>
                                                <p>Talent Details</p>
                                                <span>
                                                    <AiFillCheckCircle />
                                                    <p>{profileStrength.talent}%</p>
                                                </span>
                                            </div>
                                            <div>
                                                <p>Photos & Videos</p>
                                                <span>
                                                    <AiFillCheckCircle />
                                                    <p>{profileStrength.photo}%</p>
                                                </span>
                                            </div>
                                            <div>
                                                <p>Education & Skills</p>
                                                <span>
                                                    <AiFillCheckCircle />
                                                    <p>{profileStrength.education}%</p>
                                                </span>
                                            </div>
                                            <div>
                                                <p>Role Preferences</p>
                                                <span>
                                                    <AiFillCheckCircle />
                                                    <p>{profileStrength.roles}%</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                            : <></>
                    }

                    <div>
                        <h5 className="d-inline" > {Seeker ? "Showing results for " + Seeker : <strong>Suggestions</strong>} </h5>
                        <button className="btn" style={{ display: `${!Seeker ? "none" : "inline-block"}`, border: "1px solid black", borderRadius: "10px", marginLeft: "4rem" }} onClick={() => { setSeeker() }} >
                            {Seeker ? "Clear Seeker filter" : ""}
                        </button>
                    </div>
                    <div className="row">
                        <ul className="grid-wrapper"
                            style={{ gridTemplateRows: "70px repeat(4, 170px" }}>
                            <div className="talent-heading d-flex justify-content-between"
                                style={auth.clicked ? { gridArea: "1/1/2/4" } : { gridArea: "1/1/2/5" }}
                            >
                                {/* <div className="">Suggestions</div> */}

                            </div>


                            {
                                (cards?.length === 0 || cards === undefined) ?
                                    <div className="text-center mx-auto " >
                                        <PageNotFound Seeker={Seeker} />
                                    </div>
                                    :
                                    cards?.map((card, index) => (
                                        index < maxLimit && (
                                            <>
                                                <Card card={card} profile={profileStrength} UserProfileDeatils={userImg} setClicked={auth.setClicked} />
                                            </>
                                        )
                                    ))
                            }


                            <li className="side_div show-desktop" style={auth.clicked ? {} : { display: "none" }}>
                                <div className="sd_1">
                                    <div className="sd_upper d-flex justify-content-center align-items-center flex-column mb-3">
                                        <img src={userImg?.link} alt="" />
                                        <p>{user.username}</p>
                                        <div>
                                            <h6>Profile Strength :</h6>
                                            <span>
                                                {(profileStrength.profile &&
                                                    profileStrength.talent &&
                                                    profileStrength.photo &&
                                                    profileStrength.education &&
                                                    profileStrength.roles) <= 80 ? <p className="text-danger">Poor</p> : "Awesome"}
                                            </span>
                                        </div>
                                        <div className="line"></div>
                                    </div>
                                    <div className="sd_lower">
                                        <div>
                                            <p>Profile Details</p>
                                            <span>
                                                <AiFillCheckCircle />
                                                <p>{profileStrength.profile}%</p>
                                            </span>
                                        </div>
                                        <div>
                                            <p>Talent Details</p>
                                            <span>
                                                <AiFillCheckCircle />
                                                <p>{profileStrength.talent}%</p>
                                            </span>
                                        </div>
                                        <div>
                                            <p>Photos & Videos</p>
                                            <span>
                                                <AiFillCheckCircle />
                                                <p>{profileStrength.photo}%</p>
                                            </span>
                                        </div>
                                        <div>
                                            <p>Education & Skills</p>
                                            <span>
                                                <AiFillCheckCircle />
                                                <p>{profileStrength.education}%</p>
                                            </span>
                                        </div>
                                        <div>
                                            <p>Role Preferences</p>
                                            <span>
                                                <AiFillCheckCircle />
                                                <p>{profileStrength.roles}%</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                {
                    cards?.length == 0 ? <></>
                        : <div className="loadmore">
                            <button className="btn" onClick={() => cards.length > 9 && setMaxLimit(cards.length)}>Load More</button>
                        </div>

                }
            </div>
        </>
    );
};

export default TalentDashboard;
