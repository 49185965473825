import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "../forms.css";
import form_group from "../../../assets/images/form_group.svg";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import server from "../../server";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const TalentDetailsForm = ({ display, toggle, getFunction, userData }) => {
  const [bool, setbool] = useState(true);
  const animatedComponents = makeAnimated();
  let show = {};
  if (display) {
    show = { display: "block" };
  } else {
    show = { display: "none" };
  }
  const user = JSON.parse(localStorage.getItem("login"));
  const [lang, setlang] = useState([]);
  const [talentDetails, setTalentDetails] = useState({
    type: "",
    height: "",
    weight: "",
    bodyType: "",
    skinTone: "",
    eyeColour: "",
    hairColour: "",
    hairStyle: "",
    beardStyle: "",
    language: lang,
    boldScenes: "",
    allowances: "",
    travelling: "",
  });

  const [Roles, setRoles] = useState([]);
  const handleInputChange = (e) => {
    setTalentDetails({ ...talentDetails, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.type === "admin") {
      const res = await fetch(`${server}/profile/editTalentAtAdmin/${userData._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...talentDetails , language : lang}),
      });
      const ok = await res.json();
      if (ok) {
        toast("Talent details updated successfully", {
          autoClose: 2000,
        })
        toggle("bio");
      }
    } else {
      if (bool) {
        axios
          .post(`${server}/profile/`, { talent: {...talentDetails , language : lang} }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Profile details saved successfully", {
              autoClose: 2000,
            });
            toggle("bio");
          })
          .catch((err) => {
            toast(err.message, {
              autoClose: 2000,
            });
          })
      } else {
        const res = await fetch(`${server}/profile/talent`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({...talentDetails , language : lang}),
        });
        const ok = await res.json();
        console.log(ok)
        if (ok) {
          toast("Talent details updated successfully", {
            autoClose: 2000,
          })
          toggle("bio");
        }
      }
    };
  }
  const handleShow = async () => {
    axios
      .get(`${server}/profile/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data !== null) {
          setbool(false);
          if (response.data.talent.type !== "") {
            setTalentDetails(response.data.talent);
            setlang(response.data.talent.language)
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  function handleUnitChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "heightunit") {
      let hgt = String(talentDetails.height).split("-")
      hgt[1] = value
      let newHgt = hgt.join("-")
      setTalentDetails({ ...talentDetails, ["height"]: newHgt })
    }
  }

  function handleHeightWeightChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "height") {
      let hgt = String(talentDetails.height).split("-")
      hgt[0] = value
      let newHgt = hgt.join("-")
      setTalentDetails({ ...talentDetails, [name]: newHgt })

    }
    if (name == "weight") {
      let wgt = String(talentDetails.weight).split("-")
      wgt[0] = value
      wgt[1] = "kg"
      let newWgt = wgt.join("-")
      setTalentDetails({ ...talentDetails, [name]: newWgt })
    }
  }

  const getRoles = () => {
    axios
      .get(`${server}/admin/roles`)
      .then((res) => {
        setRoles(res.data)
      })
      .catch((err) => { console.log(err) });
  }

  const handleFormfields = (id) => {
    setlang(lang.filter((item) => {
      return item.value !== id;
    }))
  }

  useEffect(() => {
    if (user.type === "user") {
      handleShow();
      getRoles()
    } else {
      setTalentDetails(userData.talent);
    }
  }, []);
  return (
    <>
      {
        <div className="form-body" style={show}>
          <div className="form-container">
            <div className="form-head">Talent Details</div>
            <div className="form-desc">
              Let us know about you to suggest the best for you.
            </div>
            <form onSubmit={handleSubmit}>
              <div style={{ position: "relative", display: "flex" }}>
                <select
                  name="type"
                  onChange={handleInputChange}
                  value={talentDetails.type}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    I'm an
                  </option>
                  {
                    Roles?.map((role, index) => {
                      return (
                        <option value={role.role} key={index}> {role.role} </option>
                      )
                    })
                  }
                </select>
              </div>
              <div style={{ position: "relative" }}>
                <div className="form_group">
                  <p>Body</p>
                  <img src={form_group} alt="" />
                </div>
                <div>
                  <label htmlFor="slider-wght" className="d-block">Height</label>
                  <input
                    className="form-control w-75 d-inline-block"
                    name="height"
                    type="number"
                    step=".01"
                    id="slider-wght"
                    min="1"
                    max="900"
                    onChange={handleHeightWeightChange}
                    value={String(talentDetails.height).split("-")[0]}
                  />
                  <select name="heightunit" className="form-select w-25 d-inline-block" onChange={handleUnitChange} value={String(talentDetails.height).split("-")[1]} required>
                    <option value="Unit" disabled selected>Unit</option>
                    <option value="ft">Feet</option>
                    <option value="inch">Inch</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="slider-hgt" className="d-block">Weight</label>
                  <input
                    className="form-control w-75 d-inline-block"
                    name="weight"
                    type="number"
                    step=".01"
                    id="slider-hgt"
                    min="1"
                    max="900"
                    onChange={handleHeightWeightChange}
                    value={String(talentDetails.weight).split("-")[0]}
                  />
                  <span className="form-control w-25 d-inline-block" style={{ padding: "12px" }}>Kgs</span>
                </div>
                <select
                  name="bodyType"
                  onChange={handleInputChange}
                  value={talentDetails.bodyType}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Body Type
                  </option>
                  <option>Petite</option>
                  <option>Thin</option>
                  <option>Athletic</option>
                  <option>Medium</option>
                  <option>Plus Size</option>
                  <option>Body Builder</option>
                </select>
                <select
                  name="skinTone"
                  onChange={handleInputChange}
                  value={talentDetails.skinTone}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Skin Tone
                  </option>
                  <option>White</option>
                  <option>Brown</option>
                  <option>Black</option>
                </select>
              </div>
              <div style={{ position: "relative" }}>
                <div className="form_group">
                  <p>Face & Head</p>
                  <img src={form_group} alt="" />
                </div>
                <select
                  name="eyeColour"
                  onChange={handleInputChange}
                  value={talentDetails.eyeColour}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Eyes Color
                  </option>
                  <option>Black</option>
                  <option>Brown</option>
                  <option>Blue</option>
                  <option>Green</option>
                  <option>Grey</option>
                  <option>Other</option>
                </select>
                <select
                  name="hairColour"
                  onChange={handleInputChange}
                  value={talentDetails.hairColour}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Hair Color
                  </option>
                  <option>Black</option>
                  <option>Brown</option>
                  <option>White</option>
                  <option>Grey</option>
                  <option>Other</option>
                </select>
                <select
                  name="hairStyle"
                  onChange={handleInputChange}
                  value={talentDetails.hairStyle}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Hair Style
                  </option>
                  <option>Bald</option>
                  <option>Short</option>
                  <option>Shoulder Length</option>
                  <option>Long</option>
                  <option>Other</option>
                </select>
                <select
                  name="beardStyle"
                  onChange={handleInputChange}
                  value={talentDetails.beardStyle}
                  className="form-control form-select"
                >
                  <option value="" disabled selected>
                    Beard Style
                  </option>
                  <option>Goat patch</option>
                  <option>Goatee</option>
                  <option>The Zappa</option>
                  <option>Balbo</option>
                  <option>Anchor beard</option>
                  <option>Soul patch</option>
                  <option>Van Dyke beard</option>
                  <option>Verdi beard</option>
                  <option>Garibaldi beard</option>
                  <option>Ducktail</option>
                  <option>Hollywoodian</option>
                  <option>Other</option>
                </select>
              </div>
              <div>
                  {lang.map((item) => {
                    return (
                      <>
                        <div className="cross-wala-div">{item.label} <span onClick={() => { handleFormfields(item.value) }} className="times">&times;</span></div>
                      </>
                    )
                  })}
                </div>
              <Select isMulti
                className="mb-3"
                onChange={(e) => { setlang(e) }}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={lang}
                options={[
                  { value: 'English', label: 'English' },
                  { value: 'Marathi', label: 'Marathi' },
                  { value: 'Hindi', label: 'Hindi' },
                  { value: 'Tamil', label: 'Tamil' },
                  { value: 'Telugu', label: 'Telugu' },
                  { value: 'Malayalam', label: 'Malayalam' },
                  { value: 'Kannada', label: 'Kannada' },
                  { value: 'Bengali', label: 'Bengali' },
                  { value: 'Others', label: 'Others' }
                ]} />
              <select
                name="boldScenes"
                onChange={handleInputChange}
                value={talentDetails.boldScenes}
                className="form-control form-select"
              >
                <option value="" disabled selected>
                  Bold Scenes
                </option>
                <option>Yes</option>
                <option>No</option>
                <option>Project Based</option>
              </select>
              <select
                name="allowances"
                onChange={handleInputChange}
                value={talentDetails.allowances}
                className="form-control form-select"
              >
                <option value="" disabled selected>
                  Allowances
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <select
                name="travelling"
                onChange={handleInputChange}
                value={talentDetails.travelling}
                className="form-control form-select"
              >
                <option value="" disabled selected>
                  Traveling
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div className="buttons justify-content-between mt-5">
                <input
                  type="submit"
                  className="col-4 cancel-btn btn btn-lg btn-block my-2"
                  value="Cancel"
                />
                <p className="col-1"></p>
                <input
                  type="submit"
                  className="col-7 save-btn btn btn-lg btn-block my-2"
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>
      }
    </>
  );
}

export default TalentDetailsForm;
