import React, { useEffect, useState } from "react";

import axios from "axios"
import server from '../../server.js'
const RoleLabelForm = ({ display , functions }) => {
    const [resetConfModal, setResetConfModal] = useState(false)
    const [roles, setRoles] = useState([])
    let show = {};
    if (display) {
        show = { display: "block" };
    } else {
        show = { display: "none" };
    }
    const formFields = functions.formFields ;
    const setformFields = functions.setformFields ; 
    const resetRoles = functions.resetRoles ; 

    
    const handleFormChange = (e, index) => {
        let data = [...formFields];
        data[index].role = e.target.value;
        setformFields(data);
    };

    const addFields = () => {
        let obj = { role: "" , characters : [] };
        setformFields([...formFields, obj]);
    };

   
    var x = document.getElementById("snackbar");
    function myFunction() {
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1);
        setformFields(data);
    };

    const handleRoles = (e) =>{
        e.preventDefault();
        myFunction()
        functions.toggleForm("description");
    }

    function handleResteForm(){
        resetRoles()
        setResetConfModal(false)
    }

    const getRolesPref = async()=>{
        await axios.get(`${server}/project/getrolepref`)
        .then((response)=>{
            setRoles(response.data)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
      getRolesPref()
    }, [])
    
    
    return (
        <>
        <div className="form-body" style={show}>
            <div className="form-container">
                <div className="form-head">Role Labels</div>
                <div className="form-desc">Tell us the Roles to be in action for the project</div>

                <form id="form1" onSubmit={handleRoles}>
                    <input type="button" className="full-width-btn" value="Add Roles" onClick={addFields} />

                    {formFields.map((form, index) => {
                        return (
                            <div key={index} className="d-flex align-items-center">
                                <select
                                    className="form-control form-select"
                                    data-num={index}
                                    name="role"
                                    value={form.role}
                                    onChange={(e) => {
                                        handleFormChange(e, index);
                                    }}
                                    required
                                >
                                    <option value="" disabled selected>
                                        Name the Role
                                    </option>
                                    {/* <option selected={form.role === "Main Role Hero"} value="Supporting Actor">Supporting Actor</option>
                                    <option selected={form.role === "Main Role Hero"} value="Main Role Hero">Main Role Hero</option>
                                    <option selected={form.role === "Main Role Hero"} value="Main Role Villan">Main Role Villan</option> */}

                                    {
                                        roles?.map((role,index)=>{
                                            return(
                                                <option disabled={form.role === role.role} value={role.role}>
                                                    {role.role}
                                                </option>

                                            )
                                        })
                                    }
                                </select>
                                <i className="fa-solid fa-trash-can mx-2 mb-2" onClick={() => removeFields(index)}></i>
                            </div>
                        );
                    })}

                    <div className="buttons justify-content-between">
                        <input className="col-4 cancel-btn btn btn-lg btn-block my-2" value="Reset" onClick={(e)=>{ e.preventDefault(); setResetConfModal(true) }} />
                        <p className="col-1"></p>
                        <input type="submit"  className="col-7 save-btn btn btn-lg btn-block my-2" value="Save" />
                    </div>
                </form>
            </div>

        </div>
        {
                resetConfModal && (
                    <div id="myModal" class="modal" style={{display:"block"}}>
                        <div class="modal-content" style={{ width: "25%", marginTop: "12rem" }}>
                            <p>Your are sure you want to reset the details</p>
                            <div className="text-center" >
                                <button
                                    onClick={(e) => { e.preventDefault(); setResetConfModal(false) }}
                                    type="submit"
                                    className=" save-btn btn btn-lg btn-block m-2"
                                    value="Delete"
                                >Cancel</button>
                                <button
                                    onClick={(e) => { e.preventDefault(); handleResteForm() }}
                                    type="submit"
                                    className=" save-btn btn btn-lg btn-block m-2"
                                    value="Reset"
                                >Reset</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>

    );
};

export default RoleLabelForm;
