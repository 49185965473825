import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ChannelStats from "./forms/influencer_YT_details/ChannelStats";
import CollabDetails from "./forms/influencer_YT_details/CollabDetails";
import SlotDetails from "./forms/influencer_YT_details/SlotDetails";
import Topbar from "./mini_components/Topbar";
import server from "./server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "./AuthContext";

const SlotInfo = () => {
    const user = JSON.parse(localStorage.getItem("login"));
    let channelDetailsObj = {
        channelId: "",
        channelName: "",
        channelLink: "",
        subscriberCount: "",
        videoCount: "",
        viewCount: "",
        averageCommentsCount: 0,
        averageLikesCount: 0,
    };

    let sampleChannelDetails = {
        channelId: "UCBqFKDipsnzvJdt6UT0lMIg",
        channelLink: "https://www.youtube.com/@SandeepSeminars",
        channelName: "Sandeep Maheshwari",
        hiddenSubscriberCount: false,
        subscriberCount: "27300000",
        videoCount: "574",
        viewCount: "2245403270",
        averageCommentsCount: "9377.42",
        averageLikesCount: "271469.5",
    };

    let collabObj = {
        adType: "",
        price: "",
      };

    let slotObj = {
        startDate: "",
        endDate: "",
        isAvailable: false,
    };

    const [channelDetails, setChannelDetails] = useState(channelDetailsObj);
    const [collabDetails, setCollabDetails] = useState([collabObj]);
    const [slotDetails, setSlotDetails] = useState([slotObj]);
    const [formSelect, setFormSelect] = useState(1);

    const auth = useContext(AuthContext)


    const handleNextClick = () => {
        for (let index = 0; index < slotDetails.length; index++) {
            const element = slotDetails[index];
            if (checkIfFormFilled(element)) {
                alert(`Entry no. ${index + 1} is empty please fill it`);
                break;
            } else if (index == slotDetails.length - 1) {
                axios
                    .post(
                        `${server}/influencer/youtube`,
                        {
                            statistics: channelDetails,
                            collabDetails: collabDetails,
                            slotDetails: slotDetails,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        showToastMessage();
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />;
                    })
                    .catch((err) => {
                        alert(err.message);
                    });
            }
        }
    };

    const handlePrevClick = () => {
        if (formSelect != 1) {
            setFormSelect(prev => prev - 1)
        }
    }



    async function getYouTubeInfo() {
        // console.log(user);
        await axios
            .get(`${server}/influencer/ytInfo/${user._id}`)
            .then((response) => {
                let { data } = response;
                if (response.data) {
                    setChannelDetails(data.statistics);
                    setCollabDetails(data.collabPrices);
                    setSlotDetails(data.slot);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }



    function checkIfFormFilled(object) {
        for (const key in object) {
            const element = object[key];
            if (
                key != "isAvailable" &&
                key != "hiddenSubscriberCount" &&
                element == ""
            ) {
                return true;
            }
        }
        return false;
    }

    const showToastMessage = () => {
        toast("Slots updated Successfully!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        // setFormSelect(1);
    };


    useEffect(() => {
        getYouTubeInfo();
        auth.setActive("slot")
    }, []);

    return (
        <>
            <Topbar />
            <div className="section-youtube-info">
                <div className="width-100-mobile">
                    <SlotDetails
                        slotObj={slotObj}
                        slotDetails={slotDetails}
                        setSlotDetails={setSlotDetails}
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                    />

                    {/* <button onClick={handleNextClick} >
                {formSelect == 3 ? "Submit" : "Next"}
            </button> */}
                    {/* <div className="container mx-auto" style={{ width: "fit-content" }}>
            <input
              type="Button"
              onClick={handleNextClick}
              className="col-20 mx-auto d-inline-block save-btn btn btn-lg btn-block my-2"
              value="Next ->"
            />
          </div> */}
                    <ToastContainer />
                </div>
            </div>
        </>
    );
};

export default SlotInfo;
