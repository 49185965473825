import React from "react";
import "./minicomp.css";
import "../responsive.css";
import { FaEye, FaUsers, FaVideo } from "react-icons/fa";
import { AiOutlineComment, AiOutlineLike } from "react-icons/ai";
import Youtube from "../../assets/icons/youtube.svg";
import Eye from "../../assets/icons/eye.svg";
import Comment from "../../assets/icons/comment.svg";
import Like from "../../assets/icons/like.svg";

const ChannelCard = ({ channel }) => {
  function formatNumber(num) {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 3,
    }).format(num);
  }

  return (
    <div className="flexCont">
      {/* <div className="part">
        <img src={channel.thumbnail} className="channelImg" />
      </div>
      <div className="part">
        <span className="channelTitle">{channel.channelName} &nbsp;</span>
        <span className="">
          {formatNumber(channel.subscriberCount)} Subscribers
        </span>
        <span className="country"> {channel.country} </span>

        <span className="channelTopics">
                    
                    {channel.topicCategories?.map((topic, index) => {
                        return <span key={index}>{topic} |</span>;
                    })}
                </span>
        <div className="channelStats">
          <span className="">
            <FaVideo /> Videos {formatNumber(channel.videoCount)}
          </span>
          <span className="">
            <FaEye /> Views {formatNumber(channel?.viewCount)}
          </span>
          <span className="likes">
            <AiOutlineLike /> Likes {formatNumber(channel?.averageLikesCount)}
          </span>
          <span className="">
            <AiOutlineComment /> Comments
            {formatNumber(channel?.averageCommentsCount)}
          </span>
        </div>
      </div> */}
      <div className="channel-head">
        <div className="channel-details">
          <div>
            <img
              src={channel.thumbnail}
              className="channelImg new-style-channelImg"
              alt="Thubnail"
            />
          </div>
          <div className="channel-face">
            <p className="channel-name">{channel.channelName}</p>
            <p className="sub-count">
              {formatNumber(channel.subscriberCount)} subscribers
            </p>
            <p className="channel-location">{channel.country}</p>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div className="channel-div">
            <div className="channel-info-div">
              <img className="channel-img" src={Youtube} alt="logo" />
              <p className="channel-info-text">
                {formatNumber(channel.videoCount)} Videos
              </p>
            </div>
            <div className="channel-info-div">
              <img className="channel-img" src={Like} alt="logo" />
              <p className="channel-info-text">
                {formatNumber(channel?.averageLikesCount)} Likes
              </p>
            </div>
          </div>
          <div className="channel-div">
            <div className="channel-info-div">
              <img className="channel-img" src={Eye} alt="logo" />
              <p className="channel-info-text">
                {formatNumber(channel?.viewCount)} Views
              </p>
            </div>
            <div className="channel-info-div">
              <img className="channel-img" src={Comment} alt="logo" />
              <p className="channel-info-text">
                {formatNumber(channel?.averageCommentsCount)} Comments
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="channel-topic">
            {channel.topicCategories?.map((topic, index) => {
              return <span key={index}>{topic} |</span>;
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChannelCard;
