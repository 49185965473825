import React, { useEffect, useState, useContext } from "react";
import {
	AiOutlineSearch,
	AiOutlineDelete,
	AiOutlineHome,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import ChatContext from "../Context/chat-context";
import { getSender, getSenderLink } from "../config/ChatLogics";
import UserListItem from "../userAvatar/UserListItem";
import profile from "../../assets/icons/profile1.svg";
import noChatsImg from "../../assets/images/nochats.svg";
import server from "../server";

const MyChats1 = ({
	fetchAgain,
	setFetchAgain,
	handleSideNavbar,
	showSearchIcon,
}) => {
	const [loggedUser, setLoggedUser] = useState();
	const [activeChat, setActiveChat] = useState();
	const [search, setSearch] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingChat, setLoadingChat] = useState(false);

	const {
		selectedChat,
		setSelectedChat,
		user,
		chats,
		setChats,
		chatUnReadCount,
		setChatUnReadCount,
		socket,
	} = useContext(ChatContext);

	const fetchChats = async () => {
		try {
			const config = {
				headers: { Authorization: `Bearer ${user.token}` },
			};
			const { data } = await axios.get(`${server}/api/chat`, config);
			setChats(data);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		setLoggedUser(JSON.parse(localStorage.getItem("login")));
		fetchChats();
	}, [fetchAgain]);

	const handleSearch = async (searchValue) => {
		if (!searchValue) return;
		try {
			setLoading(true);
			const config = {
				headers: { Authorization: `Bearer ${user.token}` },
			};
			const { data } = await axios.get(
				`${server}/api/user?search=${searchValue}`,
				config
			);
			setLoading(false);
			setSearchResult(data);
		} catch (error) {
			console.log(error.message);
			setLoading(false);
		}
	};

	const handleInputChange = (e) => {
		setSearch(e.target.value);
		if (e.target.value !== "") {
			handleSearch(e.target.value);
		} else {
			setSearchResult([]);
		}
	};

	const accessChatCreateChat = async (userId) => {
		try {
			setLoadingChat(true);
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${user.token}`,
				},
			};
			const { data } = await axios.post(
				`${server}/api/chat`,
				{ userId },
				config
			);
			if (!chats.find((chat) => chat._id === data._id))
				setChats([data, ...chats]);
			setSelectedChat(data);
			setActiveChat(0);
			setLoadingChat(false);
		} catch (error) {
			console.log(error.message);
			setLoadingChat(false);
		}
	};

	useEffect(() => {
		setSelectedChat(chats[0]);
	}, [chats]);

	const handleChatClick = (chat, i) => {
		if (window.innerWidth <= 468) {
			handleSideNavbar();
			window.scrollTo(0, document.body.scrollHeight);
		}
		setSelectedChat(chat);
		setActiveChat(i);
		if (chatUnReadCount > 0) {
			setChatUnReadCount((prev) => prev - chat.unreadCount);
			chat.unreadCount = 0;
			setChats([...chats]);
		}
		socket.emit("updateYourchat", chat, user);
		updateUnReadCount([chat]);
	};

	const updateUnReadCount = (localChats) => {
		localChats.map(async (item) => {
			try {
				const config = {
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				};
				await axios.put(
					`${server}/api/chat/updateUnreadCount`,
					{ item },
					config
				);
			} catch (error) {
				console.log(error.message);
			}
		});
	};

	const handleDeleteChat = async (chatId) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			};
			await axios.delete(`${server}/api/chat/${chatId}`, config);
			setChats(chats.filter((chat) => chat._id !== chatId));
			if (selectedChat && selectedChat._id === chatId) {
				setSelectedChat(null);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		socket.off("updateChat").on("updateChat", (chat) => {
			chat.unreadCount = 0;
			let newChats = chats.map((c) => (c._id === chat._id ? chat : c));
			setChats(newChats);
			if (selectedChat && selectedChat._id === chat._id) {
				setSelectedChat(chat);
			}
		});

		socket.off("chatUpdated").on("chatUpdated", (chat) => {
			setFetchAgain((prev) => !prev);
			if (selectedChat._id === chat._id) {
				setSelectedChat((prevChat) => ({ ...prevChat, status: chat.status }));
			}
		});
	}, [chats, selectedChat, setFetchAgain, socket]);

	return (
		<div className="flex flex-col h-full overflow-y-auto">
			<div className="flex flex-col w-full p-2">
				<div className="flex items-center gap-2 p-2 border-b border-gray-300">
					<Link to={`/${user.type}dashboard`}>
						<AiOutlineHome
							className="mr-2 text-purple-500 hover:text-purple-700"
							size={24}
						/>
					</Link>
					{showSearchIcon && <AiOutlineSearch className="mr-2" />}
					<input
						type="text"
						placeholder={
							loggedUser?.type === "seeker" ? "Search" : "Your Chats"
						}
						value={search}
						onChange={handleInputChange}
						className={`w-full p-2 pl-2 border-none outline-none rounded ${
							loggedUser?.type !== "seeker" ? "bg-gray-200 cursor-default" : ""
						}`}
					/>
				</div>
				<div
					className="flex flex-col overflow-y-auto p-2"
					onClick={() => {
						setSearchResult([]);
						setSearch("");
					}}
				>
					{loading ? (
						<div className="loader mx-auto"></div>
					) : (
						searchResult.map((user) => (
							<UserListItem
								key={user._id}
								user={user}
								handleFunction={() => accessChatCreateChat(user._id)}
							/>
						))
					)}
					{chats && chats.length > 0 ? (
						chats.map((chat, i) => (
							<div
								key={i}
								className={`flex items-center justify-between p-2 cursor-pointer border-b border-gray-300 ${
									activeChat === i ? "bg-gray-200" : ""
								}`}
							>
								<div
									onClick={() => handleChatClick(chat, i)}
									className="flex items-center flex-grow"
								>
									<figure className="mr-2">
										<img
											src={
												chat.users[1]?.link ||
												getSenderLink(loggedUser, chat.users) ||
												profile
											}
											alt="Profile"
											className="w-10 h-10 rounded-full"
										/>
									</figure>
									<div className="flex flex-col w-full">
										<div className="flex justify-between items-center">
											<h6 className="text-sm font-medium">
												{getSender(loggedUser, chat.users)}
											</h6>
											{chat.unreadCount > 0 &&
												chat.unReadBy &&
												(chat.unReadBy._id === user._id ||
													chat.unReadBy === user._id) && (
													<span className="text-xs text-white bg-red-500 rounded-full px-2 py-1">
														{chat.unreadCount}
													</span>
												)}
										</div>
										<p className="text-xs text-gray-600 truncate">
											{chat.latestMessage?.content.length > 45
												? `${chat.latestMessage.content.substring(0, 45)}...`
												: chat.latestMessage?.content}
										</p>
									</div>
								</div>
								<button
									onClick={() => handleDeleteChat(chat._id)}
									className="text-red-500 hover:text-red-700"
								>
									<AiOutlineDelete size={20} />
								</button>
							</div>
						))
					) : (
						<div className="flex flex-col  items-center justify-center text-gray-500">
							<img
								src={noChatsImg}
								alt="No Chats"
								className="h-full "
							/>
							<p>No chats available</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyChats1;
