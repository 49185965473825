import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import logo from "../assets/images/logo1.svg";
import server from "./server";

const EmailVerifyRoute = () => {
    const navigate = useNavigate();

    const [oobCode, setoobCode] = useState();
    const [loading, setloading] = useState(true);
    const [mode, setmode] = useState("");
    const [forgotValues, setforgotValues] = useState({
        email : "",
        pass1: "",
        pass2: "",
    });

    const [passError, setpassError] = useState("");


    const [searchParams, setSearchParams] = useSearchParams();
    const apiKey = "AIzaSyBE9xQNI1nx1oPmRgXsiO7e54onMtptBYU";

    const handleSamepass = (e) => {
        if (forgotValues.pass1 !== e.target.value) {
            setpassError("Password do not match");
        } else {
            setpassError("");
        }
    };

    const verifyEmail = (code) => {
        axios
            .post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}&oobCode=${code}`)
            .then((res) => {
                // alert(JSON.stringify(res))
                setloading(false);
            })
            .catch((err) => {
                // alert(JSON.stringify(err))
                if (err.response.data.error?.code === 400) {
                    alert("Verification Link Expired !");
                    // navigate("/")
                }
            })
    }

    const verifyPasswordResetCode = (code) => {
        axios
            .post(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}&oobCode=${code}`)
            .then((res) => {
                setforgotValues((prev) => ({
                    ...prev,
                    email: res.data.email,
                }))
                setloading(false);
            })
            .catch((err) => {
                if (err.response.data.error?.code === 400) {
                    alert("Reset Password Link Expired !");
                    navigate("/")
                }
            })
    }

    const ResetPass = (e) => {
        e.preventDefault();
        axios
            .post(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}&oobCode=${oobCode}&newPassword=${forgotValues.pass1}`)
            .then((res) => {
                if(res.status === 200){
                    axios
                        .put(`${server}/auth/resetPassword`, {
                            password: forgotValues.pass1,
                            email: forgotValues.email,
                        })
                        .then((res) => {
                            console.log(res);
                            alert("Password Reset Successfully");
                            navigate("/")
                        })
                        .catch((err) => {
                            alert(err);
                        });
                }
            })
            .catch((err) => {
                alert(err);
            })
    }

    useEffect(() => {
        setoobCode(searchParams.get('oobCode'));
        setmode(searchParams.get("mode"));
        if (searchParams.get("mode") == "verifyEmail") {
            verifyEmail(searchParams.get('oobCode'));
        } else {
            verifyPasswordResetCode(searchParams.get('oobCode'))
        }
    }, [])

    return (
        <>
            {loading ?
                <>
                    <h1>"Loading"</h1>
                </>
                :

                mode == "verifyEmail" ?
                    <>
                        <h1>Email Verified</h1>
                        <Link to="/login">Continue to Login Page</Link>
                    </>
                    :
                    <>
                        <form
                            onSubmit={(e) => {
                                ResetPass(e);
                            }}
                            action=""
                            className={`form-container`}
                        >
                            <img src={logo} alt="" className="form-logo" />
                            <input
                                type="password"
                                placeholder="Enter Password"
                                className="form-control my-2"
                                required
                                onChange={(event) => {
                                    setforgotValues((prev) => ({
                                        ...prev,
                                        pass1: event.target.value,
                                    }));
                                }}
                            />
                            <input
                                type="password"
                                placeholder="Re-Enter Password"
                                className="form-control my-2"
                                required
                                onChange={(event) => {
                                    setforgotValues((prev) => ({
                                        ...prev,
                                        pass2: event.target.value,
                                    }));
                                    handleSamepass(event);
                                }}
                            />
                            <b>{passError}</b>
                            <button
                                type="submit"
                                className="submit-btn btn btn-lg btn-block my-4"
                            >
                                Reset Password
                            </button>
                        </form>
                    </>
            }
        </>
    )
}

export default EmailVerifyRoute;