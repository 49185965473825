import React, { useState, useEffect, useContext } from 'react'
import Topbar from './mini_components/Topbar'
import axios from 'axios'
import server from './server'
import { Link } from 'react-router-dom'
import AuthContext from './AuthContext'
const BookedSlots = () => {
    const [slotReqs, setSlotReqs] = useState([])
    const [channelStats, setChannelStats] = useState([])
    const [slotChannelMap, setSlotChannelMap] = useState([])

    const auth = useContext(AuthContext)

    function getChannelStats(slots) {
        slots?.map((slot, index) => {
            axios.get(`${server}/influencer/ytInfo/${slot.influencer}`)
                .then((resp) => {
                    if (resp !== null) {
                        setChannelStats(oldStats => [...oldStats, resp.data])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    function getSlotRequests() {
        axios.get(`${server}/influencer/getmybooked`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then((response) => {
                setSlotReqs(response.data)
                getChannelStats(response.data)
            })
            .catch((err) => {
                console.log(err)
            })


    }

    useEffect(() => {
        if (slotReqs.length == 0 || channelStats.length == 0) {
            return
        }
        let len = slotReqs.length === slotReqs.length ? slotReqs.length : 0;
        let arr = []
        for (let index = 0; index < len; index++) {

            const slot = slotReqs[index]
            const channelInfo = channelStats[index]
            let mapObj = {
                ...slot,
                channelInfo: channelInfo
            }
            arr.push(mapObj)

        }
        if (arr.length != 0) {

            setSlotChannelMap(arr)
        }

    }, [slotReqs, channelStats])


    useEffect(() => {
        console.log("fetching slotts")
        getSlotRequests()
        auth.setActive("bookedslots")
    }, [])
    return (
        <>
            <Topbar />
            <div className="requestPage d-flex justify-content-center">
                <div className="b_table mt-5 scroll-container">
                    {
                        slotChannelMap.length > 0 ? (
                            <table >
                                <thead>
                                    <td></td>
                                    <td style={{ paddingLeft: "0px" }}>Slot ID</td>
                                    <td style={{ paddingLeft: "0px" }}>Channel Name</td>
                                    <td style={{ paddingLeft: "0px" }}>Ad Type</td>
                                    <td style={{ paddingLeft: "0px" }}>Price</td>
                                    <td style={{ paddingLeft: "0px" }}>Date</td>
                                    <td></td>
                                </thead>
                                <tbody>
                                    {
                                        (slotChannelMap?.map((slotReq, index) => {
                                            return (

                                                <tr key={index} >
                                                    <Link to="/viewinfluencer" state={{ channelInfo: slotReq?.channelInfo }} >
                                                        <td className='sticky-left'>
                                                            <button  >View Profile </button>
                                                        </td>
                                                    </Link>
                                                    <td style={{ paddingLeft: "0px" }}>{index + 1}</td>
                                                    <td style={{ paddingLeft: "0px" }}>{slotReq?.channelInfo?.statistics?.channelName}</td>
                                                    <td style={{ paddingLeft: "0px" }}>{slotReq?.collab?.adType}</td>
                                                    <td style={{ paddingLeft: "0px" }}>{slotReq?.collab?.price}</td>
                                                    <td style={{ paddingLeft: "0px" }}>{slotReq?.slot?.startDate} - {slotReq?.slot?.endDate}</td>

                                                </tr>
                                            )
                                        }))
                                    }
                                </tbody>
                            </table>
                        )
                            : "You have Not booked any slots yet"
                    }

                </div>

            </div>
        </>
    )
}

export default BookedSlots