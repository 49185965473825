import React from 'react'
import pic from "../assets/images/not-found@3x.png"

const PageNotFound = ({ Seeker }) => {
    return (
        <>
            <div className='container'>
                <div className=' notfound text-center'>
                    <div className='mx-auto' >
                        <img src={pic} style={{
                            width: "100%",
                            height: "100%"
                        }}></img>
                    </div>

                    <div className='text-center mx-auto '>
                        <h2 className='my-4'><strong>404</strong> - Data not found</h2>
                    </div>
                    <div className='text-center mx-auto '>
                        <span className='my-4'>
                            {Seeker ? "We could not find projects of this Seeker" : "We can not find any result from your search. Try other Name or filters!"}

                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
