import React, { useEffect, useState } from 'react'
import SubViewProfile from './SubViewProfile';
import axios from 'axios'
import StatusSide from './StatusSide';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import server from "./server";

const SubmissionStatus = ({ a, project, id }) => {

    const [active, setActive] = useState(false);
    const [cards, setcards] = useState([]);
    const getuserId = () => {
        axios
            .get(`${server}/project/Seekers/${id}`)
            .then((res) => {

                setcards(res.data.filter(item => item.status !== 'rejected' && item.status !== 'applied'))
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getuserId();
    }, []);

    return (
        <>
            <div className="aa_head d-flex justify-content-between">
                <p>{project.basicInfo.name}</p>
                <div>
                    <span>Roles : </span>
                    <span>{project.roles.length}</span>
                </div>
                <div>
                    <span>Character : </span>
                    <span>{a}</span>
                </div>
                <div className="aa_icon">
                    {active ? (
                        <BsChevronUp onClick={() => setActive(!active)} />
                    ) : (
                        <BsChevronDown onClick={() => setActive(!active)} />
                    )}
                </div>
            </div>
            {active &&
                <div className="aa_body">
                    <hr />
                    <div className="b_table scroll-container">
                        {
                            cards?.length > 0 ? (
                                <table>
                                    <thead>
                                        <td></td>
                                        <td>Applicant Name</td>
                                        <td>Scheduled For</td>
                                        <td>Status</td>
                                        <td>Date-Location</td>
                                        <td>Time</td>
                                        <td>Interviewer Name</td>
                                    </thead>
                                    <tbody>

                                        {cards?.map((item, index) => {
                                            // console.log(item._id, "cards")
                                            return (
                                                <>
                                                    {
                                                        item.status === "shortlisted" || item.status === "scheduled" || item.status === "selected" || item.status === null ? (
                                                            <tr>
                                                                <SubViewProfile display={'/audition'} index={index} project={project} jobapplicationId={item._id} card={cards} msg={'View Profile'} />

                                                                <StatusSide roleId={item.roleId} charId={item.charId} project={project} userId={item.userId} />
                                                                <td>{item.status?.charAt(0).toUpperCase() + item.status?.slice(1).toLowerCase()}</td>
                                                                {
                                                                    item.status === "scheduled" ?

                                                                        (item.audition?.map((sub, i) => {
                                                                            // console.log(sub, "auditionstaut")
                                                                            return (
                                                                                <>

                                                                                    <td >{sub.date}</td>
                                                                                    <td>{sub.time}</td>
                                                                                    <td>{sub.interviewer}</td>

                                                                                </>
                                                                            )
                                                                        })
                                                                        )
                                                                        : (
                                                                            <>
                                                                                <td >NA</td>
                                                                                <td>NA</td>
                                                                                <td>NA</td>
                                                                            </>
                                                                        )
                                                                }

                                                            </tr>
                                                        )
                                                            : ("")
                                                    }
                                                </>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                "No candidates available"
                            )
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default SubmissionStatus
