import { authentication } from "./firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState, useEffect } from "react";
import "./style.css";
import backimg from "../assets/images/godfather.png";
import logo from "../assets/images/logo1.svg";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import server from "./server";
import { toast } from "react-toastify";

const Login = () => {
	const navigate = useNavigate();
	//email authentication states
	const [values, setValues] = useState({
		email: "",
		pass: "",
	});
	const [loader, setLoader] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [forgot, setforgot] = useState(false);
	const [email, setemail] = useState("");

	const handleForgotPassword = (e) => {
		e.preventDefault();
		axios
			.post(
				`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyBE9xQNI1nx1oPmRgXsiO7e54onMtptBYU&requestType=PASSWORD_RESET&email=${email}`
			)
			.then((res) => {
				alert("Password reset link send to entered email.");
				setforgot(false);
			})
			.catch((err) => {
				alert(err?.response?.data?.error?.message);
			});
	};

	const handleSubmission = (e) => {
		setLoader(true);
		e.preventDefault();
		console.log(values.email, values.pass);
		setErrorMsg("");
		axios
			.post(`${server}/auth/login`, {
				email: values.email,
				password: values.pass,
			})
			.then((res) => {
				if (res.status === 200) {
					signInWithEmailAndPassword(authentication, values.email, values.pass)
						.then(() => {
							console.log("1", authentication.currentUser.emailVerified);
							if (!authentication.currentUser.emailVerified) {
								alert("Email not verified");
							} else {
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("type", res.data.type);
								axios
									.get(`${server}/auth/`, {
										headers: {
											Authorization: `Bearer ${res.data.token}`,
										},
									})
									.then((data) => {
										toast("Logged In Successfully", { autoClose: 2000 });
										console.log("2", data);
										data.data.token = localStorage.getItem("token");
										localStorage.setItem("login", JSON.stringify(data.data));
										navigate(`/${localStorage.getItem("type")}dashboard`);
										window.location.reload();
									})
									.catch((err) => {
										console.log(err);
									});
							}
						})
						.catch((err) => setErrorMsg(err.message));
				}
			})
			.catch((err) => {
				setErrorMsg("Invalid email or password");
				console.log(err);
				setLoader(false);
			});
	};

	useEffect(() => {
		let user = localStorage.getItem("login");
		if (localStorage.getItem("login")) {
			if (user.type === "seeker" || user.type === "admin") {
				navigate("/seekerdashboard");
			} else if (user.type === "user") {
				navigate("/userdashboard");
			}
		}
	}, [navigate]);

	return (
		<>
			<div className="login-container row">
				<div className="left-side col-lg-5">
					<div className="top-left d-flex align-items-center">
						<i
							onClick={() => {
								navigate("/");
							}}
							className="bi bi-arrow-left cursor-pointer"
						></i>
						<p className="px-3 m-0">Login</p>
					</div>
					<img
						className="login-img"
						src={backimg}
						alt=""
					/>
					<div className="login-footer">
						<div className="open-quote">“</div>
						<div className="quote">
							Acting is the least mysterious of all crafts. Whenever we want
							something from somebody or when we want to hide something or
							pretend, we're acting. Most people do it all day long.
						</div>
						<div className="author">marlon brando</div>
						<div className="close-quote">”</div>
						<div className="three-dots">
							<i className="fa-solid fa-circle mx-1"></i>
							<i className="fa-regular fa-circle mx-1"></i>
							<i className="fa-regular fa-circle mx-1"></i>
						</div>
					</div>
				</div>
				<div className="right-side col-lg-7 d-flex align-items-center justify-content-center">
					<form
						onSubmit={handleSubmission}
						action=""
						className={`form-container ${forgot ? "d-none" : ""}`}
					>
						<img
							src={logo}
							alt=""
							className="form-logo"
						/>
						<input
							type="email"
							placeholder="Email"
							className="form-control my-2"
							required
							onChange={(event) => {
								setValues((prev) => ({ ...prev, email: event.target.value }));
							}}
						/>
						<input
							type="password"
							placeholder="Password"
							className="form-control my-2"
							required
							onChange={(event) => {
								setErrorMsg("");
								setValues((prev) => ({ ...prev, pass: event.target.value }));
							}}
						/>
						<b>{errorMsg}</b>
						{/* alert({errorMsg}) */}
						{loader ? (
							<button className="submit-btn btn btn-lg btn-block my-2 btn-loader">
								<div class="lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</button>
						) : (
							<button
								type="submit"
								className="submit-btn btn btn-lg btn-block my-4"
							>
								Login
							</button>
						)}
						<div className="alternate-option text-center">
							<u
								className="cursor-pointer"
								onClick={() => {
									setforgot(true);
								}}
							>
								Forgot Password?
							</u>{" "}
							<br />
							Don’t have an account{" "}
							<div className="web1-buttons d-flex flex-column mt-3">
								<Link
									to="/signup"
									state={{ talent: true, type: "user" }}
								>
									<button className="btn btn-talents">Sign up as Talent</button>
								</Link>
								<Link
									to="/signup"
									state={{ talent: false, type: "seeker" }}
								>
									<button className="btn btn-seekers">Sign up as Seeker</button>
								</Link>
								<Link
									to="/signup"
									state={{ talent: false, type: "influencer" }}
								>
									<button className="btn btn-talents">
										Sign up as Influencer
									</button>
								</Link>
							</div>
						</div>
					</form>

					<form
						onSubmit={(e) => {
							handleForgotPassword(e);
						}}
						action=""
						className={`form-container ${forgot ? "" : "d-none"}`}
					>
						<img
							src={logo}
							alt=""
							className="form-logo"
						/>
						<input
							type="email"
							placeholder="Enter Email"
							className="form-control my-2"
							required
							onChange={(event) => {
								setemail(event.target.value);
							}}
						/>
						<button
							type="submit"
							className="submit-btn btn btn-lg btn-block my-4"
						>
							Send Password Reset Link
						</button>
						<div id="recaptcha-container"></div>
					</form>
				</div>
			</div>
		</>
	);
};

export default Login;
