import React, { useContext, useEffect, useRef } from "react";
import Topbar from "./mini_components/Topbar";
import annouce from "../assets/icons/noun-announce-4868354.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CharacterCard from "./mini_components/CharacterCard";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import server from "./server";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "./AuthContext";

const Roles = ({ display }) => {
  let rolesForm, charForm, summForm, rolesToggle, charToggle, summToggle;

  const location = useLocation();
  const [projectDetails, setProjectDetails] = useState(location.state);
  const [roles, setRoles] = useState(projectDetails.roles);
  const [rolePref, setrolePref] = useState([]);
  // for character form
  const [activeRole, setActiveRole] = useState(roles[0]);
  const [chars, setChars] = useState(activeRole.characters);
  const [toEdit, setToEdit] = useState({});
  const [toDelete, setToDelete] = useState({});
  const [highlighted, setHighlighted] = useState(roles[0]._id)


  const auth = useContext(AuthContext)

  useEffect(() => {
    setChars(activeRole.characters);
  }, [activeRole]);


  // Total roles count to set in the project details card
  var totalRolesCount = 0;
  function setTotalRoleCount() {
    let count = 0;
    for (let index = 0; index < [...roles].length; index++) {
      const element = [...roles][index];
      count += element.characters.length;
    }
    totalRolesCount = count;
  }

  // useEffect for re calculating total characters after adding roles
  useEffect(() => {
    setTotalRoleCount();
  }, [roles]);

  setTotalRoleCount();

  function handleAddRole(e) {
    e.preventDefault();
    let obj = { role: "", characters: [], flag: true };
    setRoles([...roles, obj]);
  }

  const handleFormChange = (e, index, attrToChange) => {
    e.preventDefault();

    let data = [...roles];
    if (attrToChange === "roleName") {
      data[index].role = e.target.value;
    }
    setRoles(data);
  };

  const handleRoleUpdateForm = (e) => {
    e.preventDefault();
    axios
      .put(
        `${server}/project/changeRoles`,
        {
          roles: roles,
          id: projectDetails._id
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        toast(`${projectDetails.basicInfo.name} Roles Updated suceessfully`, { autoClose: 2000 });
        setRoles(res?.data?.roles)
      });
  };

  //console.log("Roles array: ", roles)

  function handleAddCharacter(e) {
    e.preventDefault();
    setToEdit({
      name: "",
      age: "",
      gender: "",
      details: "",
      indexInChars: activeRole.characters.length,
    });
  }

  let name, value;
  const handleEditChange = (e) => {
    name = e.target.name;
    value = e.target.value;

    setToEdit({ ...toEdit, [name]: value });
  };

  var modal = document.getElementById("myModal");
  var [Index, setIndex] = useState();
  const [ProjectId, setProjectId] = useState(projectDetails._id)
  const handledelete = (index, id) => {
    setIndex(index)
    modal.style.display = "block";
  }

  const handlesave = async () => {
    const list = [...roles];
    list.splice(Index, 1);
    setRoles(list);
    modal.style.display = "none";

    const savedata = await fetch(`${server}/project/Deleteproject/${ProjectId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ list })
    })
    const res = await savedata.json();
    console.log(res);
  }

  function handleCharacterUpdateForm(e) {
    e.preventDefault();

    let prevChars = chars;
    let indexInChars = toEdit.indexInChars;
    delete toEdit.indexInChars;
    prevChars[indexInChars] = toEdit;

    setActiveRole({ ...activeRole, prevChars });

    axios
      .put(
        `${server}/project/changeRoles`,
        {
          roles: roles,
          id: projectDetails._id
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast(`${res.data.basicInfo.name} Roles/Character Updated suceessfully`, { autoClose: 2000 });
        setToEdit({})
        setRoles(res.data.roles)
      })
      .catch((err) => {
        console.log(err)
        toast("An Error occured while adding role/character, Please try again", { autoClose: 2000 })
      })
  }

  function handleCharDelete(e) {
    e.preventDefault();
    if (chars.length > 1) {

      let prevChars = chars;
      let indexInChars = toDelete.indexInChars;
      delete toDelete.indexInChars;
      prevChars = prevChars.splice(indexInChars, 1,)

      setActiveRole({ ...activeRole, prevChars });
      setChars(prevChars)

      setProjectDetails({ ...projectDetails, activeRole });
      setToDelete({})
      axios
        .put(
          `${server}/project/changeRoles`,
          {
            project: projectDetails,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast(`${res.data.basicInfo.name} Character Deleted suceessfully`, { autoClose: 2000 });
          setRoles(res.data.roles);
        })
        .catch((err) => {
          toast("An Error occured while deleting Try again", { autoClose: 2000 })
        })

    }
    else {
      toast("Role should contain atleast one character", { autoClose: 2000 })
      setToDelete({})
    }

  }

  function getAllForms() {
    rolesForm = document.getElementById("roles-form");
    charForm = document.getElementById("char-form");
    summForm = document.getElementById("summ-form");
    rolesToggle = document.getElementById("roles-toggle");
    charToggle = document.getElementById("char-toggle");
    summToggle = document.getElementById("summ-toggle");
  }

  // //console.log(charForm)

  const toggle = (cur_form) => {
    getAllForms();
    if (cur_form == "roles") {
      rolesForm.style.display = "block";
      charForm.style.display = "none";
      summForm.style.display = "none";
      rolesToggle.classList.add("active-toggle");
      charToggle.classList.remove("active-toggle");
      summToggle.classList.remove("active-toggle");
    } else if (cur_form == "char") {
      charForm.style.display = "block";
      rolesForm.style.display = "none";
      summForm.style.display = "none";
      rolesToggle.classList.remove("active-toggle");
      charToggle.classList.add("active-toggle");
      summToggle.classList.remove("active-toggle");
    } else if (cur_form == "summary") {
      charForm.style.display = "none";
      rolesForm.style.display = "none";
      summForm.style.display = "block";
      rolesToggle.classList.remove("active-toggle");
      charToggle.classList.remove("active-toggle");
      summToggle.classList.add("active-toggle");
    }
  };

  let show = {};
  if (display) {
    show = { display: "block" };
  } else {
    show = { display: "none" };
  }

  const slideDiv1 = useRef("");

  // let width = box.offsetWidth;
  // console.log(box.offsetWidth);

  const prevCon = (e) => {
    e.preventDefault();
    let width = slideDiv1.current.offsetWidth;
    slideDiv1.current.scrollLeft = slideDiv1.current.scrollLeft - width;
    // console.log(slideDiv1);
  };
  const nextCon = (e) => {
    e.preventDefault();
    let width = slideDiv1.current.offsetWidth;
    slideDiv1.current.scrollLeft = slideDiv1.current.scrollLeft + width;
  };

  const prevController = (e) => {
    let slidingDiv = e.target.parentElement.parentElement.previousSibling;
    let width = slidingDiv.offsetWidth;
    slidingDiv.scrollLeft = slidingDiv.scrollLeft - width;

    // console.log(e.target.parentElement.previousSibling.offsetWidth);
  };
  const nextController = (e) => {
    let slidingDiv = e.target.parentElement.parentElement.previousSibling;
    let width = slidingDiv.offsetWidth;
    slidingDiv.scrollLeft = slidingDiv.scrollLeft + width;
    // console.log(slidingDiv.children.length);
  };

  const getRolesPref = async () => {
    await axios.get(`${server}/project/getrolepref`)
      .then((response) => {
        setrolePref(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getRolesPref();
    auth.setActive("mask")

  }, [])

  return (
    <div className="roleCreation ">
      <Topbar />
      <ToastContainer />
      <div className="flex-container">

        <div className="projCont">
          <div className='pc_child'>
            <div className='d-flex'>
              <figure className='m-0'>
                <img src={annouce} className="promotion" alt="" />

              </figure>
              <div className='d-flex flex-column'>

                <span className="projectTitle">
                  {projectDetails.basicInfo.name}

                </span>
                <span className="projectInfo">
                  {projectDetails.basicInfo.desc}

                </span>

              </div>
            </div>


            <hr className='Path-26' />
            <div className='d-flex justify-content-between'>
              <div>
                <span className='postedOn me-2'>Posted On</span>

                <span className="date">{projectDetails.createAt?.slice(4, 15)}</span>
              </div>
              <div>
                <span className="location me-2">Location</span>

                <span className="locationName">{projectDetails.basicInfo.address}
                </span>

              </div>
            </div>


          </div>
          <div className="lastRow">
            <span number={projectDetails.roles.length} className='roles'>Roles</span>
            <span number={totalRolesCount} className='characters'>Characters</span>
          </div>
        </div>
        <>
          {" "}
          {
            <div className="roleUpdate form-body">
              <div className="roleChild">
                <div className="form-toggle fixedDiv d-flex " style={show}>
                  <div
                    className="toggle-option active-toggle"
                    onClick={() => {
                      toggle("roles");
                    }}
                    id="roles-toggle"
                  >
                    Roles
                  </div>
                  <div
                    className="toggle-option"
                    onClick={() => {
                      toggle("char");
                    }}
                    id="char-toggle"
                  >
                    Characters
                  </div>
                  <div
                    className="toggle-option"
                    onClick={() => {
                      toggle("summary");
                    }}
                    id="summ-toggle"
                  >
                    Summary
                  </div>
                </div>

                <div className="form-container" id="roles-form" >
                  <div>
                    <form onSubmit={handleRoleUpdateForm}>
                      <button
                        // type="submit"
                        className="full-width-btn cursor-pointer"
                        value="Add Roles"
                        onClick={handleAddRole}
                      >
                        Add Role
                      </button>

                      {roles.map((item, index) => {
                        return (
                          <>
                            <div key={index} className="role_boxes">
                              <select
                                className="form-control form-select"
                                data-num={index}
                                name="role"
                                disabled={item.flag ? false : true}
                                value={item.role}
                                onChange={(e) => {
                                  handleFormChange(e, index, "roleName");
                                }}
                                required
                              >
                                <option value="" disabled selected>
                                  Name the Role
                                </option>
                                {
                                  rolePref?.map((role, index) => {
                                    return (
                                      <option value={role.role}>
                                        {role.role}
                                      </option>

                                    )
                                  })
                                }
                              </select>
                              <RiDeleteBin5Line onClick={() => handledelete(index, item._id)} />
                            </div>
                          </>
                        );
                      })}

                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <input
                          type="button"
                          className="cancel-btn btn btn-lg btn-block"
                          value="Cancel"
                          onClick={(e) => e.preventDefault}
                        />
                        <p className="col-1"></p>
                        <input
                          type="submit"
                          className="save-btn btn btn-lg btn-block"
                          value="Save"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  id="char-form"
                  style={{ display: "none" }}
                >
                  <div className="charForm">
                    <div className="charList ">
                      {roles.map((item, index) => {
                        return (
                          <>
                            <div
                              style={{
                                fontSize: "13px"
                              }}
                              className={
                                highlighted === item._id ? "hihlightedCharList" : ""
                              }
                              onClick={() => {
                                setToEdit({});
                                setActiveRole(item);
                                setHighlighted(item._id);
                              }}
                            >
                              {item.role}
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="scroll_x ">
                      <div className="container-fluid experience_container">
                        <div className="ec_child" ref={slideDiv1}>
                          {chars.map((item, index) => {
                            return (
                              <CharacterCard
                                index={index}
                                cardData={item}
                                toEdit={toEdit}
                                setToEdit={setToEdit}
                                setToDelete={setToDelete}
                              />
                            );
                          })}
                        </div>
                        <div className="controllers">
                          <button onClick={prevCon}>
                            <BsChevronCompactLeft />
                          </button>
                          <button onClick={nextCon}>
                            <BsChevronCompactRight />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-container">
                      <div>
                        <form onSubmit={handleCharacterUpdateForm}>

                          <input
                            type="submit"
                            className="full-width-btn"
                            value="Add Another Character"
                            onClick={handleAddCharacter}
                          />
                          {JSON.stringify(toEdit) !== "{}" && (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Character Name"
                                name="name"
                                value={toEdit.name}
                                onChange={handleEditChange}
                              />
                              <select
                                className="form-control form-select"
                                id="exampleFormControlSelect1"
                                name="gender"
                                value={toEdit.gender}
                                onChange={handleEditChange}
                              >
                                <option value="chorus" disabled>
                                  Gender
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>

                              <textarea
                                name="details"
                                id=""
                                className="form-control text-area"
                                rows="5"
                                placeholder="Details..."

                                value={toEdit.details}
                                onChange={handleEditChange}
                              ></textarea>

                              <input
                                type="number"
                                className="form-control "
                                id="exampleFormControlSelect1"
                                value={toEdit.age}
                                onChange={handleEditChange}
                                name="age"
                                placeholder="Age"

                              />


                              <div className="d-flex justify-content-between mt-5">
                                <input
                                  className="cancel-btn btn btn-lg btn-block my-2"
                                  value="Cancel"
                                  onClick={(e) => { e.preventDefault(); setToEdit({}) }}
                                />
                                <input
                                  type="submit"
                                  className=" save-btn btn btn-lg btn-block my-2"
                                  value="Save"
                                />
                              </div>

                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ========  modal for delete confirm ============= */}
                <div id="myModal" class="modal" >
                  <div class="modal-content" style={{ width: "25%", marginTop: "12rem" }}>
                    <p>Characters and Applicants accociated with this role will also deleted , are you sure ?</p>
                    <div className="text-center" >
                      <input
                        onClick={handlesave}
                        type="submit"
                        className=" save-btn btn btn-lg btn-block my-2"
                        value="Delete"
                      />
                      <button
                        onClick={(e) => { e.preventDefault(); modal.style.display = "none" }}
                        type="submit"
                        className=" save-btn btn btn-lg btn-block m-2"
                        value="Delete"
                      >Cancel</button>
                    </div>
                  </div>
                </div>


                <form
                  id="summ-form"
                  className="summary"
                  style={{ display: "none" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {projectDetails.roles?.map((item, index) => {
                    return (
                      <>
                        <div className="purple_head">
                          <span> {item.role} </span>
                          <span>
                            {"("}
                            {item.characters.length}
                            {")"}
                          </span>
                        </div>
                        <div className="scroll_x ">
                          <div className="container-fluid experience_container">
                            <div className="ec_child">
                              {item
                                ? item.characters?.map((not, i) => {
                                  return (
                                    <div className="summaryChild">
                                      <span className="w-100 d-flex align-items-center">
                                        <h5 class="card-title">
                                          {not.name}{" "}
                                          {not.gender ? ":" + not.gender : ""}
                                          {not.age ? "," + not.age : ""}
                                        </h5>
                                      </span>
                                      <p class="card-text">
                                        {not.details
                                          ? not.details
                                          : "Not available"}
                                      </p>
                                    </div>
                                  );
                                })
                                : " "}
                            </div>
                            <div className="controllers">
                              <button>
                                <BsChevronCompactLeft
                                  onClick={(e) => prevController(e)}
                                />
                              </button>
                              <button>
                                {" "}
                                <BsChevronCompactRight
                                  onClick={(e) => nextController(e)}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </form>
              </div>
            </div>
          }{" "}

          {
            JSON.stringify(toDelete) !== "{}" && (
              <div id="myModal" class="modal d-block" style={{ zIndex: "3" }} >
                <div class="modal-content" style={{ width: "25%", marginTop: "12rem" }}>
                  <p>Your are sure to delete this Character: {toDelete?.name} </p>
                  <div className="text-center" >
                    <input
                      onClick={handleCharDelete}
                      type="submit"
                      className=" save-btn btn btn-lg btn-block my-2"
                      value="Delete"
                    />
                    <button
                      onClick={(e) => { e.preventDefault(); setToDelete({}) }}
                      type="submit"
                      className=" save-btn btn btn-lg btn-block m-2"
                      value="Delete"
                    >Cancel</button>
                  </div>
                </div>
              </div>
            )
          }
        </>
      </div>
    </div>
  );
};

export default Roles;
